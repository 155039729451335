import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React from 'react';
import ReactExport from 'react-export-excel';
import { useTranslation } from 'react-i18next';
import { userInfo } from '../../utils';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportExcel = ({ filename, dataSheets, loading }) => {
  const { t } = useTranslation();

  return (
    <div>
      {!userInfo?.user?.role.includes('admin') &&
      !userInfo?.user?.role.includes('accountant') ? (
        <Tooltip title={t('exportDataPermissionTooltip')}>
          <Button
            style={{ marginTop: '20px' }}
            type='primary'
            icon={<CloudDownloadOutlined />}
            disabled
          >
            {t('download')} .xlsx
          </Button>
        </Tooltip>
      ) : (
        <ExcelFile
          filename={filename}
          element={
            <Button
              loading={loading}
              style={{ marginTop: '20px' }}
              type='primary'
              disabled={loading}
              icon={<CloudDownloadOutlined />}
            >
              {loading ? `${t('gettingData')}...` : `${t('download')} .xlsx`}
            </Button>
          }
        >
          {dataSheets?.map((dataSheet, index) => (
            <ExcelSheet
              key={index}
              data={dataSheet.sheetDataSets}
              name={dataSheet.sheetName}
            >
              {dataSheet.sheetColumns.map((column, index) => (
                <ExcelColumn
                  key={index}
                  label={column.label}
                  value={column.value}
                  style={column.style}
                />
              ))}
            </ExcelSheet>
          ))}
        </ExcelFile>
      )}
    </div>
  );
};

export default ExportExcel;
