import { Button, Form, Input, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
const { TextArea } = Input;

const CreateForm = ({ visible, onCreate, onCancel, loading }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  return (
    <Modal
      className='class-setting'
      open={visible}
      title='Thêm thông báo'
      onCancel={onCancel}
      footer={[
        <Button onClick={onCancel}>{t('cancel')}</Button>,
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                onCreate(values);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          {t('ok')}
        </Button>,
      ]}
    >
      <Form form={form} layout='vertical' name='form_in_modal'>
        <Form.Item name='title' label='Tiêu đề'>
          <Input defaultValue='' />
        </Form.Item>
        <Form.Item name='description' label='Nội dung'>
          <TextArea rows={5}></TextArea>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateForm;
