const studyStatus = [
  'Đang học',
  'Đã kết thúc',
  'Bảo lưu',
  'Chưa học',
  'Bỏ học',
  'Không qua tốt nghiệp',
  'Chưa thi tốt nghiệp',
  'Học xong, đang chăm sóc',
  'Học xong, không học lên',
  'Học xong, có thể học lên'
];

module.exports = studyStatus;
