import { DeleteOutlined, FileAddOutlined } from '@ant-design/icons';
import { Button, Table, Typography, message } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Confirm } from 'react-st-modal';
import history from '../../history';
import addCommas from '../../utils/addCommas';
import removeNonNumeric from '../../utils/removeNonNumeric';

import { useTranslation } from 'react-i18next';
import { config, userInfo } from '../../utils';
import '../Common/Table.css';
import Spinner from '../Spinner/Spinner';
import CreateFormSalesSalary from './CreateFormSalesSalary';
import UpdateFormSalesSalary from './UpdateFormSalesSalary';

const { Text } = Typography;

const SalarySalesTable = ({ user }) => {
  const { t } = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [updateTable, setUpdatTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [updateItem, setUpdateItem] = useState(null);
  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  // fetch records
  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
      return;
    }

    const fetchData = async () => {
      setLoadingTable(true);

      const url = `${process.env.REACT_APP_API_URL}/v1/salaries?user=${user._id}`;

      const { data } = await axios.get(url, config);

      data.data.forEach((record, index) => {
        record.key = record._id;
        record.no = index + 1;
      });

      setData(data.data);
      setLoadingTable(false);
    };

    fetchData();
  }, [updateTable]);

  const onUpdate = (values) => {
    const updateRecord = async () => {
      setLoadingUpdate(true);

      await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/salaries/${updateItem._id}`,
        values,
        config
      );

      setLoadingUpdate(false);

      setUpdatTable(!updateTable);
      setVisibleUpdate(false);

      message.success('Cập nhật thành công');
    };

    updateRecord();
  };

  // create new record
  const onCreate = (values) => {
    const createRecord = async () => {
      values.user = user._id;

      setLoadingCreate(true);

      await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/salaries`,
        values,
        config
      );

      setLoadingCreate(false);

      setVisibleCreate(false);
      setUpdatTable(!updateTable);

      message.success('Thêm thành công');
    };

    createRecord();
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const handleDelete = async () => {
    setLoading(true);

    const deleteById = async (id) => {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/v1/salaries/${id}`,
        config
      );

      setUpdatTable(!updateTable);
    };

    const result = await Confirm(
      'Việc xoá sẽ ảnh hưởng đến các bản ghi hiện tại, chắc chắn xoá?'
    );

    if (result) {
      selectedRowKeys.forEach((id) => {
        deleteById(id);
      });

      setLoading(false);
      setSelectedRowKeys([]);

      message.success(t('deletedSuccessfully'));
    } else {
      setLoading(false);
      setSelectedRowKeys([]);
    }
  };

  const columns = [
    {
      title: 'Thao tác',
      dataIndex: '',
      fixed: 'left',
      render: (text, record) => (
        <Button
          onClick={() => {
            setVisibleUpdate(true);
            setUpdateItem(record);
          }}
        >
          {t('edit')}
        </Button>
      ),
    },
    {
      title: t('no'),
      dataIndex: 'no',
    },
    {
      title: t('month'),
      dataIndex: 'month',
    },
    {
      title: t('year'),
      dataIndex: 'year',
    },
    {
      title: 'Lương cứng',
      dataIndex: 'permanent_salary',
    },
    {
      title: 'Số HV tư vấn',
      width: 200,
      dataIndex: 'student_count',
    },
    {
      title: 'Lương kinh doanh',
      dataIndex: 'business_salary',
    },
    {
      title: 'Lương khác',
      dataIndex: 'other_salary',
    },
    {
      title: 'Tổng doanh số',
      dataIndex: 'total_sales',
    },
    {
      title: 'Thưởng',
      dataIndex: 'reward',
    },
    {
      title: 'Phạt',
      dataIndex: 'punishment',
    },
    {
      title: 'Tạm ứng',
      dataIndex: 'advance_salary',
    },
    {
      title: 'Tổng thu nhập',
      dataIndex: '',
      render: (text, record) => (
        <span>
          {addCommas(
            removeNonNumeric(record.reward) -
              removeNonNumeric(record.punishment) +
              parseInt(removeNonNumeric(record.other_salary)) -
              removeNonNumeric(record.advance_salary) +
              parseInt(removeNonNumeric(record.permanent_salary)) +
              parseInt(removeNonNumeric(record.business_salary)) +
              parseInt(removeNonNumeric(record.total_sales))
          )}
        </span>
      ),
    },
    {
      title: 'Note',
      dataIndex: 'note',
    },
  ];

  return (
    <>
      <div className='input-table'>
        {!loadingTable && !!user ? (
          <>
            <div style={{ marginBottom: 16 }} className='table-header'>
              <div className='left'>
                <Button
                  type='primary'
                  onClick={() => history.back()}
                  style={{ marginBottom: '10px', marginRight: '8px' }}
                >
                  Quay lại
                </Button>
                <div className='delete-zone'>
                  <Button
                    className='delete-btn'
                    type='default'
                    onClick={handleDelete}
                    disabled={!hasSelected}
                    loading={loading}
                    icon={<DeleteOutlined />}
                  >
                    {t('delete')}
                  </Button>
                  <span style={{ marginLeft: 8 }}>
                    {hasSelected ? (
                      <Text>
                        {selectedRowKeys.length} {t('selected')}
                      </Text>
                    ) : (
                      ''
                    )}
                  </span>
                </div>
                <div className='add-zone'>
                  <Button
                    className='add-btn'
                    icon={<FileAddOutlined />}
                    type='text'
                    onClick={() => {
                      setVisibleCreate(true);
                    }}
                  >
                    {t('createRecord')}
                  </Button>
                </div>
              </div>
            </div>
            <h3>Thông tin lương nhân sự {user.fullname}</h3>
            <Table
              showHeader={data?.length > 0}
              pagination={{ pageSize: 100 }}
              bordered
              rowSelection={rowSelection}
              columns={columns}
              dataSource={data}
              scroll={{ x: 'max-content' }}
              showSorterTooltip={true}
              sticky
            />
            <CreateFormSalesSalary
              visible={visibleCreate}
              onCreate={onCreate}
              onCancel={() => {
                setVisibleCreate(false);
              }}
              loading={loadingCreate}
            />
            <UpdateFormSalesSalary
              visible={visibleUpdate}
              onUpdate={onUpdate}
              onCancel={() => {
                setVisibleUpdate(false);
              }}
              updateItem={updateItem}
              loading={loadingUpdate}
            />
          </>
        ) : (
          <Spinner />
        )}
      </div>
    </>
  );
};

export default SalarySalesTable;
