import {
  CommentOutlined,
  EyeOutlined,
  NotificationOutlined,
} from '@ant-design/icons';
import { Avatar, List, Select, Tag, Typography } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { config, userInfo } from '../../utils';
import SkeletonList from '../SubClassList/SkeletonList';
import './Notification.css';
const { Option } = Select;
const { Text, Title } = Typography;

const Notifications = ({ history }) => {
  const [notis, setNotis] = useState([]);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState(
    `${process.env.REACT_APP_API_URL}/v1/notifications?sort`
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
      return;
    }

    const fetchNotis = async () => {
      setLoading(true);

      const { data } = await axios.get(url, config);

      setNotis(data.data);
      setLoading(false);
    };

    fetchNotis();
  }, [url]);

  const handleChange = (value) => {
    setUrl(
      url.replace('=createdAt', '') + (value === 'oldest' ? '=createdAt' : '')
    );
  };

  return (
    <div className='notifications'>
      <div className='noti-header'>
        <div className='noti-le'>
          <h3>
            <NotificationOutlined /> {t('announcements')}
          </h3>
        </div>
        <div className='sort'>
          <Select
            defaultValue='newest'
            style={{ width: 120 }}
            onChange={handleChange}
          >
            <Option value='newest'>{t('newest')}</Option>
            <Option value='oldest'>{t('oldest')}</Option>
          </Select>
        </div>
      </div>
      <div className='noti-body'>
        {!loading ? (
          <>
            {notis.length > 0 ? (
              <>
                <Text>
                  <small>{t('announcementsHint')}</small>
                </Text>

                <List
                  pagination={{
                    pageSize: 5,
                  }}
                  itemLayout='horizontal'
                  dataSource={notis}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar size='large' src={item.user.avatar} />}
                        title={
                          <Link to={`/notifications/${item._id}`}>
                            {item.title}
                          </Link>
                        }
                        description={
                          <>
                            <div className='list-des'>
                              <div className='time'>
                                {dayjs(item.createdAt).fromNow()}
                              </div>
                              <p>
                                <span> · </span>
                                {item.user.fullname}
                              </p>
                              {item.user.role.map((role) => (
                                <Tag
                                  color={
                                    item.user.role.includes('admin')
                                      ? 'magenta'
                                      : 'cyan'
                                  }
                                >
                                  {role}
                                </Tag>
                              ))}
                            </div>
                            <div className='more-info'>
                              <div className='count-viewers'>
                                <EyeOutlined /> {item.views}
                              </div>
                              <div className='count-comments'>
                                <CommentOutlined
                                  style={{ marginLeft: '10px' }}
                                />{' '}
                                {item.comments.length}
                              </div>
                            </div>
                          </>
                        }
                      />
                    </List.Item>
                  )}
                />
              </>
            ) : (
              <div className='noti-empty'>
                <img
                  src='/no-data.svg'
                  alt='No data'
                  className='noti-empty-img'
                />
                <Title className='mb-0' level={5}>
                  {t('noAnnouncementsHeading')}
                </Title>
                <Text>{t('noAnnouncementsSubHeading')}</Text>
              </div>
            )}
          </>
        ) : (
          <SkeletonList />
        )}
      </div>
    </div>
  );
};

export default Notifications;
