import { DeleteOutlined, FileAddOutlined } from '@ant-design/icons';
import { Button, Table, Typography, message } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Confirm } from 'react-st-modal';
import { useBoundStore } from '../../store/useBoundStore';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { config, userInfo } from '../../utils';
import '../Common/Table.css';
import Spinner from '../Spinner/Spinner';
import CreateForm from './CreateForm';
import UpdateForm from './UpdateForm';

const { Text } = Typography;

const SettingCosultant = ({ history }) => {
  const { t } = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [updateTable, setUpdatTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [updateItem, setUpdateItem] = useState(null);
  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const ranking = useBoundStore((state) => state.ranking);

  // get classname
  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
      return;
    }
  }, [history, userInfo]);

  // fetch records
  useEffect(() => {
    const fetchData = async () => {
      setLoadingTable(true);

      if (!!ranking) {
        ranking.map((rankingObj, index) => {
          rankingObj.key = rankingObj._id;
          rankingObj.no = index + 1;
        });
        setData(ranking);
        setLoadingTable(false);
      } else {
        const url = `${process.env.REACT_APP_API_URL}/v1/cosultants`;

        const { data } = await axios.get(url, config);

        data.data.map((record, index) => {
          record.key = record._id;
          record.no = index + 1;
        });

        setData(data.data);
        setLoadingTable(false);
      }
    };

    fetchData();
  }, [JSON.stringify(ranking), updateTable]);

  const onUpdate = (values) => {
    const updateRecord = async () => {
      setLoadingUpdate(true);

      await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/cosultants/${updateItem._id}`,
        values,
        config
      );

      setLoadingUpdate(false);

      setUpdatTable(!updateTable);
      setVisibleUpdate(false);

      message.success('Cập nhật thành công');
    };

    updateRecord();
  };

  // create new record
  const onCreate = (values) => {
    const createRecord = async () => {
      values.kpi.forEach((item) => {
        delete item.key;
      });

      setLoadingCreate(true);

      await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/cosultants`,
        values,
        config
      );

      setLoadingCreate(false);

      setVisibleCreate(false);
      setUpdatTable(!updateTable);

      message.success('Thêm thành công');
    };

    createRecord();
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const handleDelete = async () => {
    setLoading(true);

    const deleteById = async (id) => {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/v1/cosultants/${id}`,
        config
      );

      setUpdatTable(!updateTable);
    };

    const result = await Confirm(t('confirmDelete'));

    if (result) {
      selectedRowKeys.map((id) => {
        deleteById(id);
      });

      setLoading(false);
      setSelectedRowKeys([]);

      message.success(t('deletedSuccessfully'));
    } else {
      setLoading(false);
      setSelectedRowKeys([]);
    }
  };

  const columns = [
    {
      title: t('edit'),
      dataIndex: '',
      fixed: 'left',
      render: (text, record) => (
        <>
          <Button
            onClick={() => {
              setVisibleUpdate(true);
              setUpdateItem(record);
            }}
          >
            {t('edit')}
          </Button>
        </>
      ),
    },
    {
      title: t('no'),
      dataIndex: 'no',
    },
    {
      title: t('recordConsultant'),
      dataIndex: 'fullname',
    },
    {
      title: t('createdAt'),
      dataIndex: 'createdAt',
      render: (_, record) =>
        dayjs(record.createdAt).format('DD/MM/YYYY, HH:mm:ss'),
    },
    {
      title: t('updatedAt'),
      dataIndex: 'updatedAt',
      render: (_, record) =>
        dayjs(record.updatedAt).format('DD/MM/YYYY, HH:mm:ss'),
    },
  ];

  return (
    <div className='input-table'>
      {!loadingTable ? (
        <>
          <div style={{ marginBottom: 16 }} className='table-header'>
            <div className='left'>
              <div className='delete-zone'>
                <Button
                  className='delete-btn'
                  type='default'
                  onClick={handleDelete}
                  disabled={!hasSelected}
                  loading={loading}
                  icon={<DeleteOutlined />}
                >
                  {t('delete')}
                </Button>
                <span style={{ marginLeft: 8 }}>
                  {hasSelected ? (
                    <Text>
                      {selectedRowKeys.length} {t('selected')}
                    </Text>
                  ) : (
                    ''
                  )}
                </span>
              </div>
              <div className='add-zone'>
                <Button
                  className='add-btn'
                  icon={<FileAddOutlined />}
                  type='text'
                  onClick={() => {
                    setVisibleCreate(true);
                  }}
                >
                  {t('createRecord')}
                </Button>
              </div>
            </div>
          </div>
          <Table
            pagination={{ pageSize: 100 }}
            bordered
            rowSelection={
              userInfo?.user?.role.includes('admin') ? rowSelection : ''
            }
            columns={columns}
            dataSource={data}
            scroll={{ x: 'max-content' }}
            showSorterTooltip={true}
            sticky
          />
          <CreateForm
            visible={visibleCreate}
            onCreate={onCreate}
            onCancel={() => {
              setVisibleCreate(false);
            }}
            loading={loadingCreate}
          />
          <UpdateForm
            visible={visibleUpdate}
            onUpdate={onUpdate}
            onCancel={() => {
              setVisibleUpdate(false);
            }}
            updateItem={updateItem}
            loading={loadingUpdate}
          />
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default SettingCosultant;
