import { Avatar, Button } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import history from '../../history';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { config, userInfo } from '../../utils';
import Spinner from '../Spinner/Spinner';
import { USER_ROLE_MAPPING } from './SettingUser';
import './UserDetails.css';
import UserDetailsItem from './UserDetailsItem';

const UserDetails = ({ match }) => {
  const { t } = useTranslation();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
      return;
    }

    const fetchData = async () => {
      setLoading(true);

      const url = `${process.env.REACT_APP_API_URL}/v1/users/${match.params.id}`;

      const { data } = await axios.get(url, config);

      setUser(data.data);
      setLoading(false);
    };

    fetchData();
  }, [match.params.id]);

  return (
    <div className='user-details'>
      {!!user ? (
        <>
          <div className='user-details__header'>
            <Button
              type='primary'
              onClick={() => history.back()}
              style={{ marginBottom: '15px' }}
            >
              Quay lại
            </Button>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h3>Chi tiết nhân sự {user.fullname}</h3>
            </div>
          </div>

          <div className='user-details-heading'>
            <Avatar src={user.avatar} size='large' />
            <h3>{user.fullname}</h3>
          </div>

          <div className='user-details__content'>
            <UserDetailsItem title={t('recordName')} content={user.fullname} />
            <UserDetailsItem
              title='Chức danh'
              content={
                <>
                  {user.role
                    .map((role) => `${USER_ROLE_MAPPING[role]}`)
                    .join(', ')}
                </>
              }
            />
            <UserDetailsItem
              title='Cộng tác viên'
              content={user.isCollaborator ? 'Có' : 'Không'}
            />
            <UserDetailsItem
              title='Ngày sinh'
              content={dayjs(user.birthday).format('DD/MM/YYYY')}
            />
            <UserDetailsItem title='Tài khoản' content={user.username} />
            <UserDetailsItem
              title='Ngày tạo'
              content={dayjs(user.createdAt).format('DD/MM/YYYY')}
            />
            <UserDetailsItem
              title='Ngày bắt đầu làm việc'
              content={dayjs(user.onboardDate).format('DD/MM/YYYY')}
            />
            <UserDetailsItem
              title='Hợp đồng làm việc'
              isLink
              content={user.contract}
            />
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default UserDetails;
