import { ExclamationCircleOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Drawer, Modal, Space, message } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { toPng } from 'html-to-image';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';
import { FACILITY_MAPPING } from '../../constants';
import { config } from '../../utils';
import getDataMailBill from '../../utils/getDataMailBill';
import getInvitationLetter from '../../utils/getInvitationLetter';
import BillPDF from '../PDF/BillPDF';
const { confirm } = Modal;

const SendEmailDrawer = React.forwardRef((props, _ref) => {
  const {
    updateItem,
    classJoin,
    subClasses,
    teachers,
    assistants,
    loadingMailBill,
    setLoadingMailBill,
    setIsSentBill,
    setUpdateTable,
    open,
    onClose,
  } = props;
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const billRef = useRef(null);
  const invitationLetterRef = useRef(null);

  const subClassObj =
    updateItem?.joined_class &&
    subClasses?.find((sub) => sub.class_name === updateItem.joined_class);

  const sendEmaill = () =>
    confirm({
      title: t('Xác nhận gửi email'),
      icon: <ExclamationCircleOutlined />,
      content: t(
        'Bạn đã kiểm tra kỹ toàn bộ thông tin chính xác và chịu trách nhiệm sau khi gửi. Xác nhận gửi email?'
      ),
      async onOk() {
        const files = [];
        setLoadingMailBill(true);

        message.info('Đang đính kèm phiếu thu...');
        const billImg = await toPng(billRef.current, {
          cacheBust: true,
          backgroundColor: '#fff',
          width: 436,
        });
        files.push({
          fileName: `Phiếu thu - ${updateItem?.name}.png`,
          fileContent: billImg,
          fileType: 'dataUrl',
        });

        if (subClassObj) {
          message.info('Đang đính kèm thư mời...');
          const invitationLetterImg = await toPng(invitationLetterRef.current, {
            cacheBust: true,
            width: 540,
            height: 960,
            style: {
              transform: 'scale(1)',
            },
          });
          files.push({
            fileName: `Thư mời - ${updateItem?.name}.png`,
            fileContent: invitationLetterImg,
            fileType: 'dataUrl',
          });
        }

        message.info('Đang gửi email...');
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/auth/sendmail`,
          {
            mailUser: updateItem?.email,
            // mailUser: 'hungngv231@gmail.com', // reveiver
            mailSubject: `[THALIC VOICE] THƯ MỜI VÀ THÔNG TIN THAM GIA KHOÁ HỌC`,
            mailContent: getDataMailBill(
              updateItem?.name || '',
              updateItem?.class?.alias || '',
              classJoin?.class_schedule || '',
              classJoin?.class_hour || '',
              classJoin?.class_opening
                ? dayjs(classJoin.class_opening).format('DD/MM/YYYY')
                : ''
            ),
            files,
          }
        );

        if (data.success) {
          message.success('Gửi email thành công');

          // update status
          const { data } = await axios.put(
            `${process.env.REACT_APP_API_URL}/v1/records/${updateItem?._id}`,
            {
              isSentBill: 1,
            },
            config
          );

          if (data.success) {
            setIsSentBill(1);
            setUpdateTable(true);
          } else {
            message.error('Lưu trạng thái gửi thất bại');
          }
        } else {
          message.error(`Gửi email thất bại: ${data.msg}`);
        }

        setLoadingMailBill(false);
      },
      onCancel() {
        console.log('Cancel');
      },
    });

  const getInvitationLetterData = () => {
    const name = updateItem?.name || '';
    const course = updateItem?.class?.alias || '';
    const open = dayjs(subClassObj?.class_opening).format('DD/MM/YYYY') || '';
    const address =
      FACILITY_MAPPING[updateItem?.facility]?.address || 'Chưa có thông tin';
    const addressStyle = FACILITY_MAPPING[updateItem?.facility]?.style;

    let teacher = subClassObj?.class_teacher?.includes('fullname')
      ? JSON.parse(subClassObj.class_teacher)?.fullname
      : teachers?.find((teacher) => teacher._id === subClassObj.class_teacher)
          ?.fullname || subClassObj.class_teacher;

    if (updateItem?.class?.class === 'VIP 1-1 CV Chuyên Môn') {
      if (!teacher || teacher === '/') {
        const teachingAssistant =
          subClassObj?.class_teaching_assistant?.includes('fullname')
            ? JSON.parse(subClassObj.class_teaching_assistant)?.fullname
            : assistants?.find(
                (teacher) =>
                  teacher._id === subClassObj.class_teaching_assistant
              )?.fullname || subClassObj.class_teaching_assistant;
        teacher = teachingAssistant;
      }
    }

    return {
      name,
      course,
      teacher,
      open,
      address,
      addressStyle,
    };
  };

  return (
    <>
      <Drawer
        title={t('previewBeforeSend')}
        placement='right'
        width={width >= 640 ? 500 : '100%'}
        onClose={onClose}
        open={open}
        extra={
          <Space>
            <Button
              type='primary'
              icon={<SendOutlined />}
              onClick={sendEmaill}
              disabled={loadingMailBill}
              loading={loadingMailBill}
            >
              {loadingMailBill ? t('sending') : t('send')}
            </Button>
          </Space>
        }
      >
        <p>
          <b>{t('mailTo')}:</b> {updateItem?.email}
        </p>
        <p>
          <b>{t('mailSubject')}:</b>{' '}
          {`[THALIC VOICE] THƯ MỜI VÀ THÔNG TIN THAM GIA KHOÁ HỌC`}
        </p>
        <p>
          <b>{t('mailContent')}:</b>{' '}
          <p>
            <div
              style={{
                marginTop: 10,
                border: '1px solid #eee',
                padding: 16,
                borderRadius: 8,
              }}
              dangerouslySetInnerHTML={{
                __html: getDataMailBill(
                  updateItem?.name,
                  updateItem?.class?.alias || '',
                  classJoin?.class_schedule || '',
                  classJoin?.class_hour || '',
                  classJoin?.class_opening
                    ? dayjs(classJoin.class_opening).format('DD/MM/YYYY')
                    : ''
                ),
              }}
            ></div>
          </p>
        </p>
        <p>
          <b>{t('mailAttachments')}:</b>
        </p>
        <BillPDF bill={updateItem} ref={billRef} />
        {subClassObj && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: width <= 640 ? -80 : -60,
              padding: width <= 640 ? 16 : 0,
            }}
          >
            <div
              ref={invitationLetterRef}
              style={{
                transform: 'scale(0.8)',
              }}
              dangerouslySetInnerHTML={{
                __html: getInvitationLetter({
                  name: getInvitationLetterData().name,
                  course: getInvitationLetterData().course,
                  teacher: getInvitationLetterData().teacher,
                  open: getInvitationLetterData().open,
                  address: getInvitationLetterData().address,
                  addressStyle: getInvitationLetterData().addressStyle,
                }),
              }}
            ></div>
          </div>
        )}
      </Drawer>
    </>
  );
});

export default React.memo(SendEmailDrawer);
