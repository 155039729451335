const locations = [
  { value: 'Chưa chọn' },
  { value: 'Nước ngoài' },
  { value: 'Người Việt ở nước ngoài' },
  { value: 'Người nước ngoài ở VN' },
  { value: 'An Giang' },
  { value: 'Bình Dương' },
  { value: 'Bình Phước' },
  { value: 'Bình Thuận' },
  { value: 'Bình Định' },
  { value: 'Bạc Liêu' },
  { value: 'Bắc Kạn' },
  { value: 'Bắc Giang' },
  { value: 'Bắc Ninh' },
  { value: 'Bến Tre' },
  { value: 'Cao Bằng' },
  { value: 'Cà Mau' },
  { value: 'Cần Thơ' },
  { value: 'Gia Lai' },
  { value: 'Huế' },
  { value: 'Hà Giang' },
  { value: 'Hà Nam' },
  { value: 'Hà Nội' },
  { value: 'Hà Tĩnh' },
  { value: 'Hòa Bình' },
  { value: 'Hưng Yên' },
  { value: 'Hải Dương' },
  { value: 'Hải Phòng' },
  { value: 'Hậu Giang' },
  { value: 'Khánh Hòa' },
  { value: 'Kiên Giang' },
  { value: 'Kon Tum' },
  { value: 'Lai Châu' },
  { value: 'Long An' },
  { value: 'Lào Cai' },
  { value: 'Lâm Đồng' },
  { value: 'Lạng Sơn' },
  { value: 'Nam Định' },
  { value: 'Nghệ An' },
  { value: 'Ninh Bình' },
  { value: 'Ninh Thuận' },
  { value: 'Phú Thọ' },
  { value: 'Phú Yên' },
  { value: 'Quảng Bình' },
  { value: 'Quảng Nam' },
  { value: 'Quảng Ngãi' },
  { value: 'Quảng Ninh' },
  { value: 'Quảng Trị' },
  { value: 'Sóc Trăng' },
  { value: 'Sơn La' },
  { value: 'TP Hồ Chí Minh' },
  { value: 'Thanh Hoá' },
  { value: 'Thái Bình' },
  { value: 'Thái Nguyên' },
  { value: 'Tiền Giang' },
  { value: 'Trà Vinh' },
  { value: 'Tuyên Quang' },
  { value: 'Tây Ninh' },
  { value: 'Vĩnh Long' },
  { value: 'Vĩnh Phúc' },
  { value: 'Bà Rịa - Vũng Tàu' },
  { value: 'Yên Bái' },
  { value: 'Điện Biên' },
  { value: 'Đà Nẵng' },
  { value: 'Đắk Lắk' },
  { value: 'Đắk Nông' },
  { value: 'Đồng Nai' },
  { value: 'Đồng Tháp' },
];

module.exports = locations;
