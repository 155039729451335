import { Button, Form, Input, Modal, Select, Spin } from 'antd';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { config, userInfo } from '../../utils';
import removeNonNumeric from '../../utils/removeNonNumeric';
import BarChart from '../Statistics/BarChart';
import '../Statistics/index.css';
import EditableTable from './EditableTable';

const UpdateForm = ({ visible, onUpdate, onCancel, updateItem, loading }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [defaultlDataSource, setDefaultlDataSource] = useState([]);
  const [count, setCount] = useState(0);
  const [time, setTime] = useState(new Date(Date.now()).getFullYear());
  const [years, setYears] = useState([]);
  const [loadingChart, setLoadingChart] = useState(false);
  const [dataMoney, setDataMoney] = useState([]);

  // get array of years and labels
  useEffect(() => {
    if (userInfo?.user?.role.includes('sales_mn')) {
      setYears([2023]);
      return;
    }
    const currYear = new Date(Date.now()).getFullYear();
    const yearsArr = [];
    for (let i = 2021; i <= currYear; ++i) {
      yearsArr.push(i);
    }
    setYears(yearsArr);
  }, []);

  const fetchItem = useCallback(async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1/cosultants/${updateItem._id}`,
      config
    );

    setDefaultlDataSource(data.data);
  }, [updateItem]);

  const fetchDataChart = async (time) => {
    setLoadingChart(true);
    let url = `${process.env.REACT_APP_API_URL}/v1/records?is_register=true&cosultant=${updateItem.fullname}&select=month,year,transfer_date_1,transfer_date_2,paid_tuition_1,paid_tuition_2`;

    const { data } = await axios.get(url, config);
    setDataMoney(data.data);

    setLoadingChart(false);
  };

  useEffect(() => {
    if (updateItem) {
      fetchItem();
    }
    return () => {
      setDefaultlDataSource(null);
      form.resetFields();
    };
  }, [updateItem, fetchItem, form]);

  useEffect(() => {
    if (updateItem) {
      fetchDataChart(time);
    }
  }, [time, updateItem]);

  // chart configs
  const options = {
    borderWidth: 1.5,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
      },
    },
  };

  // labels & kpiData
  const labels = [];
  const kpiData = {};
  const monthCount =
    time < new Date().getFullYear() ? 12 : new Date().getMonth() + 1;
  for (let i = 1; i <= monthCount; ++i) {
    labels.push(`Tháng ${i}`);

    const kpiMonth = defaultlDataSource?.kpi?.find(
      (kpiObj) => kpiObj.month == i && kpiObj.year == time
    );
    if (!!kpiMonth) {
      kpiData[`Tháng ${i}`] = parseInt(removeNonNumeric(kpiMonth.kpi));
    } else kpiData[`Tháng ${i}`] = 0;
  }

  // recieved money data
  const compareTransferDate = (compareMonth, compareYear, _transferDate) => {
    const transferDate = new Date(_transferDate);

    return (
      compareMonth == transferDate.getMonth() + 1 &&
      compareYear == transferDate.getFullYear()
    );
  };
  console.log('labels', labels);

  const recievedMoneyData = {};
  labels.forEach((label) => {
    dataMoney.forEach((curr) => {
      recievedMoneyData[label] =
        (recievedMoneyData[label] ? recievedMoneyData[label] : 0) +
        (compareTransferDate(label.slice(6), time, curr.transfer_date_1)
          ? parseInt(removeNonNumeric(curr.paid_tuition_1 || '0'))
          : 0) +
        (compareTransferDate(label.slice(6), time, curr.transfer_date_2)
          ? parseInt(removeNonNumeric(curr.paid_tuition_2 || '0'))
          : 0);
    }, {});
  });

  console.log(recievedMoneyData);

  const data = {
    labels,
    datasets: [
      {
        label: 'KPI',
        data: labels.map((label) => kpiData[label]),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Doanh số thực',
        data: labels.map((label) => recievedMoneyData[label]),
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  const getDataSource = (data) => {
    setDataSource(data);
  };

  const handleTimeChange = (value) => {
    setTime(value);
  };

  return (
    <Modal
      className='class-setting'
      open={visible}
      title={t('editConsultant')}
      onCancel={onCancel}
      footer={[
        <Button onClick={onCancel}>{t('cancel')}</Button>,
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                values.kpi = dataSource;
                onUpdate(values);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          {t('ok')}
        </Button>,
      ]}
    >
      {!defaultlDataSource ? (
        <p>
          <Spin /> Đang tải...
        </p>
      ) : (
        <>
          <Form form={form} layout='vertical' name='form_in_modal'>
            <Form.Item name='fullname' label={t('consultant')}>
              <Input
                defaultValue={defaultlDataSource.fullname}
                placeholder={`${t('e.g.')}Nguyễn Văn A`}
              />
            </Form.Item>

            {/* bar chart */}
            <div
              className='chart-section'
              style={{
                height: '650px',
                marginTop: '30px',
              }}
            >
              <div className='flex items-center justify-between'>
                <div className='heading-chart'>Biểu đồ doanh số/KPI</div>
                <div className='statistics-time'>
                  <Select
                    style={{ width: 120 }}
                    defaultValue={time}
                    onChange={handleTimeChange}
                  >
                    {years.map((year) => (
                      <Select.Option value={year}>{year}</Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
              {!loadingChart ? (
                <BarChart data={data} options={options} />
              ) : (
                <p>
                  <Spin /> Đang tải biểu đồ
                </p>
              )}
            </div>

            <div
              style={{
                marginTop: '10px',
              }}
            >
              <EditableTable
                getDataSource={getDataSource}
                count={count}
                defaultlDataSource={defaultlDataSource.kpi}
              />
            </div>
          </Form>
        </>
      )}
    </Modal>
  );
};

export default React.memo(UpdateForm);
