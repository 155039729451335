import {
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { Button, Input, Spin, Table, Tooltip, Typography, message } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Confirm } from 'react-st-modal';
import { config } from '../../utils';
import Ripple from '../Common/Ripple';
import CreateSubClassForm from './CreateSubClassForm';
import './SettingClass.css';
import UpdateSubClassForm from './UpdateSubClassForm';

const PAGE_SIZE = 15;

const ClassEditTable = ({
  defaultlDataSource = [],
  teachers = [],
  assistants = [],
  classId,
  mainClass,
}) => {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState(defaultlDataSource);
  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [updateItem, setUpdateItem] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/classes/sub_class_list?primary_class_id=${classId}&page=${page}&limit=${PAGE_SIZE}&keyword=${keyword}`,
        config
      );

      setDataSource(data.data?.subClasses);
      setTotal(data.data?.total);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [classId, page, keyword]);

  useEffect(() => {
    if (classId) {
      fetchData();
    }
  }, [fetchData, classId]);

  useEffect(() => {
    if (dataSource) {
      dataSource.forEach((data) => {
        data.key = data._id;
      });
    }
  }, [dataSource]);

  const columns = [
    {
      title: t('edit'),
      dataIndex: '',
      fixed: 'left',
      render: (_, record) => (
        <>
          <Tooltip
            placement='rightBottom'
            title={`${t('recordLastModified')}: ${dayjs(
              record.updatedAt
            ).format('DD/MM/YYYY HH:mm:ss')}`}
          >
            <Button
              size='small'
              icon={<EditOutlined />}
              onClick={() => {
                setVisibleUpdate(true);
                setUpdateItem(record);
              }}
            >
              <Ripple color='#ffcd0f' />
            </Button>
          </Tooltip>
        </>
      ),
    },
    {
      title: 'Giáo viên',
      dataIndex: '',
      width: 120,
      render: (_, record) => <>{record.class_teacher.fullname || '/'}</>,
    },
    {
      title: 'Trợ giảng',
      dataIndex: '',
      width: 120,
      render: (_, record) => (
        <>{record.class_teaching_assistant.fullname || '/'}</>
      ),
    },

    {
      title: 'Lớp học',
      dataIndex: 'class_name',
      editable: true,
      width: 120,
    },
    {
      title: 'Học phí riêng',
      dataIndex: 'class_fee',
      render: (_, record) => <>{record.class_fee ? record.class_fee : '/'}</>,
      editable: true,
      width: 120,
    },
    {
      title: t('classOpening'),
      dataIndex: 'class_opening',
      editable: true,
      width: 120,
      type: 'date',
      render: (_, record) =>
        record.class_opening
          ? dayjs(record.class_opening).format('DD/MM/YYYY')
          : '/',
    },
    {
      title: t('classSchedule'),
      dataIndex: 'class_schedule',
      editable: true,
      width: 120,
      render: (_, record) => record.class_schedule || '/',
    },
    {
      title: t('classTime'),
      dataIndex: 'class_hour',
      editable: true,
      width: 120,
      render: (_, record) => record.class_hour || '/',
    },
    {
      title: t('classEnding'),
      dataIndex: 'class_end',
      editable: true,
      width: 120,
      type: 'date',
      render: (_, record) =>
        record.class_end ? dayjs(record.class_end).format('DD/MM/YYYY') : '/',
    },
  ];

  const onCreate = async (values) => {
    try {
      setLoadingCreate(true);

      await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/classes/sub_class_list?primary_class_id=${classId}`,
        values,
        config
      );
      fetchData();
    } catch (error) {
    } finally {
      setLoadingCreate(false);
      setVisibleCreate(false);
      message.success('Thêm thành công');
    }
  };

  const onUpdate = async (values) => {
    try {
      setLoadingUpdate(true);

      await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/classes/sub_class_list?primary_class_id=${classId}&sub_class_id=${updateItem?._id}`,
        values,
        config
      );
      fetchData();
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingUpdate(false);
      setVisibleUpdate(false);
      message.success('Sửa thành công');
    }
  };

  const handleDelete = async () => {
    setLoadingDelete(true);

    const deleteById = async (id) => {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/v1/classes/sub_class_list?primary_class_id=${classId}&sub_class_id=${id}`,
        config
      );
    };

    const result = await Confirm(t('confirmDelete'));

    if (result) {
      selectedRowKeys.forEach((id) => {
        deleteById(id);
      });

      setLoadingDelete(false);
      setSelectedRowKeys([]);
      fetchData();

      message.success(t('deletedSuccessfully'));
    } else {
      setLoadingDelete(false);
      setSelectedRowKeys([]);
    }
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      {loading ? (
        <div
          style={{
            height: 300,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spin />
        </div>
      ) : (
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 16,
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {hasSelected && (
                <div className='delete-zone'>
                  <Button
                    className='delete-btn'
                    type='default'
                    onClick={handleDelete}
                    disabled={!hasSelected}
                    loading={loadingDelete}
                    icon={<DeleteOutlined />}
                  >
                    {t('delete')}
                    <Ripple />
                  </Button>
                  <span style={{ marginLeft: 8 }}>
                    {hasSelected ? (
                      <Typography.Text>
                        {selectedRowKeys.length} {t('selected')}
                      </Typography.Text>
                    ) : (
                      ''
                    )}
                  </span>
                </div>
              )}
              <Button
                icon={<FileAddOutlined />}
                onClick={() => {
                  setVisibleCreate(true);
                }}
                type='primary'
                style={{
                  marginLeft: 20,
                }}
              >
                Thêm lớp học
              </Button>
            </div>
            <Input.Search
              defaultValue={keyword}
              placeholder='Tìm mã lớp học'
              onSearch={(value) => {
                setPage(1);
                setKeyword(value);
              }}
              style={{
                width: 200,
                marginLeft: 20,
              }}
            />
          </div>
          <Table
            pagination={{
              pageSize: PAGE_SIZE,
              total,
              current: page,
              onChange: (page) => setPage(page),
            }}
            title={() => (
              <>
                <div className='table-header-add-class'>
                  <span>
                    <InfoCircleOutlined /> Chấp nhận định dạng nhập học phí:
                    1000000, 1.000.000, 1,000,000
                    <br />
                    <InfoCircleOutlined /> {t('tuitionHint')}
                  </span>
                </div>
              </>
            )}
            rowSelection={rowSelection}
            rowClassName={() => 'editable-row'}
            bordered
            dataSource={dataSource}
            columns={columns}
            scroll={{ x: 'max-content' }}
            showSorterTooltip={true}
            sticky
          />
          <CreateSubClassForm
            visible={visibleCreate}
            onCreate={onCreate}
            onCancel={() => {
              setVisibleCreate(false);
            }}
            loading={loadingCreate}
            teachers={teachers}
            assistants={assistants}
            mainClass={mainClass}
          />
          <UpdateSubClassForm
            visible={visibleUpdate}
            onUpdate={onUpdate}
            onCancel={() => {
              setVisibleUpdate(false);
              setUpdateItem(null);
            }}
            updateItem={updateItem}
            loading={loadingUpdate}
            teachers={teachers}
            assistants={assistants}
          />
        </div>
      )}
    </>
  );
};

export default ClassEditTable;
