import { Tag as AntTag } from 'antd';
import styled from 'styled-components';

const StyledAntTag = styled(AntTag)`
  border-radius: 16px !important;
`;

const Tag = (props) => {
  return <StyledAntTag {...props} />;
};

export default Tag;
