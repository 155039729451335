import { t } from 'i18next';
import { Helmet } from 'react-helmet';
import ClassList from '../components/ClassList/ClassList';
import ClassTable from '../components/ClassTable/ClassTable';
import Extras from '../components/Extras';
import FinanceTable from '../components/FinanceTable/FinanceTable';
import InputTable from '../components/InputTable/InputTable';
import ManageRequests from '../components/ManageRequests/ManageRequests';
import ManageRequestsSent from '../components/ManageRequestsSent/ManageRequestsSent';
import ManageRevenue from '../components/ManageRevenue/ManageRevenue';
import NotificationDetails from '../components/Notifications/NotificationDetails/NotificationDetails';
import Notifications from '../components/Notifications/Notifications';
import SettingNotification from '../components/Notifications/SettingNotification/SettingNotification';
import PancakeConfig from '../components/Pancake/PancakeConfig';
import Profile from '../components/Profile/Profile';
import Ranking from '../components/Ranking/Ranking';
import Ranks from '../components/Ranks/Ranks';
import RegisterTable from '../components/RegisterTable/RegisterTable';
import SettingClass from '../components/SettingClass/SettingClass';
import SettingCosultant from '../components/SettingCosultant/SettingCosultant';
import SalaryDetails from '../components/SettingUser/SalaryDetails';
import SettingUser from '../components/SettingUser/SettingUser';
import UserDetails from '../components/SettingUser/UserDetails';
import Statistics from '../components/Statistics';
import SubClassList from '../components/SubClassList/SubClassList';
import SystemConfig from '../components/SysemConfig/SystemConfig';
import Task from '../components/Task/Task';
import Login from '../pages/Login/Login';

export const publicRoutes = [
  {
    path: '/login',
    exact: true,
    component: (props) => (
      <>
        <Helmet>
          <title>{t('login')} | THALIC CRM</title>
          <meta property='og:title' content={`${t('login')} | THALIC CRM`} />
        </Helmet>
        <Login {...props} />
      </>
    ),
  },
  // {
  //   path: '/register',
  //   exact: true,
  //   component: Register,
  // },
];

export const privateRoutes = [
  {
    path: '/',
    exact: true,
    component: (props) => (
      <>
        <Helmet>
          <title>THALIC CRM</title>
          <meta property='og:title' content='THALIC CRM' />
        </Helmet>
        {window.scrollTo({ top: 0, behavior: 'smooth' })}
        <div className='wrapper' style={{ backgroundColor: 'unset' }}>
          <Notifications {...props} />
          <Task {...props} />
        </div>
      </>
    ),
  },
  {
    path: '/users',
    exact: true,
    component: (props) => (
      <>
        <Helmet>
          <title>{t('humanResourceManagement')} | THALIC CRM</title>
          <meta
            property='og:title'
            content={`${t('humanResourceManagement')} | THALIC CRM`}
          />
        </Helmet>
        <SettingUser {...props} />
      </>
    ),
  },
  {
    path: '/users/:id',
    exact: true,
    component: (props) => (
      <>
        <Helmet>
          <title>{t('userDetails')} | THALIC CRM</title>
          <meta
            property='og:title'
            content={`${t('userDetails')} | THALIC CRM`}
          />
        </Helmet>
        <UserDetails {...props} />
      </>
    ),
  },
  {
    path: '/users/:id/salary',
    exact: true,
    component: (props) => (
      <>
        <Helmet>
          <title>{t('salaryDetails')} | THALIC CRM</title>
          <meta
            property='og:title'
            content={`${t('salaryDetails')} | THALIC CRM`}
          />
        </Helmet>
        <SalaryDetails {...props} />
      </>
    ),
  },
  {
    path: '/profile',
    exact: true,
    component: (props) => (
      <>
        <Helmet>
          <title>{t('accountSettings')} | THALIC CRM</title>
          <meta
            property='og:title'
            content={`${t('accountSettings')} | THALIC CRM`}
          />
        </Helmet>
        <Profile {...props} />
      </>
    ),
  },
  {
    path: '/statistics',
    exact: true,
    component: (props) => (
      <>
        <Helmet>
          <title>{t('statistics')} | THALIC CRM</title>
          <meta
            property='og:title'
            content={`${t('statistics')} | THALIC CRM`}
          />
        </Helmet>
        <Statistics {...props} />
      </>
    ),
  },
  {
    path: '/notifications/setting',
    exact: true,
    component: (props) => (
      <>
        <Helmet>
          <title>{t('manageNotifications')} | THALIC CRM</title>
          <meta
            property='og:title'
            content={`${t('manageNotifications')} | THALIC CRM`}
          />
        </Helmet>
        <SettingNotification {...props} />
      </>
    ),
  },
  {
    path: '/notifications/:id',
    exact: true,
    component: (props) => (
      <>
        <Helmet>
          <title>{t('notiDetails')} | THALIC CRM</title>
          <meta
            property='og:title'
            content={`${t('notiDetails')} | THALIC CRM`}
          />
        </Helmet>
        <NotificationDetails {...props} />
      </>
    ),
  },
  {
    path: '/import',
    exact: true,
    component: (props) => (
      <>
        <Helmet>
          <title>{t('dataEntry')} | THALIC CRM</title>
          <meta
            property='og:title'
            content={`${t('dataEntry')} | THALIC CRM`}
          />
        </Helmet>
        <InputTable {...props} />
      </>
    ),
  },
  {
    path: '/students',
    exact: true,
    component: (props) => (
      <>
        <Helmet>
          <title>{t('customers')} | THALIC CRM</title>
          <meta
            property='og:title'
            content={`${t('customers')} | THALIC CRM`}
          />
        </Helmet>
        <RegisterTable {...props} />
      </>
    ),
  },
  {
    path: '/records',
    exact: true,
    component: ClassTable,
  },
  {
    path: '/classes/setting',
    exact: true,
    component: (props) => (
      <>
        <Helmet>
          <title>{t('manageClasses')} | THALIC CRM</title>
          <meta
            property='og:title'
            content={`${t('manageClasses')} | THALIC CRM`}
          />
        </Helmet>
        <SettingClass {...props} />
      </>
    ),
  },
  {
    path: '/cosultants/setting',
    exact: true,
    component: (props) => (
      <>
        <Helmet>
          <title>{t('manageConsultants')} | THALIC CRM</title>
          <meta
            property='og:title'
            content={`${t('manageConsultants')} | THALIC CRM`}
          />
        </Helmet>
        <SettingCosultant {...props} />
      </>
    ),
  },
  {
    path: '/classes/:id',
    exact: true,
    component: SubClassList,
  },
  {
    path: '/classes',
    exact: true,
    component: (props) => (
      <>
        <Helmet>
          <title>{t('classList')} | THALIC CRM</title>
          <meta
            property='og:title'
            content={`${t('classList')} | THALIC CRM`}
          />
        </Helmet>
        <ClassList {...props} />
      </>
    ),
  },
  {
    path: '/finance',
    exact: true,
    component: (props) => (
      <>
        <Helmet>
          <title>{t('manageFinances')} | THALIC CRM</title>
          <meta
            property='og:title'
            content={`${t('manageFinances')} | THALIC CRM`}
          />
        </Helmet>
        <FinanceTable {...props} />
      </>
    ),
  },
  {
    path: '/ranking',
    exact: true,
    component: (props) => (
      <>
        <Helmet>
          <title>{t('ranking')} | THALIC CRM</title>
          <meta property='og:title' content={`${t('ranking')} | THALIC CRM`} />
        </Helmet>
        <Ranking {...props} />
      </>
    ),
  },
  {
    path: '/manage/revenue',
    exact: true,
    component: (props) => (
      <>
        <Helmet>
          <title>{t('revenueManagement')} | THALIC CRM</title>
          <meta
            property='og:title'
            content={`${t('revenueManagement')} | THALIC CRM`}
          />
        </Helmet>
        <ManageRevenue {...props} />
      </>
    ),
  },
  {
    path: '/manage/requests',
    exact: true,
    component: (props) => (
      <>
        <Helmet>
          <title>{t('manageRequests')} | THALIC CRM</title>
          <meta
            property='og:title'
            content={`${t('manageRequests')} | THALIC CRM`}
          />
        </Helmet>
        <ManageRequests {...props} />
      </>
    ),
  },
  {
    path: '/requests/me',
    exact: true,
    component: (props) => (
      <>
        <Helmet>
          <title>{t('submittedRequests')} | THALIC CRM</title>
          <meta
            property='og:title'
            content={`${t('submittedRequests')} | THALIC CRM`}
          />
        </Helmet>
        <ManageRequestsSent {...props} />
      </>
    ),
  },
  {
    path: '/system-configs/pancake',
    exact: true,
    component: (props) => (
      <>
        <Helmet>
          <title>{t('pancakeConfig')} | THALIC CRM</title>
          <meta
            property='og:title'
            content={`${t('pancakeConfig')} | THALIC CRM`}
          />
        </Helmet>
        <PancakeConfig {...props} />
      </>
    ),
  },
  {
    path: '/system-configs',
    exact: true,
    component: (props) => (
      <>
        <Helmet>
          <title>{t('general')} | THALIC CRM</title>
          <meta property='og:title' content={`${t('general')} | THALIC CRM`} />
        </Helmet>
        <SystemConfig {...props} />
      </>
    ),
  },
  {
    path: '/extras',
    exact: true,
    component: (props) => (
      <>
        <Helmet>
          <title>{t('extras')} | THALIC CRM</title>
          <meta property='og:title' content={`${t('extras')} | THALIC CRM`} />
        </Helmet>
        <Extras {...props} />
      </>
    ),
  },
  {
    path: '/ranks',
    exact: true,
    component: (props) => (
      <>
        <Helmet>
          <title>{t('ranks')} | THALIC CRM</title>
          <meta property='og:title' content={`${t('ranks')} | THALIC CRM`} />
        </Helmet>
        <Ranks {...props} />
      </>
    ),
  },
];
