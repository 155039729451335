import { Spin } from 'antd';
import React from 'react';
import './Loader.css';
import PulseLoader from 'react-spinners/PulseLoader';

const Loader = ({ icon, fullHeight }) => {
  return (
    <div className={`loader ${fullHeight && 'loader-full'}`}>
      {icon ? (
        <Spin />
      ) : (
        <div className="loading-container">
          <img src="/logo-new.png" height={50} alt="Thalic Voice" />
          <PulseLoader
            cssOverride={{ marginTop: 10 }}
            color="#ffcd0f"
            loading
            size={8}
            margin={4}
          />
        </div>
      )}
    </div>
  );
};

export default Loader;
