const statusRecord = [
  { value: 'Đang xem thông tin' },
  { value: 'Đang chọn lịch' },
  { value: 'Đợi làm test' },
  { value: 'Chưa phản hồi' },
  { value: 'Không phản hồi' },
  { value: 'Không ở HN, muốn học off' },
  { value: 'Tư vấn Zalo' },
  { value: 'Phân vân onl, off' },
  { value: 'Hẹn tháng sau, ra Tết...' },
  { value: 'Hỏi giùm người khác' },
  { value: 'Không nghe máy' },
  { value: 'Thuê bao/sai số' },
  { value: 'Không có nhu cầu' },
  { value: 'Hẹn gọi lại' },
  { value: 'Băn khoăn học phí' },
  { value: 'Không xếp được lịch' },
  { value: 'Không làm test' },
  { value: 'Muốn học thử' },
  { value: 'Chuẩn bị ĐK' },
  { value: 'Không add Zalo' },
  { value: 'Đã đăng ký' },
  // { value: "Khác" },
];

export { statusRecord };
