import { Spin, Typography } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { config, userInfo } from '../../utils';
import RequestSentItem from '../RequestSentItem/RequestSentItem';
import './ManageRequestsSent.css';

const ManageRequestsSent = () => {
  const { t } = useTranslation();
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchRequests = async (status) => {
      try {
        setLoading(true);

        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/requests?from.id=${userInfo?.user?.id}`,
          config
        );
        setRequests(data.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, []);

  return (
    <div className='mn-requests'>
      <h1>
        {t('submittedRequests')} ({requests?.length})
      </h1>
      {loading ? (
        <Spin />
      ) : (
        <>
          <Typography.Text style={{ marginBottom: 10 }}>
            <small>{t('requestsHint')}</small>
          </Typography.Text>
          {requests?.map((item) => (
            <RequestSentItem request={item} />
          ))}
        </>
      )}
    </div>
  );
};

export default ManageRequestsSent;
