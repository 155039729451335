import { PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  Tooltip,
} from 'antd';
import axios from 'axios';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AGE_OPTIONS, FACILITY_MAPPING } from '../../constants';
import { useClasses } from '../../hooks/useClasses';
import { useConfigs } from '../../hooks/useConfigs';
import { useCosultants } from '../../hooks/useCosultants';
import { getRecord } from '../../resources/records';
import { useBoundStore } from '../../store/useBoundStore';
import { config, LEVEL_ENUM, potentials, userInfo } from '../../utils';
import addCommas from '../../utils/addCommas';
import locations from '../../utils/locations';
import removeNonNumeric from '../../utils/removeNonNumeric';
import { sex } from '../../utils/sex';
import { statusRecord } from '../../utils/status';
import studyStatus from '../../utils/studyStatus';
import SkeletonForm from '../Common/SkeletonForm';

const { TextArea } = Input;
const { Panel } = Collapse;
const { Option } = Select;

const UpdateForm = ({ visible, onUpdate, onCancel, updateItem, loading }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isKhachLe, setIsKhachLe] = useState(null);
  const [isKhachCombo, setIsKhachCombo] = useState(null);
  const [isKhachHocLen, setIsKhachHocLen] = useState(null);
  const [isL1L2, setIsL1L2] = useState(null);
  const [isL2L3, setIsL2L3] = useState(null);
  const [register, setRegister] = useState(null);
  const [data, setData] = useState(null);
  const typingTimeoutRef = useRef(null);
  const [isNewPhone, setIsNewPhone] = useState(true);

  const [ages, setAges] = useState(AGE_OPTIONS);
  const [age, setAge] = useState('');
  const ageRef = useRef(null);

  const ranking = useBoundStore((state) => state.ranking);

  const isAdmin = userInfo?.user?.role.includes('admin');

  const { data: channels } = useConfigs('channels', { enabled: false });
  const { data: classes } = useClasses('classes', {
    enabled: false,
  });
  const { data: cosultants } = useCosultants('cosultants', {
    enabled: false,
  });

  const fetchRecord = async () => {
    const { data } = await getRecord(updateItem._id);
    setData(data);
  };

  useEffect(() => {
    if (!!updateItem) {
      fetchRecord();
    }

    return () => {
      setData(null);
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateItem]);

  const getFee = useCallback(
    (joinedClass) => {
      if (
        !data ||
        !data?.expected_class ||
        data?.expected_class === 'Chưa chọn'
      )
        return '0';

      const subClass = data.class?.sub_class.find(
        (sub) => sub.class_name === joinedClass
      );

      if (!subClass || subClass.class_fee === '/') {
        return addCommas(removeNonNumeric(data.class?.fee));
      }

      return addCommas(removeNonNumeric(subClass.class_fee));
    },
    [data]
  );

  const handleFormValuesChange = (changedValues, allValues) => {
    form.setFieldsValue({
      permanent_fee:
        getFee(
          changedValues.joined_class ||
            allValues.joined_class ||
            data.joined_class
        ) || '0',
      discount:
        addCommas(
          parseInt(
            removeNonNumeric(
              changedValues.discount || allValues.discount || data.discount
            )
          )
        ) || '0',
      paid_tuition_1:
        addCommas(
          parseInt(
            removeNonNumeric(
              changedValues.paid_tuition_1 ||
                allValues.paid_tuition_1 ||
                data.paid_tuition_1
            )
          )
        ) || '0',
      paid_tuition_2:
        addCommas(
          parseInt(
            removeNonNumeric(
              changedValues.paid_tuition_2 ||
                allValues.paid_tuition_2 ||
                data.paid_tuition_2
            )
          )
        ) || '0',
      unpaid_tuition:
        addCommas(
          removeNonNumeric(
            getFee(
              changedValues.joined_class ||
                allValues.joined_class ||
                data.joined_class
            )
          ) -
            removeNonNumeric(
              changedValues.paid_tuition_1 ||
                allValues.paid_tuition_1 ||
                data.paid_tuition_1
            ) -
            removeNonNumeric(
              changedValues.paid_tuition_2 ||
                allValues.paid_tuition_2 ||
                data.paid_tuition_2
            ) -
            removeNonNumeric(
              changedValues.discount || allValues.discount || data.discount
            )
        ) || '0',
      act_received: addCommas(
        parseInt(
          removeNonNumeric(
            changedValues.paid_tuition_1 ||
              allValues.paid_tuition_1 ||
              data.paid_tuition_1 ||
              '0'
          )
        ) +
          parseInt(
            removeNonNumeric(
              changedValues.paid_tuition_2 ||
                allValues.paid_tuition_2 ||
                data.paid_tuition_2 ||
                '0'
            )
          )
      ),
    });
  };

  const handlePhoneChange = (e) => {
    const phone = e.target.value;

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      const checkPhoneNumber = async () => {
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/records/check_phone`,
          { phone },
          config
        );

        setIsNewPhone(data.data.is_new_phone);
      };
      checkPhoneNumber();
    }, 300);
  };

  const onAgeChange = (value) => {
    setAge(value);
  };

  const addAge = (e) => {
    e.preventDefault();
    setAges([...ages, age]);
    setAge('');
    setTimeout(() => {
      ageRef.current?.focus();
    }, 0);
  };

  return (
    <Modal
      width={600}
      open={visible}
      title={t('editRecordModal')}
      onCancel={onCancel}
      footer={[
        <Button onClick={onCancel}>{t('cancel')}</Button>,
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                values.is_register = register;
                values.isKhachLe = isKhachLe;
                values.isKhachCombo = isKhachCombo;
                values.isKhachHocLen = isKhachHocLen;
                values.isL1L2 = isL1L2;
                values.isL2L3 = isL2L3;

                if (values.expected_class) {
                  values.class = classes?.find(
                    (item) => item.class === values.expected_class
                  )?._id;
                }
                if (values.joined_class) {
                  values.sub_class = data?.class?.sub_class.find(
                    (item) => item.class_name === values.joined_class
                  )?._id;
                }
                if (age) {
                  values.year_of_birthday = age;
                }

                form.resetFields();
                console.log('values', values);
                onUpdate(values);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          {t('ok')}
        </Button>,
      ]}
    >
      {!data ? (
        <p>
          <SkeletonForm />
        </p>
      ) : (
        <>
          <Form
            form={form}
            layout='vertical'
            name='form_in_modal'
            onValuesChange={handleFormValuesChange}
          >
            <Collapse
              defaultActiveKey={
                window.location.pathname.includes('import')
                  ? ['personalInfo', 'registrationInfo', 'otherInfo']
                  : ['registrationInfo', 'otherInfo', 'billingInfo']
              }
            >
              {data?.user?.id === userInfo?.user?.id ||
              userInfo?.user?.role.includes('admin') ? (
                <>
                  <Panel key='personalInfo' header={t('personalInfo')}>
                    <div className='inline-input'>
                      <Form.Item
                        name='name'
                        label={t('recordName')}
                        rules={[
                          {
                            required: data.name ? false : true,
                            message: 'Trường này là bắt buộc!',
                          },
                        ]}
                      >
                        <Input defaultValue={data.name} />
                      </Form.Item>
                      <Form.Item
                        name='email'
                        label={t('recordEmail')}
                        rules={[
                          {
                            required: false,
                            message: 'Trường này là bắt buộc!',
                          },
                          {
                            type: 'email',
                            message: 'Email không hợp lệ!',
                          },
                        ]}
                      >
                        <Input defaultValue={data.email} />
                      </Form.Item>
                    </div>
                    <div className='inline-input'>
                      <Form.Item
                        name='phone'
                        label={t('recordPhone')}
                        rules={[
                          {
                            required: data.phone ? false : true,
                            message: 'Trường này là bắt buộc!',
                          },
                        ]}
                      >
                        <Input
                          defaultValue={data.phone}
                          onChange={handlePhoneChange}
                        />
                      </Form.Item>
                      <Form.Item
                        name='year_of_birthday'
                        label={t('recordDateOfBirthday')}
                      >
                        <Select
                          allowClear
                          style={{
                            width: '100%',
                          }}
                          defaultValue={data.year_of_birthday}
                          placeholder=''
                          dropdownRender={(menu) => (
                            <>
                              {menu}
                              <Divider
                                style={{
                                  margin: '8px 0',
                                }}
                              />
                              <Space
                                style={{
                                  padding: '0 8px 4px',
                                }}
                              >
                                <InputNumber
                                  style={{
                                    width: '100%',
                                  }}
                                  min={1900}
                                  minLength={4}
                                  maxLength={4}
                                  placeholder='Nhập năm sinh'
                                  ref={ageRef}
                                  value={age}
                                  onChange={onAgeChange}
                                  onKeyDown={(e) => e.stopPropagation()}
                                />
                                <Button
                                  type='text'
                                  icon={<PlusOutlined />}
                                  onClick={addAge}
                                  disabled={!age}
                                >
                                  Thêm
                                </Button>
                              </Space>
                            </>
                          )}
                          options={ages.map((item) => ({
                            label: item,
                            value: item,
                          }))}
                        />
                      </Form.Item>
                    </div>
                    {!isNewPhone && (
                      <Alert
                        message='SĐT này đã có trên hệ thống, tiếp tục nhập hoặc huỷ bỏ.'
                        type='warning'
                      />
                    )}
                    <div className='inline-input'>
                      <Form.Item
                        name='sex'
                        label={t('recordSex')}
                        rules={[
                          {
                            required: data.sex || isAdmin ? false : true,
                            message: 'Trường này là bắt buộc!',
                          },
                        ]}
                      >
                        <Select defaultValue={data.sex}>
                          <Option value='Chưa chọn'>Chưa chọn</Option>
                          {sex?.map((sexObj) => (
                            <Option key={sexObj.value} value={sexObj.value}>
                              {sexObj.value}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <div id='ud-popup-add'>
                        <Form.Item name='address' label={t('recordAddress')}>
                          <Select
                            showSearch
                            getPopupContainer={() =>
                              document.getElementById('ud-popup-add')
                            }
                            defaultValue={data.address}
                            style={{
                              width: '100%',
                            }}
                            options={locations}
                            placeholder={t('typeToSearch')}
                            optionFilterProp='children'
                            filterOption={(inputValue, option) =>
                              option.value
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) !== -1
                            }
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className='inline-input'>
                      <Form.Item
                        name='occupation'
                        label={t('recordOccupation')}
                        rules={[
                          {
                            required: !isAdmin && !data.potential,
                            message: 'Trường này là bắt buộc!',
                          },
                          {
                            min: 4,
                            message: 'Nhập ít nhất 4 ký tự!',
                          },
                        ]}
                      >
                        <Input defaultValue={data.occupation} />
                      </Form.Item>
                      <Form.Item
                        name='purpose'
                        label={t('recordPurpose')}
                        rules={[
                          {
                            required: !isAdmin && !data.potential,
                            message: 'Trường này là bắt buộc!',
                          },
                          {
                            min: 20,
                            message: 'Nhập ít nhất 20 ký tự!',
                          },
                        ]}
                      >
                        <Input defaultValue={data.purpose} />
                      </Form.Item>
                    </div>
                  </Panel>
                  <Panel key='registrationInfo' header={t('registrationInfo')}>
                    <div className='inline-input'>
                      <div id='ud-popup-col'>
                        <Form.Item
                          name='cosultant'
                          label={t('consultant')}
                          rules={[
                            {
                              required: data.cosultant ? false : true,
                              message: 'Trường này là bắt buộc!',
                            },
                          ]}
                        >
                          <Select
                            getPopupContainer={() =>
                              document.getElementById('ud-popup-col')
                            }
                            defaultValue={data.cosultant}
                            placeholder={t('typeToSearch')}
                            showSearch
                            allowClear
                            filterOption={(inputValue, option) =>
                              option.value
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) !== -1
                            }
                          >
                            {(!!ranking ? ranking : cosultants)?.map((item) => (
                              <Option key={item._id} value={item.fullname}>
                                {item.fullname}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                      <Form.Item name='is_register' label={t('enrollInClass')}>
                        <Checkbox
                          defaultChecked={data.is_register}
                          onClick={(e) => setRegister(e.target.checked)}
                        >
                          {t('recordRegistered')}
                        </Checkbox>
                      </Form.Item>
                    </div>
                    <div className='inline-input'>
                      {!window.location.pathname.includes('students') ? (
                        <Form.Item
                          name='expected_class'
                          label={t('recordExpectedClass')}
                          rules={[
                            {
                              required: data?.expected_class ? false : true,
                              message: 'Trường này là bắt buộc!',
                            },
                          ]}
                        >
                          <Select
                            defaultValue={data?.expected_class}
                            placeholder={t('typeToSearch')}
                            showSearch
                            allowClear
                            filterOption={(inputValue, option) =>
                              option.value
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) !== -1
                            }
                          >
                            <Option value='Chưa chọn'>Chưa chọn</Option>
                            {classes
                              ?.sort((a, b) => a.order - b.order)
                              .map((classObj) => (
                                <Option
                                  key={classObj._id}
                                  value={classObj.class}
                                >
                                  {classObj.class}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      ) : (
                        <Form.Item
                          name='class'
                          label={t('recordExpectedClass')}
                        >
                          <Select
                            defaultValue={data?.expected_class}
                            disabled
                          ></Select>
                        </Form.Item>
                      )}
                      {window.location.pathname.includes('students') && (
                        <div id='ud-popup-joined_class'>
                          <Form.Item
                            name='joined_class'
                            label={t('class')}
                            rules={[
                              {
                                required:
                                  data.joined_class || data.expected_class
                                    ? false
                                    : true,
                                message: 'Trường này là bắt buộc!',
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              getPopupContainer={() =>
                                document.getElementById('ud-popup-joined_class')
                              }
                              disabled={
                                data.expected_class === 'Chưa chọn' ||
                                !data.expected_class
                              }
                              defaultValue={data?.joined_class}
                              placeholder={t('typeToSearch')}
                              optionFilterProp='children'
                              filterOption={(inputValue, option) =>
                                option.value
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              }
                            >
                              {data?.class?.sub_class.map((classObj) => (
                                <Option
                                  key={classObj._id}
                                  value={classObj.class_name}
                                >
                                  {classObj.class_name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      )}
                      <div id='edit-popup-facility'>
                        <Form.Item
                          name='facility'
                          label={t('recordFacility')}
                          rules={[
                            {
                              required: !isAdmin && !data.facility,
                              message: 'Trường này là bắt buộc!',
                            },
                          ]}
                        >
                          <Select
                            allowClear
                            defaultValue={data.facility}
                            getPopupContainer={() =>
                              document.getElementById('edit-popup-facility')
                            }
                            optionLabelProp='label'
                          >
                            {Object.keys(FACILITY_MAPPING)?.map(
                              (item, index) => (
                                <Option key={index} value={item}>
                                  <div
                                    style={{
                                      whiteSpace: 'wrap',
                                    }}
                                  >
                                    {FACILITY_MAPPING[item].address}
                                  </div>
                                </Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </Panel>
                  {window.location.pathname.includes('student') && (
                    <Panel key='billingInfo' header={t('billingInfo')}>
                      <div className='inline-input'>
                        <Form.Item
                          name='permanent_fee'
                          label={
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <p
                                style={{
                                  marginBottom: '0',
                                  marginRight: '5px',
                                }}
                              >
                                {t('fixedTuition')}{' '}
                              </p>
                              <Tooltip
                                placement='topLeft'
                                title={<p>{t('tuitionHint')}</p>}
                              >
                                <QuestionCircleOutlined />
                              </Tooltip>
                            </div>
                          }
                        >
                          <Input
                            defaultValue={getFee(data?.joined_class)}
                            disabled
                          />
                        </Form.Item>
                        <Form.Item name='act_received' label={t('received')}>
                          <Input defaultValue={data.act_received} disabled />
                        </Form.Item>
                      </div>
                      <div className='inline-input'>
                        <Form.Item name='discount' label={t('discount')}>
                          <Input
                            defaultValue={
                              data.discount === '0' || !data.discount
                                ? ''
                                : data.discount
                            }
                          />
                        </Form.Item>
                        <Form.Item name='unpaid_tuition' label={t('unPaid')}>
                          <Input defaultValue={data.unpaid_tuition} disabled />
                        </Form.Item>
                      </div>
                      <div className='inline-input'>
                        <Form.Item
                          name='paid_tuition_1'
                          label={t('paid1')}
                          rules={[
                            {
                              required: data.paid_tuition_1 ? false : true,
                              message: 'Trường này là bắt buộc!',
                            },
                          ]}
                        >
                          <Input defaultValue={data.paid_tuition_1} />
                        </Form.Item>
                        <Form.Item name='paid_tuition_2' label={t('paid2')}>
                          <Input
                            defaultValue={
                              data.paid_tuition_2 === '0' ||
                              !data.paid_tuition_2
                                ? ''
                                : data.paid_tuition_2
                            }
                          />
                        </Form.Item>
                      </div>
                      <div className='inline-input'>
                        <Form.Item
                          name='transfer_date_1'
                          label={t('paidDate1')}
                          rules={[
                            {
                              required: data.transfer_date_1 ? false : true,
                              message: 'Trường này là bắt buộc!',
                            },
                          ]}
                        >
                          <DatePicker
                            style={{ width: '100%' }}
                            format={'DD/MM/YYYY'}
                            disabled={
                              data.transfer_date_1 &&
                              !userInfo?.user?.role.includes('admin')
                            }
                            defaultValue={
                              data.transfer_date_1 &&
                              moment(
                                moment(data.transfer_date_1).format(
                                  'DD/MM/YYYY'
                                ),
                                'DD/MM/YYYY'
                              )
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          name='transfer_date_2'
                          label={t('paidDate2')}
                        >
                          <DatePicker
                            style={{ width: '100%' }}
                            format={'DD/MM/YYYY'}
                            disabled={
                              data.transfer_date_2 &&
                              !userInfo?.user?.role.includes('admin')
                            }
                            defaultValue={
                              data.transfer_date_2 &&
                              moment(
                                moment(data.transfer_date_2).format(
                                  'DD/MM/YYYY'
                                ),
                                'DD/MM/YYYY'
                              )
                            }
                          />
                        </Form.Item>
                      </div>
                      <div className='inline-input'>
                        <Form.Item
                          name='transfer_method_1'
                          label={t('paymentMethod1')}
                          rules={[
                            {
                              required: data.transfer_method_1 ? false : true,
                              message: 'Trường này là bắt buộc!',
                            },
                          ]}
                        >
                          <Select defaultValue={data.transfer_method_1}>
                            <Option value='Chuyển khoản'>Chuyển khoản</Option>
                            <Option value='Tiền mặt'>Tiền mặt</Option>
                            <Option value='Quẹt thẻ'>Quẹt thẻ</Option>
                            <Option value='Trả góp'>Trả góp</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name='transfer_method_2'
                          label={t('paymentMethod2')}
                        >
                          <Select defaultValue={data.transfer_method_2}>
                            <Option value='Chuyển khoản'>Chuyển khoản</Option>
                            <Option value='Tiền mặt'>Tiền mặt</Option>
                            <Option value='Quẹt thẻ'>Quẹt thẻ</Option>
                            <Option value='Trả góp'>Trả góp</Option>
                          </Select>
                        </Form.Item>
                      </div>

                      <div className='inline-input'>
                        <Form.Item
                          name='transfer_info_1'
                          label={t('paymentContent1')}
                          rules={[
                            {
                              required: data.transfer_info_1 ? false : true,
                              message: 'Trường này là bắt buộc!',
                            },
                          ]}
                        >
                          <Input defaultValue={data.transfer_info_1} />
                        </Form.Item>
                        <Form.Item
                          name='transfer_info_2'
                          label={t('paymentContent2')}
                        >
                          <Input defaultValue={data.transfer_info_2} />
                        </Form.Item>
                      </div>
                    </Panel>
                  )}
                  <Panel key='otherInfo' header={t('otherInfo')}>
                    <div className='inline-input'>
                      <div id='ud-popup-channel'>
                        <Form.Item
                          name='channel'
                          label={t('recordChannel')}
                          rules={[
                            {
                              required: data.channel ? false : true,
                              message: 'Trường này là bắt buộc!',
                            },
                          ]}
                        >
                          <Select
                            getPopupContainer={() =>
                              document.getElementById('ud-popup-channel')
                            }
                            defaultValue={data.channel}
                            style={{
                              width: '100%',
                            }}
                            placeholder={t('typeToSearch')}
                            showSearch
                            allowClear
                            filterOption={(inputValue, option) =>
                              option.value
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) !== -1
                            }
                          >
                            {channels?.map((item) => (
                              <Option key={item._id} value={item.channel_name}>
                                {item.channel_name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                      <div id='popup-level-2'>
                        <Form.Item
                          name='level'
                          label={t('recordClassification')}
                        >
                          <Select defaultValue={data.level}>
                            <Option value=''>Không chọn</Option>
                            {LEVEL_ENUM?.map((item) => (
                              <Option key={item} value={item}>
                                {item}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                    <div className='inline-input'>
                      <div id='ud-popup-status'>
                        <Form.Item name='status' label={t('recordStatus')}>
                          <Select
                            defaultValue={data.status}
                            getPopupContainer={() =>
                              document.getElementById('ud-popup-status')
                            }
                            placeholder={t('typeToSearch')}
                            showSearch
                            allowClear
                            filterOption={(inputValue, option) =>
                              option.value
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) !== -1
                            }
                          >
                            {statusRecord.map((item, index) => (
                              <Option key={index} value={item.value}>
                                {item.value}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                      <div id='add-popup-callback'>
                        <Form.Item name='callback' label={t('recordCallback')}>
                          <DatePicker
                            style={{ width: '100%' }}
                            format='HH:mm, DD/MM/YYYY'
                            showTime={{
                              format: 'HH:mm',
                              showSecond: false,
                            }}
                            defaultValue={
                              data.callback && moment(data.callback)
                            }
                          />
                        </Form.Item>
                      </div>
                    </div>
                    {window.location.pathname.includes('student') && (
                      <div className='inline-input'>
                        <Form.Item
                          name='confirm_mail'
                          label={t('confirmationMail')}
                          rules={[
                            {
                              required: data.confirm_mail ? false : true,
                              message: 'Trường này là bắt buộc!',
                            },
                          ]}
                        >
                          <Input defaultValue={data.confirm_mail} />
                        </Form.Item>
                        <div id='ud-popup-study_status'>
                          <Form.Item
                            name='study_status'
                            label={t('studyStatus')}
                            rules={[
                              {
                                required: data.study_status ? false : true,
                                message: 'Trường này là bắt buộc!',
                              },
                            ]}
                          >
                            <Select
                              getPopupContainer={() =>
                                document.getElementById('ud-popup-study_status')
                              }
                              defaultValue={data.study_status}
                              placeholder={t('typeToSearch')}
                              showSearch
                              allowClear
                              filterOption={(inputValue, option) =>
                                option.value
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              }
                            >
                              {studyStatus?.map((status) => (
                                <Option value={status}>{status}</Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    )}
                    <div className='inline-input'>
                      <div id='edit-popup-potential'>
                        <Form.Item
                          name='potential'
                          label={t('recordPotentail')}
                          rules={[
                            {
                              required: !isAdmin && !data.potential,
                              message: 'Trường này là bắt buộc!',
                            },
                          ]}
                        >
                          <Select
                            defaultValue={data.potential}
                            getPopupContainer={() =>
                              document.getElementById('edit-popup-potential')
                            }
                          >
                            {potentials.map((item, index) => (
                              <Option key={index} value={item}>
                                {item}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                      <div id='popup-customer-type-1'>
                        <Form.Item name='isKhachLe'>
                          <Checkbox
                            defaultChecked={data.isKhachLe}
                            onClick={(e) => setIsKhachLe(e.target.checked)}
                          >
                            {t('standardCustomer')}
                          </Checkbox>
                        </Form.Item>
                        <Form.Item name='isKhachCombo'>
                          <Checkbox
                            defaultChecked={data.isKhachCombo}
                            onClick={(e) => setIsKhachCombo(e.target.checked)}
                          >
                            {t('comboCustomer')}
                          </Checkbox>
                        </Form.Item>
                        <Form.Item name='isKhachHocLen'>
                          <Checkbox
                            defaultChecked={data.isKhachHocLen}
                            onClick={(e) => setIsKhachHocLen(e.target.checked)}
                          >
                            {t('levelUpCustomer')}
                          </Checkbox>
                        </Form.Item>
                        <Form.Item name='isL1L2'>
                          <Checkbox
                            defaultChecked={data.isL1L2}
                            onClick={(e) => setIsL1L2(e.target.checked)}
                          >
                            {t('L1L2')}
                          </Checkbox>
                        </Form.Item>
                        <Form.Item name='isL2L3'>
                          <Checkbox
                            defaultChecked={data.isL2L3}
                            onClick={(e) => setIsL2L3(e.target.checked)}
                          >
                            {t('L2L3')}
                          </Checkbox>
                        </Form.Item>
                      </div>
                    </div>
                  </Panel>
                  <Panel key='note' header={t('recordNote')}>
                    <Form.Item name='note' label={t('recordNote1')}>
                      <TextArea showCount defaultValue={data.note} />
                    </Form.Item>
                    <Form.Item name='note_1' label={t('recordNote2')}>
                      <TextArea showCount defaultValue={data.note} />
                    </Form.Item>
                    <Form.Item name='note_gv' label={t('teacherNote')}>
                      <TextArea showCount defaultValue={data.note_gv} />
                    </Form.Item>
                  </Panel>
                </>
              ) : userInfo.user.username === 'TVTS4' ||
                userInfo?.user?.role.includes('sales_mn') ? (
                <>
                  <Panel key='registrationInfo' header={t('registrationInfo')}>
                    <div className='inline-input'>
                      <div id='ud-popup-col'>
                        <Form.Item
                          name='cosultant'
                          label={t('consultant')}
                          rules={[
                            {
                              required: data.cosultant ? false : true,
                              message: 'Trường này là bắt buộc!',
                            },
                          ]}
                        >
                          <Select
                            getPopupContainer={() =>
                              document.getElementById('ud-popup-col')
                            }
                            defaultValue={data.cosultant}
                          >
                            {(!!ranking ? ranking : cosultants)?.map((item) => (
                              <Option key={item._id} value={item.fullname}>
                                {item.fullname}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </Panel>
                </>
              ) : (
                <div>Bạn không có quyền chỉnh sửa bản ghi này.</div>
              )}
            </Collapse>
          </Form>
        </>
      )}
    </Modal>
  );
};

export default React.memo(UpdateForm);
