import { Button, Checkbox, Form, Input, Modal, Select } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import addCommas from '../../utils/addCommas';
import removeNonNumeric from '../../utils/removeNonNumeric';
import userInfoConfig from '../../utils/userInfoConfig';
import EditableTable from './EditableTable';
import { USER_ROLE_MAPPING } from './SettingUser';
import { userInfo } from '../../utils';
const { Option } = Select;

const CreateForm = ({ visible, onCreate, onCancel, loading }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [count, setCount] = useState(0);
  const [role, setRole] = useState(['sales']);
  const [department, setDepartment] = useState('');
  const [position, setPosition] = useState('');
  const [workingType, setWorkingType] = useState('');
  const [statusWorking, setStatusWorking] = useState('');

  const getDataSource = (data) => {
    setDataSource(data);
  };

  const handleFormValuesChange = () => {
    form.setFieldsValue({
      salary:
        addCommas(parseInt(removeNonNumeric(form.getFieldValue('salary')))) ||
        '0',
    });
  };

  return (
    <Modal
      className='class-setting'
      open={visible}
      title='Thêm User'
      onCancel={onCancel}
      footer={[
        <Button onClick={onCancel}>{t('cancel')}</Button>,
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                values.task = dataSource;
                values.role = role;
                values.department = department;
                values.position = position;
                values.workingType = workingType;
                values.statusWorking = statusWorking;
                console.log('values', values);
                onCreate(values);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          {t('ok')}
        </Button>,
      ]}
    >
      <Form
        form={form}
        onValuesChange={handleFormValuesChange}
        layout='vertical'
        name='form_in_modal'
      >
        <h4>Thông tin cơ bản</h4>
        <div
          style={{
            background: '#b1b1b1',
            width: '100%',
            height: '1px',
            marginBottom: '10px',
          }}
        ></div>
        <div className='inline-input'>
          <Form.Item
            name='fullname'
            label='Tên hiển thị'
            rules={[{ required: true, message: 'Hãy nhập Tên hiển thị!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name='birthday' label='Ngày sinh'>
            <Input type='date' />
          </Form.Item>
        </div>
        <div className='inline-input'>
          <Form.Item
            name='username'
            label='Tên tài khoản'
            rules={[{ required: true, message: 'Hãy nhập Tên tài khoản!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='password'
            label='Mật khẩu'
            rules={[{ required: true, message: 'Mật khẩu là bắt buộc!' }]}
          >
            <Input />
          </Form.Item>
        </div>
        {!userInfo?.user?.role.includes('hr') && (
          <div className='inline-input'>
            <Form.Item name='role' label='Loại tài khoản'>
              <Select
                mode='multiple'
                allowClear
                defaultValue={['sales']}
                onChange={(value) => setRole(value)}
              >
                {Object.entries(USER_ROLE_MAPPING).map(([key, value]) => (
                  <Option value={key}>{value}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name='email' label='Email'>
              <Input />
            </Form.Item>
          </div>
        )}

        <Form.Item name='isCollaborator' valuePropName='checked'>
          <Checkbox>Là CTV</Checkbox>
        </Form.Item>

        <h4>Thông tin khác</h4>
        <div
          style={{
            background: '#b1b1b1',
            width: '100%',
            height: '1px',
            marginBottom: '10px',
          }}
        ></div>
        <div className='inline-input'>
          <Form.Item name='department' label='Phòng ban'>
            <Select onChange={(value) => setDepartment(value)}>
              {userInfoConfig?.department.map((value) => (
                <Option value={value}>{value}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='position' label='Chức danh'>
            <Select onChange={(value) => setPosition(value)}>
              {userInfoConfig?.position.map((value) => (
                <Option value={value}>{value}</Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className='inline-input'>
          <Form.Item name='workingType' label='Hình thức làm việc'>
            <Select onChange={(value) => setWorkingType(value)}>
              {userInfoConfig?.workingType.map((value) => (
                <Option value={value}>{value}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='statusWorking' label='Trạng thái'>
            <Select onChange={(value) => setStatusWorking(value)}>
              {userInfoConfig?.statusWorking.map((value) => (
                <Option value={value}>{value}</Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className='inline-input'>
          <Form.Item
            name='onboardDate'
            label='Ngày bắt đầu làm việc (Mặc định là thời gian tạo tài khoản)'
          >
            <Input type='date' />
          </Form.Item>
          <Form.Item name='contract' label='Hợp đồng làm việc'>
            <Input placeholder='Dán link tài liệu vào đây (Ví dụ Google Drive)' />
          </Form.Item>
        </div>
        {/* <div className="inline-input">
          <Form.Item name="salary" label="Lương cứng (VNĐ)">
            <Input />
          </Form.Item>
        </div> */}

        <EditableTable
          getDataSource={getDataSource}
          dataSource={dataSource}
          count={count}
        />
      </Form>
    </Modal>
  );
};

export default CreateForm;
