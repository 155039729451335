import React from 'react';
import { Skeleton } from 'antd';

const SkeletonStatistic = () => {
  return (
    <div>
      <div className='center' style={{ marginBottom: 20 }}>
        <Skeleton.Avatar active />
        <Skeleton.Input style={{ width: 100, marginLeft: '8px' }} active />
      </div>
      <Skeleton active />
    </div>
  );
};

export default SkeletonStatistic;
