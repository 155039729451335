import {
  CalendarOutlined,
  ControlOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, Modal, Radio, Table, Typography } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ExportExcel from '../../components/ExportExcel/ExportExcel';
import { config, userInfo } from '../../utils';
import Loader from '../Common/Loader/Loader';
import Ripple from '../Common/Ripple';
import '../Common/Table.css';

const { Text } = Typography;

const FinanceTable = ({ history }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updateTable, setUpdatTable] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [dataSheets, setDataSheets] = useState([]);

  const [data, setData] = useState([]);
  const [cosultants, setCosultants] = useState([]);
  const [classes, setClasses] = useState([]);
  const [subClasses, setSubClasses] = useState([]);
  const [classNames, setClassNames] = useState([]);

  const [dateType, setDateType] = useState('');
  const [startMonth, setStartMonth] = useState('');
  const [endMonth, setEndMonth] = useState('');
  const [year, setYear] = useState('');

  const getMonth = (inputDate) => {
    return new Date(inputDate).getMonth() + 1;
  };

  const compareTransferDate = (transferDate, year, startMonth, endMonth) => {
    const transfer = new Date(transferDate);

    return (
      transfer.getMonth() + 1 >= startMonth &&
      transfer.getMonth() + 1 <= endMonth &&
      transfer.getFullYear() == year
    );
  };

  // fetch records
  const fetchData = async () => {
    let url;

    if (userInfo?.user.role.includes('admin')) {
      url = `${process.env.REACT_APP_API_URL}/v1/records?is_register=true&select=joined_class,transfer_date_1,transfer_date_2,month,year,cosultant,paid_tuition_1,paid_tuition_2,act_received,unpaid_tuition,discount,expected_class`;
    } else if (
      userInfo?.user.role.includes('sales') ||
      userInfo?.user.role.includes('sales_mn')
    ) {
      url = `${process.env.REACT_APP_API_URL}/v1/records?user=${userInfo?.user._id}&is_register=true&select=joined_class,transfer_date_1,transfer_date_2,month,year,cosultant,paid_tuition_1,paid_tuition_2,act_received,unpaid_tuition,discount,expected_class`;
    }

    const { data } = await axios.get(url, config);

    if (dateType === 'ngay-khai-giang') {
      const compareClassOpening = (joined_class) => {
        if (!joined_class) return -1;

        const subClass = subClasses.find(
          (sub) => sub.class_name === joined_class
        );

        const openingDate = new Date(subClass && subClass.class_opening);
        return (
          openingDate.getMonth() + 1 >= startMonth &&
          openingDate.getMonth() + 1 <= endMonth &&
          openingDate.getFullYear() == year
        );
      };

      setData(
        data.data.filter((record) => compareClassOpening(record.joined_class))
      );
    } else if (dateType === 'ngay-chuyen-khoan') {
      const filteredData = data.data.filter((record) => {
        return (
          compareTransferDate(
            record.transfer_date_1,
            year,
            startMonth,
            endMonth
          ) ||
          compareTransferDate(
            record.transfer_date_2,
            year,
            startMonth,
            endMonth
          )
        );
      });

      setData(filteredData);
    } else {
      setData(
        data.data.filter(
          (record) =>
            parseInt(record.month) >= parseInt(startMonth) &&
            parseInt(record.month) <= parseInt(endMonth) &&
            parseInt(record.year) == year
        )
      );
    }
  };

  // fetch cosultants
  const fetchCosultants = async () => {
    let url;

    if (userInfo?.user.role.includes('admin')) {
      url = `${process.env.REACT_APP_API_URL}/v1/cosultants`;
    } else if (userInfo?.user.role.includes('sales')) {
      url = `${process.env.REACT_APP_API_URL}/v1/cosultants?fullname=${userInfo?.user.fullname}`;
    }

    const { data } = await axios.get(url, config);

    data.data.forEach((cosItem) => {
      const totalKPI = cosItem.kpi.reduce((acc, curr) => {
        return parseInt(curr.month) >= parseInt(startMonth) &&
          parseInt(curr.month) <= parseInt(endMonth) &&
          curr.year === year
          ? acc + parseInt(removeNonNumeric(curr.kpi))
          : acc;
      }, 0);

      cosItem.kpi = addCommas(totalKPI);
    });

    setCosultants(data.data);
  };

  // fetch classes
  const fetchClasses = async () => {
    let url = `${process.env.REACT_APP_API_URL}/v1/classes`;

    const { data } = await axios.get(url, config);

    let classNames = [];
    let subs = [];

    data.data.forEach((classObj) => {
      classNames.push({ class_name: classObj.class });
      subs.push(...classObj.sub_class);
    });

    setClassNames(classNames);
    setClasses(data.data);
    setSubClasses(subs);
  };

  const addCommas = (num) =>
    num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '';

  const removeNonNumeric = (num) =>
    num ? num.toString().replace(/[^0-9]/g, '') : '';

  useEffect(() => {
    fetchClasses();
  }, []);

  useEffect(() => {
    if (startMonth && endMonth && year && dateType && classes.length > 0) {
      setLoadingTable(true);
      fetchCosultants();
      fetchData();
    }
  }, [updateTable]);

  // console.log(
  //   'data',
  //   data.filter((item) => item.cosultant === 'Nguyễn Thuỳ Linh')
  // );

  useEffect(() => {
    if (
      cosultants.length &&
      data.length &&
      classes.length &&
      classNames.length
    ) {
      const newCosultants = [...cosultants];
      newCosultants.forEach((cos) => {
        cos.kpi = cos.kpi || '∞';

        if (dateType === 'ngay-chuyen-khoan') {
          cos.total_received = addCommas(
            data.reduce((acc, curr) => {
              return curr.cosultant !== cos.fullname
                ? acc
                : acc +
                    (getMonth(curr.transfer_date_1) >= parseInt(startMonth) &&
                    getMonth(curr.transfer_date_1) <= parseInt(endMonth)
                      ? +parseInt(removeNonNumeric(curr.paid_tuition_1 || '0'))
                      : 0) +
                    (getMonth(curr.transfer_date_2) >= parseInt(startMonth) &&
                    getMonth(curr.transfer_date_2) <= parseInt(endMonth)
                      ? +parseInt(removeNonNumeric(curr.paid_tuition_2 || '0'))
                      : 0);
            }, 0) || '0'
          );
        } else {
          cos.total_received = addCommas(
            data.reduce((acc, curr) => {
              return curr.cosultant !== cos.fullname
                ? acc
                : acc + parseInt(removeNonNumeric(curr.act_received || '0'));
            }, 0) || '0'
          );
        }

        cos.total_unpaid = addCommas(
          data.reduce((acc, curr) => {
            return curr.cosultant !== cos.fullname
              ? acc
              : acc + parseInt(removeNonNumeric(curr.unpaid_tuition || '0'));
          }, 0) || '0'
        );
        cos.total_discount = addCommas(
          data.reduce((acc, curr) => {
            return curr.cosultant !== cos.fullname
              ? acc
              : acc + parseInt(removeNonNumeric(curr.discount || '0'));
          }, 0) || '0'
        );

        cos.percent_passed = `${
          Math.round(
            (parseInt(removeNonNumeric(cos.total_received)) /
              parseInt(removeNonNumeric(cos.kpi))) *
              100 *
              100
          ) / 100 || 0
        }%`;

        classNames.forEach((className) => {
          const cosKey = className.class_name;
          cos[cosKey] = data.reduce((acc, curr) => {
            return curr.expected_class === cosKey &&
              curr.cosultant === cos.fullname
              ? acc + 1
              : acc;
          }, 0);
        });

        cos.total_students = addCommas(
          data.reduce((acc, curr) => {
            return curr.cosultant !== cos.fullname ? acc : acc + 1;
          }, 0) || '0'
        );
      });

      newCosultants.sort(
        (a, b) =>
          parseInt(removeNonNumeric(a.total_received)) -
          parseInt(removeNonNumeric(b.total_received))
      );
      setCosultants(newCosultants);
      setLoadingTable(false);
    }
  }, [data, classes, classNames]);

  // data sheets
  useEffect(() => {
    if (cosultants.length && classNames.length) {
      let dataSets = [];

      cosultants.forEach((cos, index) => {
        let dataSet = {};
        dataSet.no = index + 1;
        dataSet.cosultant = cos.fullname;
        classNames.forEach((className) => {
          dataSet[className.class_name] = cos[className.class_name];
        });
        dataSet.total_students = cos.total_students;
        dataSet.total_unpaid = cos.total_unpaid;
        dataSet.total_discount = cos.total_discount;
        dataSet.total_received = cos.total_received;
        dataSet.kpi = cos.kpi;
        dataSet.percent_passed = cos.percent_passed;

        dataSets.push(dataSet);
      });

      let childrenDataSets = [];
      classNames.forEach((classObj) => {
        childrenDataSets.push({
          label: classObj.class_name,
          value: classObj.class_name,
        });
      });

      const columns = [
        {
          label: t('no'),
          value: 'no',
        },
        {
          label: t('recordConsultant'),
          value: 'cosultant',
        },
        { label: t('students'), value: '' },
        ...childrenDataSets,
        { label: t('totalStudents'), value: 'total_students' },
        { label: t('unPaid1'), value: 'total_unpaid' },
        { label: t('totalDiscount'), value: 'total_discount' },
        { label: t('received1'), value: 'total_received' },
        { label: t('kpi'), value: 'kpi' },
        { label: t('percent'), value: 'percent_passed' },
      ];

      const sheet = {
        sheetName: `Quản lý tài chính_${new Date().getTime()}`,
        sheetDataSets: dataSets,
        sheetColumns: columns,
      };

      setDataSheets([...dataSheets, sheet]);
    }
  }, [cosultants, classNames, data]);

  let childrenClasses = [];
  classNames &&
    classNames.forEach((classObj) => {
      childrenClasses.push({
        title: classObj.class_name,
        dataIndex: classObj.class_name,
        width: 160,
      });
    });
  childrenClasses.push({
    title: 'Tổng học viên',
    dataIndex: 'total_students',
    width: 120,
  });

  const columns = [
    {
      title: t('recordConsultant'),
      dataIndex: 'fullname',
      fixed: 'left',
    },
    {
      title: t('numberOfStudents'),
      dataIndex: '',
      children: childrenClasses,
    },
    {
      title: t('unPaid1'),
      dataIndex: 'total_unpaid',
      width: 120,
    },
    {
      title: t('totalDiscount'),
      dataIndex: 'total_discount',
      width: 120,
    },
    {
      title: t('received1'),
      dataIndex: 'total_received',
      width: 120,
    },
    {
      title: t('kpi'),
      dataIndex: 'kpi',
      width: 120,
    },
    {
      title: t('percent'),
      dataIndex: 'percent_passed',
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    form.resetFields();
    setUpdatTable(!updateTable);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <div className='input-table'>
      {!loadingTable ? (
        <>
          <div style={{ marginBottom: 16 }} className='table-header'>
            <div className='left'>
              <Input.Group compact style={{ marginBottom: '20px' }}>
                <div className='filter-zone'>
                  <Button
                    onClick={showModal}
                    type='primary'
                    icon={<ControlOutlined />}
                  >
                    {t('filter')}
                    <Ripple />
                  </Button>
                  <Modal
                    title={t('filterData')}
                    open={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    okText={t('ok')}
                    cancelText={t('cancel')}
                  >
                    <Form form={form} layout='vertical' name='form_in_modal'>
                      <Form.Item name='date-type' label={t('filterBy')}>
                        <Radio.Group
                          onChange={(e) => setDateType(e.target.value)}
                          value={dateType}
                        >
                          <Radio value={'ngay-nhap-lieu'}>Ngày nhập liệu</Radio>
                          <Radio value={'ngay-khai-giang'}>
                            Ngày khai giảng
                          </Radio>
                          <Radio value={'ngay-chuyen-khoan'}>
                            Ngày chuyển khoản
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <div className='inline-input-3'>
                        <Form.Item name='start-month' label={t('fromMonth')}>
                          <Input
                            value={startMonth}
                            onChange={(e) => setStartMonth(e.target.value)}
                            prefix={<CalendarOutlined />}
                          />
                        </Form.Item>
                        <Form.Item name='end-month' label={t('toMonth')}>
                          <Input
                            value={endMonth}
                            onChange={(e) => setEndMonth(e.target.value)}
                            prefix={<CalendarOutlined />}
                          />
                        </Form.Item>
                        <Form.Item name='year' label={t('year')}>
                          <Input
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                            prefix={<CalendarOutlined />}
                          />
                        </Form.Item>
                      </div>

                      <b>{t('financeModalNote')}</b>
                    </Form>
                  </Modal>
                </div>
                {userInfo?.user.role.includes('admin') && (
                  <Link to='/cosultants/setting'>
                    <Button
                      icon={<SettingOutlined />}
                      type='default'
                      style={{ marginRight: '20px', marginLeft: '10px' }}
                    >
                      {t('settings2')}
                      <Ripple />
                    </Button>
                  </Link>
                )}
              </Input.Group>
            </div>
            <div className='right'>
              {dataSheets.length > 0 && (
                <ExportExcel
                  filename={`${userInfo.user.fullname} - Quản lý tài chính`}
                  dataSheets={dataSheets}
                />
              )}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <strong style={{ margin: '0' }}>
              {t('time')}: {t('month')} {startMonth} {'→'} {endMonth}{' '}
              {t('year')} {year} | {t('filterBy')}:{' '}
              {dateType === 'ngay-khai-giang'
                ? 'Ngày khai giảng'
                : dateType === 'ngay-nhap-lieu'
                ? t('recordInputDate')
                : 'Ngày chuyển khoản'}
            </strong>
          </div>
          <Table
            showHeader={[...cosultants]?.length > 0}
            pagination={{ pageSize: 100 }}
            bordered
            sticky
            columns={[...columns]}
            dataSource={[...cosultants]}
            scroll={{ x: 'max-content' }}
            summary={(pageData) => {
              let studentsCount = new Map();
              let totalUnpaid = 0;
              let totalDiscount = 0;
              let totalStudents = 0;
              let totalReceived = 0;
              let totalPassed = 0;
              let countPassed = 0;

              pageData.forEach((props) => {
                const {
                  total_unpaid,
                  total_discount,
                  total_received,
                  total_students,
                  percent_passed,
                } = props;
                Object.keys(props).forEach((key) => {
                  if (key.toString().includes('Lớp')) {
                    studentsCount.set(
                      key,
                      (studentsCount.get(key) || 0) + props[key]
                    );
                  }
                });
                totalUnpaid += parseInt(removeNonNumeric(total_unpaid));
                totalDiscount += parseInt(removeNonNumeric(total_discount));
                totalStudents += parseInt(
                  removeNonNumeric(total_students || '0')
                );
                totalReceived += parseInt(removeNonNumeric(total_received));
                totalPassed += parseFloat(percent_passed) || 0;
                ++countPassed;
              });

              return (
                <Table.Summary>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan='1' align='center'>
                      {t('total')}
                    </Table.Summary.Cell>
                    {classNames.map((className, index) => (
                      <Table.Summary.Cell key={index}>
                        <Text type='danger'>
                          {studentsCount.get(className.class_name)}
                        </Text>
                      </Table.Summary.Cell>
                    ))}
                    <Table.Summary.Cell>
                      <Text type='danger'>{addCommas(totalStudents)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type='danger'>{addCommas(totalUnpaid)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type='danger'>{addCommas(totalDiscount)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type='danger'>{addCommas(totalReceived)}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type='danger'>
                        {t('avg')}:{' '}
                        {Math.round((totalPassed / countPassed) * 100) / 100}%
                      </Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
          />
        </>
      ) : (
        <Loader fullHeight />
      )}
    </div>
  );
};

export default FinanceTable;
