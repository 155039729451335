import { Button, Form, Input, Modal, Spin } from 'antd';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../../utils';
const { TextArea } = Input;

const UpdateForm = ({ visible, onUpdate, onCancel, updateItem, loading }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [defaultlDataSource, setDefaultlDataSource] = useState([]);

  const fetchItem = useCallback(async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1/notifications/${updateItem._id}`,
      config
    );

    setDefaultlDataSource(data.data);
  }, [updateItem]);

  useEffect(() => {
    if (updateItem) {
      fetchItem();
    }
    return () => {
      setDefaultlDataSource(null);
      form.resetFields();
    };
  }, [updateItem, fetchItem, form]);

  return (
    <Modal
      className='class-setting'
      open={visible}
      title='Sửa thông báo'
      onCancel={onCancel}
      footer={[
        <Button onClick={onCancel}>{t('cancel')}</Button>,
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                onUpdate(values);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          {t('ok')}
        </Button>,
      ]}
    >
      {!defaultlDataSource ? (
        <p>
          <Spin /> Đang tải...
        </p>
      ) : (
        <>
          <Form form={form} layout='vertical' name='form_in_modal'>
            <Form.Item name='title' label='Tiêu đề'>
              <Input defaultValue={defaultlDataSource.title} />
            </Form.Item>
            <Form.Item name='description' label='Nội dung'>
              <TextArea
                rows={5}
                defaultValue={defaultlDataSource.description}
              ></TextArea>
            </Form.Item>
          </Form>
        </>
      )}
    </Modal>
  );
};

export default React.memo(UpdateForm);
