import { ConfigProvider } from 'antd';
import 'antd/dist/antd.css';
import viVN from 'antd/lib/locale/vi_VN';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isToday from 'dayjs/plugin/isToday';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './i18n';
import './index.css';
require('dayjs/locale/vi');

dayjs.locale('vi');
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(isToday);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={viVN}>
      <Router>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </Router>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
