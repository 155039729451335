export const APP_VERSION = '63.0.1';

export const LANGUAGES = [
  { label: 'English', code: 'en', img: '/en-flag.png' },
  { label: 'Vietnamese', code: 'vi', img: '/vi-flag.png' },
];

export const LANG_KEY_MAPPING = {
  en: 'English',
  vi: 'Vietnamese',
};

export const REQUIRED_ATTRIBUTES_MAPPING = {
  unregistered: {
    phone: 'recordPhone',
    name: 'recordName',
    class: 'recordExpectedClass',
    channel: 'recordChannel',
    cosultant: 'recordConsultant',
    status: 'recordStatus',
    occupation: 'recordOccupation',
    purpose: 'recordPurpose',
  },
  registered: {
    sub_class: 'class',
    study_status: 'studyStatus',
    paid_tuition_1: 'paid1',
    transfer_method_1: 'paymentMethod1',
    transfer_date_1: 'paidDate1',
    transfer_info_1: 'paymentContent1',
    tuition_status: 'tuitionStatus',
    confirm_mail: 'confirmationMail',
    level: 'recordClassification',
  },
};

export const MONTH_INDEX_NAME_MAPPING = {
  0: 'month0',
  1: 'month1',
  2: 'month2',
  3: 'month3',
  4: 'month4',
  5: 'month5',
  6: 'month6',
  7: 'month7',
  8: 'month8',
  9: 'month9',
  10: 'month10',
  11: 'month11',
};

export const STATISTICS_FIELDS_NAME_MAPPING = {
  total_received: 'totalSales1',
  total_students: 'totalData',
  total_students_registed: 'students',
  registered_rate: 'conversionRate',
  percent_passed: 'percent',
};

export const STATISTICS_TYPE_MAPPING = {
  facility: 'Cơ sở',
  channel: 'Kênh tiếp cận',
  expected_class: 'Lớp học',
  cosultant: 'Tư vấn tuyển sinh',
  study_status: 'Trạng thái học',
  tuition_status: 'Trạng thái học phí',
  status: 'Trạng thái',
  // type_customer: 'Loại khách',
  level: 'Level',
  address: 'Địa chỉ',
  potential: 'Tiềm năng',
  sex: 'Giới tính',
  year_of_birthday: 'Năm sinh',
};

export const FACILITY_MAPPING = {
  // 'Cơ sở 1': 'CƠ SỞ 1: Số 47 Vũ Ngọc Phan,\n Láng Hạ, Đống Đa, Hà Nội',
  // 'Cơ sở 2':
  //   'CƠ SỞ 2: Toà IMPERIA 4,\n Vinhomes SmartCity, Nam Từ Liêm,\n Hà Nội',
  // 'Cơ sở 3': 'CƠ SỞ 3: Số 372/12 Điện Biên Phủ,\n Quận 10, TP. Hồ Chí Minh',
  // 'Cơ sở 4': 'CƠ SỞ 4: Tòa số 160, Nguyễn Ngọc Nại,\n Thanh Xuân, Hà Nội',
  // Online: 'CƠ SỞ TRỰC TUYẾN:\n Học qua Zoom',
  // 'Cơ sở khách': 'CƠ SỞ KHÁCH',

  'Cơ sở Đống Đa': {
    address: 'Cơ sở Đống Đa: Toà 47 Vũ Ngọc Phan, Láng Hạ, Đống Đa, Hà Nội', // CS1
    style: {
      maxWidth: 190,
    },
  },
  'Cơ sở Quận 10': {
    address:
      'Cơ sở Quận 10: Số 372/12 Điện Biên Phủ, Phường 11, Quận 10, TP. HCM', // CS3
    style: {
      maxWidth: 235,
    },
  },
  'Cơ sở Thanh Xuân': {
    address: 'Cơ sở Thanh Xuân: Toà 160 Nguyễn Ngọc Nại, Thanh Xuân, Hà Nội', // CS4
    style: {
      maxWidth: 235,
    },
  },
  Online: {
    address: 'Online: Học qua Zoom', // Online
    style: {
      maxWidth: 300,
    },
  },
  'Cơ sở khách': {
    address: 'Cơ sở khách', // Khách
    style: {
      maxWidth: 300,
    },
  },
};

export const AGE_OPTIONS = ['Từ 20-30 tuổi', 'Từ 30-40 tuổi', 'Trên 40 tuổi'];

export const platforms = [
  {
    name: 'Tất cả',
    alias: '',
  },
  {
    name: 'Facebook',
    alias: 'facebook',
  },
  {
    name: 'Instagram',
    alias: 'instagram_official',
  },
  {
    name: 'Tiktok',
    alias: 'tiktok',
  },
];

export const CLASS_VALEDICTORIAN_FOLDER_PATH = {
  'Lớp 1 Online': 'cert/lv1',
  'Lớp 2 Online': 'cert/lv2',
  'Lớp 3 Online': 'cert/lv3',
  'Level 4 Online': 'cert/lv4',
  'LEVEL A - ONLINE': 'cert/lva',
};

export const VALEDICTORIAN_OPTIONS = [
  {
    value: '1',
    title: 'Thủ khoa đầu ra',
  },
  {
    value: '2',
    title: 'Nỗ lực nhất',
  },
  {
    value: '3',
    title: 'Giọng nói hay nhất',
  },
  {
    value: '4',
    title: 'Thay đổi nhiều nhất',
  },
];
