import { CheckCircleOutlined } from '@ant-design/icons';
import { Avatar, Button, Input, Table, Tag, message } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { socket } from '../../../services/socket';
import { config, userInfo } from '../../../utils';
import Ripple from '../../Common/Ripple';
import { USER_ROLE_MAPPING } from '../../SettingUser/SettingUser';
import './UserSession.css';

const { Search } = Input;

const UserSession = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [updateTable, setUpdatTable] = useState(false);
  const [loadingF5, setLoadingF5] = useState(false);
  const [loadingLogOut, setLoadingLogOut] = useState(false);
  const [udpateItem, setUpdateItem] = useState();

  // fetch records
  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
      return;
    }

    const fetchData = async () => {
      setLoadingTable(true);

      const url = `${process.env.REACT_APP_API_URL}/v1/users`;

      const { data } = await axios.get(url, config);

      data.data.forEach((record, index) => {
        record.key = record._id;
        record.no = index + 1;
      });

      setData(data.data);
      setLoadingTable(false);
    };

    fetchData();
  }, [updateTable]);

  const handleF5 = (userId) => {
    setUpdateItem(userId);
    setLoadingF5(true);
    socket.emit('client: f5', userId);
    setTimeout(() => {
      message.success('Thành công');
      setLoadingF5(false);
    }, 2000);
  };

  const handleF5All = () => {
    socket.emit('client: f5', 'all');
  };

  const handleLogOut = async (userId) => {
    setUpdateItem(userId);
    setLoadingLogOut(true);
    socket.emit('client: logout', userId);
    setTimeout(() => {
      message.success('Thành công');
      setLoadingLogOut(false);
    }, 2000);
  };

  const handleLogOutAll = () => {
    socket.emit('client: logout', 'all');
  };

  const handleSearchUser = (value) => {
    console.log(value);
  };

  const columns = [
    {
      title: t('no'),
      dataIndex: 'no',
      width: 40,
      fixed: 'left',
    },
    {
      title: 'Thao tác',
      dataIndex: '',
      fixed: 'left',
      render: (_, record) => (
        <div className='flex items-center'>
          <Button
            type='default'
            onClick={() => handleF5(record._id)}
            loading={loadingF5 && record._id === udpateItem}
          >
            F5
            <Ripple />
          </Button>
          <Button
            type='default'
            style={{
              marginLeft: 10,
            }}
            onClick={() => handleLogOut(record._id)}
            loading={loadingLogOut && record._id === udpateItem}
          >
            Log out
            <Ripple />
          </Button>
        </div>
      ),
    },
    {
      title: 'Nhân sự',
      dataIndex: 'fullname',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            style={{ marginRight: '10px' }}
            src={record.avatar}
            size='large'
          />
          <Link className='username' to={`/users/${record.id}`}>
            {text}
          </Link>
        </div>
      ),
    },
    {
      title: 'Tài khoản',
      dataIndex: 'username',
      render: (text, record) => `${text}`,
    },
    {
      title: 'Trạng thái TK',
      width: 110,
      render: (text, record) => (
        <Tag icon={<CheckCircleOutlined />} color='success'>
          Đang hoạt động
        </Tag>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 60,
    },
    {
      title: 'Loại tài khoản',
      dataIndex: 'role',
      render: (_, record) => (
        <>
          {record.role.map((role) => `${USER_ROLE_MAPPING[role]}`).join(', ')}
        </>
      ),
    },
    {
      title: 'Phòng ban',
      dataIndex: 'department',
    },
    {
      title: 'Chức danh',
      dataIndex: 'position',
    },
    {
      title: 'Hình thức làm việc',
      width: 150,
      dataIndex: 'workingType',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'statusWorking',
    },
    {
      title: 'Thâm niên',
      dataIndex: 'onboardDate',
      render: (_, record) =>
        `${dayjs(record.onboardDate || record.createdAt).fromNow(true)}`,
    },
    {
      title: t('createdAt'),
      dataIndex: 'createdAt',
      render: (text) => `${dayjs(text).format('DD/MM/YYYY, HH:mm:ss')}`,
    },
  ];

  return (
    <div className='user-session'>
      <div className='user-session-header'>
        <div className='flex items-center'>
          <Button type='primary' onClick={handleF5All}>
            F5 all
            <Ripple />
          </Button>
          <Button
            type='primary'
            style={{
              marginLeft: 10,
            }}
            onClick={handleLogOutAll}
          >
            Log out all
            <Ripple />
          </Button>
        </div>
        <div></div>
        {/* <Search
          placeholder='Search user...'
          style={{ width: 200 }}
          onSearch={handleSearchUser}
        /> */}
      </div>
      <Table
        showHeader={data?.length > 0}
        pagination={{ pageSize: 100 }}
        bordered
        columns={columns}
        dataSource={data}
        scroll={{ x: 'max-content' }}
        showSorterTooltip={true}
        sticky
        loading={loadingTable}
      />
    </div>
  );
};

export default UserSession;
