import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Popconfirm, Table } from 'antd';
import { t } from 'i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import short from 'short-uuid';
import './SettingCosultant.css';
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  type,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const formatPrice = (price) => {
    const addCommas = (num) =>
      num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '';
    const removeNonNumeric = (num) =>
      num ? num.toString().replace(/[^0-9]/g, '') : '';

    return addCommas(removeNonNumeric(price));
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      values.kpi = values.kpi ? formatPrice(values.kpi) : '/';
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} là bắt buộc.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className='editable-cell-value-wrap'
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

class EditableTable extends React.Component {
  constructor(props) {
    super(props);

    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));

    this.columns = [
      {
        title: t('month'),
        dataIndex: 'month',
        editable: true,
      },
      {
        title: t('year'),
        dataIndex: 'year',
        editable: true,
      },
      {
        title: 'KPI',
        dataIndex: 'kpi',
        editable: true,
      },
      {
        title: t('action'),
        dataIndex: 'operation',
        render: (_, record) =>
          this.state.dataSource.length >= 1 ? (
            parseInt(record.year) < 2023 &&
            !this.userInfo?.user.role.includes('admin') ? (
              ''
            ) : (
              <Popconfirm
                title={t('confirmDel')}
                onConfirm={() => this.handleDelete(record.key)}
              >
                <a>{t('delete')}</a>
              </Popconfirm>
            )
          ) : null,
      },
    ];

    this.state = {
      dataSource: this.props.defaultlDataSource || [],
      count: this.props.count || 0,
    };
  }

  handleDelete = (key) => {
    const dataSource = [...this.state.dataSource];
    this.setState({
      dataSource: dataSource.filter((item) => item.key !== key),
    });
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;

    const newData = {
      key: short.generate(),
      month: new Date(Date.now()).getMonth() + 1,
      year: new Date(Date.now()).getFullYear(),
      kpi: '/',
    };

    this.setState({
      dataSource: [...dataSource, newData],
      count: count + 1,
    });
  };

  handleSave = (row) => {
    const newData = [...this.state.dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    this.setState({
      dataSource: newData,
    });
  };

  render() {
    const { dataSource } = this.state;

    if (dataSource) {
      dataSource.map((data) => {
        data.key = short.generate();
      });
    }

    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };

    const columns = this.columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave,
        }),
      };
    });
    return (
      <div>
        <Button
          onClick={this.handleAdd}
          type='primary'
          style={{
            marginBottom: 16,
          }}
        >
          {t('addKPI')}
        </Button>
        <Table
          pagination={{ pageSize: 100 }}
          footer={(currentPageData) => {
            this.props.getDataSource(dataSource);
          }}
          title={() => (
            <div className='table-header-add-class'>
              <InfoCircleOutlined />
              <span>{t('kpiHint1')}</span>
            </div>
          )}
          components={components}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={dataSource}
          columns={columns}
        />
      </div>
    );
  }
}

export default EditableTable;
