import { PlusOutlined } from '@ant-design/icons';
import { Badge, Button, Form, Input, message, Modal, Upload } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { config, getBase64FromFile, userInfo } from '../../utils';
import { getImgUrl } from '../../utils/getImgUrl';
import './Profile.css';

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }

  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }

  return isJpgOrPng && isLt2M;
};

const Profile = () => {
  const [profile, setProfile] = useState({});
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/v1/users/${userInfo?.user?.id}`;

      const { data } = await axios.get(url, config);

      setProfile(data.data);
      setPreviewImage(data?.data?.avatar || '');
    };

    fetchData();
  }, []);

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64FromFile(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  const handleCancel = () => setPreviewVisible(false);

  const uploadButton = (
    <div>
      {previewImage ? (
        <img
          src={previewImage}
          alt='avatar'
          style={{
            width: '100%',
            height: 100,
            objectFit: 'cover',
          }}
        />
      ) : (
        <>
          <PlusOutlined />
          <div
            style={{
              marginTop: 8,
            }}
          >
            Chọn ảnh
          </div>
        </>
      )}
    </div>
  );

  const handleSave = async () => {
    setLoading(true);

    let avatar = '';
    if (fileList[0]?.originFileObj) {
      if (!beforeUpload(fileList[0].originFileObj)) {
        setLoading(false);
        return;
      } else {
        avatar = await getImgUrl(fileList[0].originFileObj);
      }
    }

    const url = `${process.env.REACT_APP_API_URL}/v1/users/${userInfo?.user?.id}`;

    const { data: dataUser } = await axios.put(url, { avatar }, config);

    if (dataUser.success) {
      message.success('Cập nhật thành công');
      setLoading(false);
      const userInfoFromLocal = JSON.parse(localStorage.getItem('userInfo'));
      userInfoFromLocal.user.avatar =
        dataUser.data.avatar || userInfoFromLocal.user.avatar;
      localStorage.setItem('userInfo', JSON.stringify(userInfoFromLocal));
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  return (
    <div className='profile-wrapper'>
      <Form
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout='horizontal'
        onSubmi
      >
        <Form.Item label='Tên hiển thị'>
          <Input disabled placeholder={profile?.fullname} />
        </Form.Item>
        <Form.Item label='Username'>
          <Input disabled placeholder={profile?.username} />
        </Form.Item>
        <Form.Item label='Trạng thái tài khoản'>
          <Badge
            count={'Đang hoạt động'}
            style={{
              backgroundColor: '#16c784',
            }}
          />
        </Form.Item>
        <Form.Item label='Avatar'>
          <Upload
            fileList={fileList}
            name='avatar'
            listType='picture-card'
            beforeUpload={() => {
              return false;
            }}
            onChange={handleChange}
            onPreview={handlePreview}
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>
          <Modal
            open={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img
              alt='preview'
              style={{
                width: '100%',
              }}
              src={previewImage}
            />
          </Modal>
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 4,
            span: 14,
          }}
        >
          <Button type='primary' onClick={handleSave} loading={loading}>
            Lưu
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Profile;
