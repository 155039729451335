import React from 'react';
import InputTable from '../components/InputTable/InputTable';
import HeaderV2 from '../components/Header/HeaderV2';
import history from '../history';

const TiktokAds = () => {
  return (
    <div style={{ margin: '20px' }}>
      <HeaderV2 history={history} />
      <InputTable tiktokAds />
    </div>
  );
};

export default TiktokAds;
