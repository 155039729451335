import { FileAddOutlined } from '@ant-design/icons';
import { Button, message, Select, Table, Typography } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { config, userInfo } from '../../utils';
import addCommas from '../../utils/addCommas';
import removeNonNumeric from '../../utils/removeNonNumeric';
import './ManageRevenue.css';
import UpdateForm from './UpdateForm';

const ManageRevenue = () => {
  const { t } = useTranslation();
  const [year, setYear] = useState(dayjs().year());
  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [revenueData, setRevenueData] = useState();

  // get array of years
  const years = useMemo(() => {
    if (userInfo?.user?.role.includes('sales_mn')) {
      return [2023];
    }

    const currYear = dayjs().year();
    const yearsArr = [];
    for (let i = 2021; i <= currYear; ++i) {
      yearsArr.push(i);
    }

    return yearsArr;
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/statistics/revenue`,
        {},
        config
      );

      setRevenueData(data?.data);
    };

    fetchData();
  }, []);

  const dataSource = useMemo(() => {
    return (
      revenueData?.revenue[year]
        .sort((a, b) => a.month - b.month)
        .map((item, index) => ({
          ...item,
          key: index,
        })) || []
    );
  }, [year, revenueData]);

  const columns = [
    {
      title: t('month'),
      dataIndex: 'month',
      key: 'month',
    },
    {
      title: 'Doanh số',
      dataIndex: 'revenue',
      key: 'revenue',
    },
    {
      title: 'KPI',
      dataIndex: 'kpiRevenue',
      render: (text) => `${addCommas(removeNonNumeric(text))}`,
      key: 'kpiRevenue',
    },
    {
      title: '% Đạt',
      dataIndex: 'percentKpiRevenue',
      render: (text, record) => (
        <span
          className={
            parseInt(removeNonNumeric(record.revenue)) >
            parseInt(removeNonNumeric(record.kpiRevenue))
              ? 'greater'
              : 'lower'
          }
        >
          {text}
        </span>
      ),
      key: 'percentKpiRevenue',
    },
    {
      title: t('student'),
      dataIndex: 'student',
      key: 'student',
    },
    {
      title: 'KPI',
      dataIndex: 'kpiStudent',
      key: 'kpiStudent',
    },
    {
      title: '% Đạt',
      dataIndex: 'percentKpiStudent',
      render: (text, record) => (
        <span
          className={
            parseInt(removeNonNumeric(record.student)) >
            parseInt(removeNonNumeric(record.kpiStudent))
              ? 'greater'
              : 'lower'
          }
        >
          {text}
        </span>
      ),
      key: 'percentKpiStudent',
    },
    {
      title: 'Doanh số TB/Khách',
      dataIndex: 'revenuePerStudent',
      key: 'revenuePerStudent',
    },
    {
      title: 'Tổng lớp',
      dataIndex: 'class',
      key: 'class',
    },
    {
      title: 'KPI',
      dataIndex: 'kpiClass',
      key: 'kpiClass',
    },
  ];

  const expandedRowRender = (record, index, indent, expanded) => {
    const columns = [];

    const data = [];

    return (
      <Table
        className='nested-table'
        columns={columns}
        dataSource={data}
        pagination={false}
        showHeader={false}
      />
    );
  };

  const onUpdate = (values) => {
    const updateRecord = async () => {
      setLoadingUpdate(true);

      await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/revenues/63ed27fab5e8b5a0eae610df`,
        values,
        config
      );

      setLoadingUpdate(false);
      setVisibleUpdate(false);
      message.success('Cập nhật thành công');
    };

    updateRecord();
  };

  return (
    <div className='statistics mn-revenue'>
      <div className='statistics-header'>
        <div className='statistics-header__main'>
          <div className='statistics-time'>
            <Typography.Text>{t('year')}: </Typography.Text>
            <Select
              style={{ width: 120 }}
              defaultValue={year}
              onChange={(value) => setYear(value)}
            >
              {years.map((year) => (
                <Select.Option value={year}>{year}</Select.Option>
              ))}
            </Select>
          </div>

          <div className='statistics-set-kpi'>
            {userInfo?.user?.role.includes('admin') && (
              <Button
                icon={<FileAddOutlined />}
                type='primary'
                onClick={() => {
                  setVisibleUpdate(true);
                }}
              >
                Sửa KPI
              </Button>
            )}
            <UpdateForm
              visible={visibleUpdate}
              onUpdate={onUpdate}
              onCancel={() => {
                setVisibleUpdate(false);
              }}
              loading={loadingUpdate}
            />
          </div>
        </div>
        {revenueData && (
          <small style={{ marginRight: 20 }}>
            {t('recordLastUpdated')}:{' '}
            {dayjs(revenueData.last_updated).format('HH:mm, DD/MM/YYYY')}
          </small>
        )}
      </div>
      <div className='statistics-header'>
        <Table
          showHeader={dataSource.length > 0}
          pagination={{ pageSize: 100 }}
          bordered
          loading={!revenueData}
          columns={columns}
          expandable={{
            expandedRowRender,
          }}
          dataSource={dataSource}
        />
      </div>
    </div>
  );
};

export default ManageRevenue;
