const getCert = (name, course, open, end, width = '1100px') => {
  return `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Cert</title>
      <style>
        @import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap");
        @import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;700&display=swap");
  
        .cert-wrapper * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }

        .cert-wrapper p {
          margin-bottom: 0 !important;
        }
  
        .cert-wrapper {
          position: relative;
          width: ${width};
          height: 777px;
          margin: 0 auto;
        }

        .cert-content {
          position: absolute;
          top: 250px;
          left: 50%;
          width: 100%;
          transform: translateX(-50%);
        }
        .cert-name {
          font-family: "Dancing Script", cursive;
          font-size: 72pt;
          font-weight: 700;
          white-space: nowrap;
          text-align: center;
        }
        .cert-bg img {
          width: 100%;
          height: 100%;
          image-rendering: -moz-crisp-edges; /* Firefox */
          image-rendering: -o-crisp-edges; /* Opera */
          image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
          image-rendering: crisp-edges;
          -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
        }
        .cert-description {
          font-family: "Maven Pro", sans-serif;
          font-weight: 400;
          font-size: 18pt;
          text-align: center;
          white-space: nowrap;
          margin-top: -5px;
          line-height: 1.4 !important;
        }
        .cert-description__bold {
          font-weight: 500;
        }
        .cert-time {
          float: right;
          transform: translateX(-155px);
          font-family: "Maven Pro", sans-serif;
          font-weight: 600;
          font-size: 16pt;
          margin-top: 5px;
          white-space: nowrap;
        }
      </style>
    </head>
    <body>
      <div class="cert-wrapper">
        <div class="cert-bg">
          <img src="/cert-bg-2.jpg" alt="Thalic Cert" />
        </div>
        <div class="cert-content">
          <div class="cert-name">${name}</div>
          <div class="cert-description">
            <p>Đã hoàn thành khoá học</p>
            <p>
              <span class="cert-description__bold"
                >"${course}"</span
              >
              tại THALIC VOICE
            </p>
            <p>từ ngày ${open} đến ngày ${end}.</p>
          </div>
          <div class="cert-time">
            <p>Hà Nội, ngày ${end}</p>
          </div>
        </div>
      </div>
    </body>
  </html>
  `;
};

export default getCert;
