import { DeleteOutlined, FileAddOutlined } from '@ant-design/icons';
import { Button, Table, Typography, message } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Confirm } from 'react-st-modal';
import Spinner from '../../components/Spinner/Spinner';
import { config, userInfo } from '../../utils';
import '../Common/Table.css';
import CreateForm from './CreateForm';
import UpdateForm from './UpdateForm';

const { Text } = Typography;

const SettingClass = () => {
  const { t } = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [updateTable, setUpdatTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [updateItem, setUpdateItem] = useState(null);
  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const [teachers, setTeachers] = useState([]);
  const [assistants, setAssistants] = useState([]);

  const fetchTeachersAndAssistants = useCallback(async () => {
    const url = `${process.env.REACT_APP_API_URL}/v1/users?select=role,fullname`;

    const { data } = await axios.get(url, config);

    const filteredTeachers = data.data.filter((user) =>
      user.role.includes('teacher')
    );
    const filteredAssistants = data.data.filter((user) =>
      user.role.includes('assistant')
    );

    setTeachers(filteredTeachers);
    setAssistants(filteredAssistants);
  }, []);

  const fetchData = useCallback(async () => {
    setLoadingTable(true);

    const url = `${process.env.REACT_APP_API_URL}/v1/classes?select=-sub_class`;

    const { data } = await axios.get(url, config);

    data.data.forEach((record, index) => {
      record.key = record._id;
      record.no = index + 1;
    });

    setData(data.data);
    setLoadingTable(false);
  }, []);

  useEffect(() => {
    fetchTeachersAndAssistants();
  }, [fetchTeachersAndAssistants]);

  // fetch records
  useEffect(() => {
    fetchData();
  }, [updateTable, fetchData]);

  const onUpdate = (values) => {
    const updateRecord = async () => {
      setLoadingUpdate(true);
      await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/classes/${updateItem._id}`,
        values,
        config
      );

      setLoadingUpdate(false);

      setUpdatTable(!updateTable);
      setVisibleUpdate(false);

      message.success('Cập nhật thành công');
    };

    updateRecord();
  };

  // create new record
  const onCreate = (values) => {
    const createRecord = async () => {
      setLoadingCreate(true);

      await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/classes`,
        values,
        config
      );

      setLoadingCreate(false);

      setVisibleCreate(false);
      setUpdatTable(!updateTable);

      message.success('Thêm thành công');
    };

    createRecord();
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const handleDelete = async () => {
    setLoading(true);

    const deleteById = async (id) => {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/v1/classes/${id}`,
        config
      );

      setUpdatTable(!updateTable);
    };

    const result = await Confirm(t('confirmDelete'));

    if (result) {
      selectedRowKeys.map((id) => {
        deleteById(id);
      });

      setLoading(false);
      setSelectedRowKeys([]);

      message.success(t('deletedSuccessfully'));
    } else {
      setLoading(false);
      setSelectedRowKeys([]);
    }
  };

  const columns = [
    {
      title: t('edit'),
      dataIndex: '',
      fixed: 'left',
      render: (_, record) => (
        <>
          <Button
            onClick={() => {
              setVisibleUpdate(true);
              setUpdateItem(record);
            }}
          >
            {t('edit')}
          </Button>
        </>
      ),
    },
    {
      title: t('no'),
      dataIndex: 'no',
    },
    {
      title: 'Lớp chính',
      dataIndex: 'class',
    },
    {
      title: 'Học phí',
      dataIndex: 'fee',
    },
    {
      title: t('createdAt'),
      dataIndex: 'createdAt',
      render: (_, record) =>
        dayjs(record.createdAt).format('DD/MM/YYYY, HH:mm:ss'),
    },
    {
      title: t('updatedAt'),
      dataIndex: 'updatedAt',
      render: (_, record) =>
        dayjs(record.updatedAt).format('DD/MM/YYYY, HH:mm:ss'),
    },
  ];

  return (
    <div className='input-table'>
      {!loadingTable ? (
        <>
          <div style={{ marginBottom: 16 }} className='table-header'>
            <div className='left'>
              <div className='delete-zone'>
                <Button
                  className='delete-btn'
                  type='default'
                  onClick={handleDelete}
                  disabled={!hasSelected}
                  loading={loading}
                  icon={<DeleteOutlined />}
                >
                  {t('delete')}
                </Button>
                <span style={{ marginLeft: 8 }}>
                  {hasSelected ? (
                    <Text>
                      {selectedRowKeys.length} {t('selected')}
                    </Text>
                  ) : (
                    ''
                  )}
                </span>
              </div>
              <div className='add-zone'>
                <Button
                  className='add-btn'
                  icon={<FileAddOutlined />}
                  type='text'
                  onClick={() => {
                    setVisibleCreate(true);
                  }}
                >
                  {t('createRecord')}
                </Button>
              </div>
            </div>
          </div>
          <Table
            pagination={{ pageSize: 100 }}
            bordered
            rowSelection={
              userInfo?.user?.role.includes('admin') && rowSelection
            }
            columns={columns}
            dataSource={data}
            scroll={{ x: 'max-content' }}
            showSorterTooltip={true}
            sticky
          />
          <CreateForm
            visible={visibleCreate}
            onCreate={onCreate}
            onCancel={() => {
              setVisibleCreate(false);
            }}
            loading={loadingCreate}
          />
          <UpdateForm
            visible={visibleUpdate}
            onUpdate={onUpdate}
            onCancel={() => {
              setVisibleUpdate(false);
            }}
            updateItem={updateItem}
            loading={loadingUpdate}
            assistants={assistants}
            teachers={teachers}
          />
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default SettingClass;
