import React from 'react';
import './App.css';
import { useAuth } from './hooks/useAuth';
import DefaultLayout from './layouts/DefaultLayout';
import OnlyTikTok from './pages/OnlyTikTok';
import TiktokAds from './pages/TiktokAds';
import { userInfo } from './utils';

function App() {
  useAuth();

  return (
    <>
      {userInfo?.user?.role.includes('tiktok1') ? (
        <OnlyTikTok />
      ) : userInfo?.user?.role.includes('tiktok_ads') ? (
        <TiktokAds />
      ) : (
        <DefaultLayout />
      )}
    </>
  );
}

export default App;
