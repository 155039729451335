import React from 'react';
import './Watermark.css';

const Watermark = ({ children }) => {
  return (
    <div className='thalic-watermark'>
      <div
        className='thalic-watermark-overlay'
        style={{ backgroundImage: `url('/thalic-watermark.png')` }}
      ></div>
      {children}
    </div>
  );
};

export default Watermark;
