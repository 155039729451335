import { Button, DatePicker, Form, Input, Modal, Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import readNumber from 'read-vn-number';
import addCommas from '../../utils/addCommas';
import removeNonNumeric from '../../utils/removeNonNumeric';

const UpdateSubClassForm = ({
  visible = false,
  onUpdate,
  onCancel,
  loading = false,
  teachers = [],
  assistants = [],
  updateItem,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [price, setPrice] = useState('');

  const handleFormValuesChange = (_, allValues) => {
    form.setFieldsValue({
      class_fee: addCommas(removeNonNumeric(allValues.class_fee)),
    });
    setPrice(addCommas(removeNonNumeric(allValues.class_fee)));
  };

  useEffect(() => {
    form.resetFields();
  }, [updateItem]);

  return (
    <>
      {updateItem && (
        <Modal
          width={600}
          open={visible}
          title={t('Cập nhật bản ghi')}
          onCancel={onCancel}
          footer={[
            <Button onClick={onCancel}>{t('cancel')}</Button>,
            <Button
              key='submit'
              type='primary'
              loading={loading}
              onClick={() => {
                form
                  .validateFields()
                  .then((values) => {
                    if (values.class_teacher?.value) {
                      values.class_teacher = values.class_teacher.key;
                    }
                    if (values.class_teaching_assistant?.value) {
                      values.class_teaching_assistant =
                        values.class_teaching_assistant.key;
                    }
                    form.resetFields();
                    console.log('values', values);
                    onUpdate(values);
                  })
                  .catch((info) => {
                    console.log('Validate Failed:', info);
                  });
              }}
            >
              {t('ok')}
            </Button>,
          ]}
        >
          <Form
            form={form}
            layout='vertical'
            name='form_in_modal'
            onValuesChange={handleFormValuesChange}
            initialValues={{
              class_name: updateItem?.class_name,
              class_teacher: updateItem?.class_teacher._id || '/',
              class_teaching_assistant:
                updateItem?.class_teaching_assistant._id || '/',
              class_fee: updateItem?.class_fee,
              class_opening:
                updateItem.class_opening &&
                moment(
                  moment(updateItem.class_opening).format('DD/MM/YYYY'),
                  'DD/MM/YYYY'
                ),
              class_schedule: updateItem?.class_schedule,
              class_hour: updateItem?.class_hour,
              class_end:
                updateItem.class_end &&
                moment(
                  moment(updateItem.class_end).format('DD/MM/YYYY'),
                  'DD/MM/YYYY'
                ),
            }}
          >
            <Form.Item
              name='class_name'
              label={t('Tên lớp')}
              rules={[
                {
                  required: true,
                  message: 'Trường này là bắt buộc!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item name='class_teacher' label={t('Giáo viên')}>
              <Select
                showSearch
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                labelInValue
                placeholder='Chọn giáo viên'
                style={{ width: '100%' }}
                allowClear
              >
                {teachers?.map((teacher) => (
                  <Select.Option key={teacher._id} value={teacher._id}>
                    {teacher.fullname}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name='class_teaching_assistant' label={t('Trợ giảng')}>
              <Select
                showSearch
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                labelInValue
                placeholder='Chọn trợ giảng'
                style={{ width: '100%' }}
                allowClear
              >
                {assistants?.map((assistant) => (
                  <Select.Option key={assistant._id} value={assistant._id}>
                    {assistant.fullname}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name='class_fee' label={t('Học phí riêng')}>
              <Input />
            </Form.Item>
            {price && (
              <p className='fee-text'>
                Giá bằng chữ:{' '}
                <span>
                  {readNumber(parseInt(price.split('.').join('')))} đồng
                </span>
              </p>
            )}
            <Form.Item name='class_opening' label={t('classOpening')}>
              <DatePicker style={{ width: '100%' }} format='DD/MM/YYYY' />
            </Form.Item>
            <Form.Item name='class_schedule' label={t('classSchedule')}>
              <Input />
            </Form.Item>
            <Form.Item name='class_hour' label={t('classTime')}>
              <Input />
            </Form.Item>
            <Form.Item name='class_end' label={t('classEnding')}>
              <DatePicker style={{ width: '100%' }} format='DD/MM/YYYY' />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
};

export default UpdateSubClassForm;
