import {
  CheckCircleOutlined,
  DeleteOutlined,
  FileAddOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Table, Tabs, Tag, Typography, message } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Confirm } from 'react-st-modal';

import { useTranslation } from 'react-i18next';
import { config, userInfo } from '../../utils';
import '../Common/Table.css';
import Spinner from '../Spinner/Spinner';
import CreateForm from './CreateForm';
import SalaryTable from './SalaryTable';
import UpdateForm from './UpdateForm';

const { Text } = Typography;

export const USER_ROLE_MAPPING = {
  sales: 'Sales',
  hr: 'Hành chính nhân sự',
  teacher: 'Giáo viên',
  assistant: 'Trợ giảng',
  accountant: 'Kế toán',
  dev: 'Dev',
  cskh: 'Chăm sóc khách hàng',
  only_class: 'Chỉ xem danh sách lớp',
  only_set_class: 'Chỉ quản lý danh sách lớp',
  sales_mn: 'Sales manager',
  marketing: 'Marketing',
  unknown_1: 'Chưa phân loại 1',
  tiktok_ads: 'Tiktok Ads',
  tiktok1: 'Tiktok 1',
  admin: 'Admin',
};

const SettingUser = ({ history }) => {
  const { t } = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState([]);
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [updateTable, setUpdatTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [updateItem, setUpdateItem] = useState(null);
  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  // fetch records
  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
      return;
    }

    const fetchData = async () => {
      setLoadingTable(true);

      const url = `${process.env.REACT_APP_API_URL}/v1/users`;

      const { data } = await axios.get(url, config);

      data.data.forEach((record, index) => {
        record.key = record._id;
        record.no = index + 1;
      });

      setData(data.data);
      setLoadingTable(false);
    };

    fetchData();
  }, [updateTable]);

  const onUpdate = (values) => {
    const updateRecord = async () => {
      setLoadingUpdate(true);

      await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/users/${updateItem._id}`,
        values,
        config
      );

      setLoadingUpdate(false);

      setUpdatTable(!updateTable);
      setVisibleUpdate(false);

      message.success('Cập nhật thành công');
    };

    updateRecord();
  };

  // create new record
  const onCreate = (values) => {
    const createRecord = async () => {
      setLoadingCreate(true);

      await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/users`,
        values,
        config
      );

      setLoadingCreate(false);

      setVisibleCreate(false);
      setUpdatTable(!updateTable);

      message.success('Thêm thành công');
    };

    createRecord();
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const handleDelete = async () => {
    setLoading(true);

    const deleteById = async (id) => {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/v1/users/${id}`,
        config
      );

      setUpdatTable(!updateTable);
    };

    const result = await Confirm(
      'Việc xoá sẽ ảnh hưởng đến các bản ghi hiện tại, chắc chắn xoá?'
    );

    if (result) {
      selectedRowKeys.forEach((id) => {
        deleteById(id);
      });

      setLoading(false);
      setSelectedRowKeys([]);

      message.success(t('deletedSuccessfully'));
    } else {
      setLoading(false);
      setSelectedRowKeys([]);
    }
  };

  const columns = [
    {
      title: t('no'),
      dataIndex: 'no',
      width: 40,
      fixed: 'left',
    },
    {
      title: 'Thao tác',
      dataIndex: '',
      fixed: 'left',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            onClick={() => {
              setVisibleUpdate(true);
              setUpdateItem(record);
            }}
          >
            {t('edit')}
          </Button>
          <Button style={{ marginLeft: 10 }}>
            <Link to={`/users/${record._id}`}>Xem</Link>
          </Button>
        </div>
      ),
    },
    {
      title: 'Nhân sự',
      dataIndex: 'fullname',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            style={{ marginRight: '10px' }}
            src={record.avatar}
            size='large'
          />
          <Link className='username' to={`/users/${record.id}`}>
            {text}
          </Link>
        </div>
      ),
    },
    {
      title: 'Tài khoản',
      dataIndex: 'username',
      render: (text, record) => `${text}`,
    },
    {
      title: 'Trạng thái TK',
      width: 110,
      render: (text, record) => (
        <Tag icon={<CheckCircleOutlined />} color='success'>
          Đang hoạt động
        </Tag>
        // <div
        //   style={{
        //     width: '20px',
        //     height: '20px',
        //     borderRadius: '50%',
        //     background: '#2ecc71',
        //     display: 'flex',
        //     alignItems: 'center',
        //   }}
        // ></div>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 60,
    },
    {
      title: 'Loại tài khoản',
      dataIndex: 'role',
      render: (_, record) => (
        <>
          {record.role.map((role) => `${USER_ROLE_MAPPING[role]}`).join(', ')}
        </>
      ),
    },
    {
      title: 'Phòng ban',
      dataIndex: 'department',
    },
    {
      title: 'Chức danh',
      dataIndex: 'position',
    },
    {
      title: 'Hình thức làm việc',
      width: 150,
      dataIndex: 'workingType',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'statusWorking',
    },
    {
      title: 'Thâm niên',
      dataIndex: 'onboardDate',
      render: (_, record) =>
        `${dayjs(record.onboardDate || record.createdAt).fromNow(true)}`,
    },
    {
      title: t('createdAt'),
      dataIndex: 'createdAt',
      render: (text) => `${dayjs(text).format('DD/MM/YYYY, HH:mm:ss')}`,
    },
  ];

  const userList = () => (
    <div className='input-table'>
      {!loadingTable ? (
        <>
          <div style={{ marginBottom: 16 }} className='table-header'>
            <div className='left'>
              <div className='delete-zone'>
                <Button
                  className='delete-btn'
                  type='default'
                  onClick={handleDelete}
                  disabled={!hasSelected}
                  loading={loading}
                  icon={<DeleteOutlined />}
                >
                  {t('delete')}
                </Button>
                <span style={{ marginLeft: 8 }}>
                  {hasSelected ? (
                    <Text>
                      {selectedRowKeys.length} {t('selected')}
                    </Text>
                  ) : (
                    ''
                  )}
                </span>
              </div>
              <div className='add-zone'>
                <Button
                  className='add-btn'
                  icon={<FileAddOutlined />}
                  type='text'
                  onClick={() => {
                    setVisibleCreate(true);
                  }}
                >
                  {t('createRecord')}
                </Button>
              </div>
            </div>
          </div>
          <h3>Danh sách nhân sự</h3>
          <Table
            showHeader={data?.length > 0}
            pagination={{ pageSize: 100 }}
            bordered
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            scroll={{ x: 'max-content' }}
            showSorterTooltip={true}
            sticky
          />
          <CreateForm
            visible={visibleCreate}
            onCreate={onCreate}
            onCancel={() => {
              setVisibleCreate(false);
            }}
            loading={loadingCreate}
          />
          <UpdateForm
            visible={visibleUpdate}
            onUpdate={onUpdate}
            onCancel={() => {
              setVisibleUpdate(false);
            }}
            updateItem={updateItem}
            loading={loadingUpdate}
          />
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );

  const salaryContent = () => (
    <div className='input-table'>
      <h3>Bảng lương và phúc lợi</h3>
      {!loadingTable ? (
        <>
          <SalaryTable users={data} />
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );

  const onChange = (key) => {
    console.log(key);
  };

  return (
    <div className='setting-user'>
      <Tabs onChange={onChange} type='card'>
        <Tabs.TabPane tab='Danh sách nhân sự' key='hr'>
          {userList()}
        </Tabs.TabPane>
        <Tabs.TabPane tab='Bảng lương và phúc lợi' key='salary'>
          {salaryContent()}
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default SettingUser;
