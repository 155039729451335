import { Button, Spin, Tabs, message } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import uuid from 'short-uuid';
import { config } from '../../utils';
import './SystemConfig.css';
import ChannelConfigItem from './channel/ChannelConfigItem';

const SystemConfig = () => {
  const { t } = useTranslation();
  const [configs, setConfigs] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  useEffect(() => {
    const fetchConfigs = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/configs`,
          config
        );

        setConfigs(data.data);
      } catch (error) {
        message.error('Có lỗi khi lấy dữ liệu');
      } finally {
        setLoading(false);
      }
    };

    fetchConfigs();
  }, []);

  const handleDeleteChannel = (id) => {
    const newConfigs = { ...configs };
    newConfigs.channels = newConfigs.channels.filter(
      (channel) => channel._id !== id
    );
    setConfigs(newConfigs);
  };

  const handleAddChannel = () => {
    const newConfigs = { ...configs };
    newConfigs.channels = [
      ...newConfigs.channels,
      {
        channel_name: '',
        _id: `short-uuid_${uuid.generate()}`,
      },
    ];
    setConfigs(newConfigs);
  };

  const handleChangeChannel = (id, value) => {
    const newConfigs = { ...configs };
    newConfigs.channels.find((channel) => {
      if (channel._id === id) {
        channel.channel_name = value;
        return true;
      }
      return false;
    });
    setConfigs(newConfigs);
  };

  const handleSave = async () => {
    try {
      setLoadingUpdate(true);

      const newConfigs = { ...configs };
      newConfigs.channels.map(
        (item) => item._id.includes('short-uuid') && delete item._id
      );

      await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/configs`,
        newConfigs,
        config
      );

      message.success('Lưu cấu hình thành công');
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingUpdate(false);
    }
  };

  const renderChannelConfig = () => {
    return (
      <>
        {!loading ? (
          <>
            {configs?.channels.map((item, index) => (
              <ChannelConfigItem
                key={item._id}
                data={item}
                handleDelete={handleDeleteChannel}
                handleAdd={handleAddChannel}
                handleChange={handleChangeChannel}
                isLast={index === configs?.channels.length - 1}
              />
            ))}

            <Button
              type='primary'
              style={{ marginTop: 10 }}
              onClick={handleSave}
              loading={loadingUpdate}
            >
              Lưu cấu hình
            </Button>
          </>
        ) : (
          <Spin />
        )}
      </>
    );
  };

  const tabItems = [
    {
      label: t('recordChannel'),
      key: 'channel',
      children: renderChannelConfig(),
    },
  ];

  return (
    <div className='system-config'>
      <Tabs defaultActiveKey='1'>
        {tabItems.map((tab) => (
          <Tabs.TabPane tab={tab.label} key={tab.key}>
            {tab.children}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default SystemConfig;
