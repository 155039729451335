import { Tabs } from 'antd';
import React from 'react';
import UserSession from './UserSession/UserSession';
import './index.css';

const items = [
  {
    key: '1',
    label: 'User session',
    children: <UserSession />,
  },
];

const Extras = () => {
  const onChange = (key) => {
    console.log(key);
  };

  return (
    <div className='extras'>
      <Tabs defaultActiveKey='1' items={items} onChange={onChange} />
    </div>
  );
};

export default Extras;
