import { RedoOutlined, ScheduleOutlined } from '@ant-design/icons';
import { Button, Form, List, Select, Typography } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { config, userInfo } from '../../utils';
import SkeletonList from '../SubClassList/SkeletonList';
import './Task.css';
const { Option } = Select;
const { Text, Title } = Typography;

const Task = ({ history }) => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [spin, setSpin] = useState(false);
  const [updateTask, setUpdateTask] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
      return;
    }

    const fetchTasks = async () => {
      setSpin(true);
      setLoading(true);

      const url = `${process.env.REACT_APP_API_URL}/v1/users/${userInfo.user._id}`;

      const { data } = await axios.get(url, config);

      setTasks(data.data.task.reverse());
      setLoading(false);
      setSpin(false);
    };

    fetchTasks();
  }, [updateTask]);

  const handelReload = () => {
    setUpdateTask(!updateTask);
  };

  let status = '';

  const handleUpdateTaskStatus = async (taskId) => {
    if (status) {
      setSpin(true);
      setLoading(true);

      const url = `${process.env.REACT_APP_API_URL}/v1/users/${userInfo.user._id}/task`;

      await axios.put(url, { status, taskId }, config);

      setUpdateTask(!updateTask);
      setLoading(false);
      setSpin(false);
    }
  };

  const handleFormChange = (changedValues, allValues) => {
    status = changedValues.status;
  };

  return (
    <div className='task notifications'>
      <div className='task-header'>
        <h3>
          <ScheduleOutlined />
          <span> {t('tasks')}</span>
          <div className='reload-btn'>
            <Button
              size='small'
              icon={<RedoOutlined spin={spin} />}
              onClick={handelReload}
            >
              {t('reload')}
            </Button>
          </div>
        </h3>
      </div>
      <div className='task-body'>
        {!loading ? (
          <>
            {tasks.length > 0 ? (
              <>
                <List
                  pagination={{
                    onChange: (page) => {
                      console.log(page);
                    },
                    pageSize: 5,
                  }}
                  itemLayout='horizontal'
                  dataSource={tasks}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        title={item.title}
                        description={
                          <div className='list-des'>
                            <div className='time'>
                              {dayjs(item.createdAt).fromNow()} - {t('admin')}
                            </div>
                          </div>
                        }
                      />
                      {item.description}
                      <div className='status'>
                        <Form onValuesChange={handleFormChange}>
                          <Form.Item label={t('statusTask')} name='status'>
                            <Select
                              size='small'
                              onChange={() => handleUpdateTaskStatus(item._id)}
                              defaultValue={item.status}
                              style={{ width: 150 }}
                            >
                              <Option value='taskAccepted'>
                                {t('taskAccepted')}
                              </Option>
                              <Option value='ongoing'>{t('ongoing')}</Option>
                              <Option value='pendingApproval'>
                                {t('pendingApproval')}
                              </Option>
                              <Option value='completed'>
                                {t('completed')}
                              </Option>
                            </Select>
                          </Form.Item>
                        </Form>
                      </div>
                    </List.Item>
                  )}
                />
              </>
            ) : (
              <div className='task-empty'>
                <img
                  src='/task-empty.svg'
                  alt='Empty'
                  className='task-empty-img'
                />
                <Title className='mb-0' level={5}>
                  {t('noTasksHeading')}
                </Title>
                <Text>{t('noTasksSubHeading')}</Text>
              </div>
            )}
          </>
        ) : (
          <SkeletonList />
        )}
      </div>
    </div>
  );
};

export default Task;
