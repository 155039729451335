import { Button, Form, Input, Modal, Spin } from 'antd';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../utils';
import addCommas from '../../utils/addCommas';
import removeNonNumeric from '../../utils/removeNonNumeric';

const UpdateFormSalesSalary = ({
  visible,
  onUpdate,
  onCancel,
  updateItem,
  loading,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [defaultlDataSource, setDefaultlDataSource] = useState();

  const handleValueChangeAddCommas = (e) => {
    form.setFieldsValue({
      [e.target.name]: addCommas(removeNonNumeric(e.target.value)),
    });
  };

  const fetchItem = useCallback(async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1/salaries/${updateItem._id}`,
      config
    );

    setDefaultlDataSource(data.data);
  }, [updateItem]);

  useEffect(() => {
    if (updateItem) {
      fetchItem();
    }
    return () => {
      setDefaultlDataSource(null);
      form.resetFields();
    };
  }, [updateItem, fetchItem, form]);

  return (
    <Modal
      className='class-setting'
      open={visible}
      title='Sửa thông tin lương nhân sự'
      onCancel={onCancel}
      footer={[
        <Button onClick={onCancel}>{t('cancel')}</Button>,
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                onUpdate(values);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          {t('ok')}
        </Button>,
      ]}
    >
      {!defaultlDataSource ? (
        <p>
          <Spin /> Đang tải...
        </p>
      ) : (
        <>
          <Form
            form={form}
            initialValues={{
              month: defaultlDataSource.month,
              year: defaultlDataSource.year,
              permanent_salary: defaultlDataSource.permanent_salary,
              student_count: defaultlDataSource.student_count,
              business_salary: defaultlDataSource.business_salary,
              total_sales: defaultlDataSource.total_sales,
              other_salary: defaultlDataSource.other_salary,
              reward: defaultlDataSource.reward,
              punishment: defaultlDataSource.punishment,
              advance_salary: defaultlDataSource.advance_salary,
              note: defaultlDataSource.note,
            }}
            layout='vertical'
            name='form_in_modal'
          >
            <div className='inline-input'>
              <Form.Item name='month' label={t('month')}>
                <Input />
              </Form.Item>
              <Form.Item name='year' label={t('year')}>
                <Input />
              </Form.Item>
            </div>
            <div className='inline-input'>
              <Form.Item name='permanent_salary' label='Lương cứng'>
                <Input
                  name='permanent_salary'
                  onChange={handleValueChangeAddCommas}
                />
              </Form.Item>
              <Form.Item name='student_count' label='Số HV tư vấn'>
                <Input />
              </Form.Item>
            </div>
            <div className='inline-input'>
              <Form.Item name='business_salary' label='Lương kinh doanh'>
                <Input
                  name='business_salary'
                  onChange={handleValueChangeAddCommas}
                />
              </Form.Item>
              <Form.Item name='total_sales' label='Tổng doanh số'>
                <Input
                  name='total_sales'
                  onChange={handleValueChangeAddCommas}
                />
              </Form.Item>
            </div>

            <div className='inline-input'>
              <Form.Item name='other_salary' label='Lương khác'>
                <Input
                  name='other_salary'
                  onChange={handleValueChangeAddCommas}
                />
              </Form.Item>
              <Form.Item name='reward' label='Thưởng'>
                <Input name='reward' onChange={handleValueChangeAddCommas} />
              </Form.Item>
            </div>
            <div className='inline-input'>
              <Form.Item name='punishment' label='Phạt'>
                <Input
                  name='punishment'
                  onChange={handleValueChangeAddCommas}
                />
              </Form.Item>
              <Form.Item name='advance_salary' label='Tạm ứng'>
                <Input
                  name='advance_salary'
                  onChange={handleValueChangeAddCommas}
                />
              </Form.Item>
            </div>
            <Form.Item name='note' label='Note'>
              <Input />
            </Form.Item>
          </Form>
        </>
      )}
    </Modal>
  );
};

export default React.memo(UpdateFormSalesSalary);
