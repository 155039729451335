const defaultExportOptions = {
  // 1: current page
  // 2: all pages
  page: '1',
  format: 'xlsx',
};

export const createExportSlice = (set) => ({
  exportOptions: defaultExportOptions,
  updateExportOptions: (opt) =>
    set((state) => ({ exportOptions: { ...state.exportOptions, ...opt } })),
});
