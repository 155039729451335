import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { createExportSlice } from './exportSlice';
import { createFilterSlice } from './filterSlice';
import { createLanguageSlice } from './languageSlice';
import { createRankingSlice } from './rankingSlice';

export const useBoundStore = create(
  devtools((...a) => ({
    ...createRankingSlice(...a),
    ...createLanguageSlice(...a),
    ...createFilterSlice(...a),
    ...createExportSlice(...a),
  }))
);
