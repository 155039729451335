const initialFilters = {
  registerTable: {
    page: 1,
    limit: 50,
    source: '',
  },
  inputTable: {
    page: 1,
    limit: 50,
    source: '',
  },
};

export const createFilterSlice = (set) => ({
  filters: initialFilters,
  updateRegisterTableFilters: (changedFilters) =>
    set((state) => ({
      filters: {
        ...state.filters,
        registerTable: { ...state.filters.registerTable, ...changedFilters },
      },
    })),
  updateInputTableFilters: (changedFilters) =>
    set((state) => ({
      filters: {
        ...state.filters,
        inputTable: { ...state.filters.inputTable, ...changedFilters },
      },
    })),
  clearInputTableFilter: (key) =>
    set((state) => {
      let inputTableFilters = { ...state.filters.inputTable };

      if (key === 'source') {
        inputTableFilters[key] = '';
      } else {
        delete inputTableFilters[key];
      }

      return {
        filters: { ...state.filters, inputTable: inputTableFilters },
      };
    }),
  clearRegisterTableFilter: (key) =>
    set((state) => {
      let registerTableFilters = { ...state.filters.registerTable };

      if (key === 'source') {
        registerTableFilters[key] = '';
      } else {
        delete registerTableFilters[key];
      }

      return {
        filters: { ...state.filters, registerTable: registerTableFilters },
      };
    }),
  clearAllInputTableFilters: () =>
    set((state) => ({
      filters: { ...state.filters, inputTable: initialFilters.inputTable },
    })),
  clearAllRegisterTableFilters: () =>
    set((state) => ({
      filters: {
        ...state.filters,
        registerTable: initialFilters.registerTable,
      },
    })),
});
