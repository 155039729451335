import {
  CaretDownOutlined,
  LogoutOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import { Button, Drawer, Dropdown, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import '../../theme.dark.less';
import '../../theme.light.less';
import { userInfo } from '../../utils';
import './Header.css';

const HeaderV2 = ({ history }) => {
  const [username, setUsername] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (userInfo) {
      setUsername(userInfo.user.fullname);
    } else {
      setUsername(null);
    }
  }, [history, userInfo]);

  const handleLogout = () => {
    localStorage.removeItem('userInfo');
    setUsername(null);
    history.push('/login');
    window.location.reload();
  };

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const menu = (
    <Menu>
      <Menu.Item danger onClick={handleLogout}>
        <LogoutOutlined /> Đăng xuất
      </Menu.Item>
    </Menu>
  );

  const mobileMenu = (
    <Menu
      onClick={() => setVisible(false)}
      style={{ float: 'right', marginRight: '15px' }}
    >
      <Menu.Item danger onClick={handleLogout}>
        <LogoutOutlined /> Đăng xuất
      </Menu.Item>
    </Menu>
  );

  return (
    <div className='header header-v2'>
      <div className='logo'>
        {userInfo && (
          <>
            <img src='/favicon.png' height='30' width='' alt='Logo' />
          </>
        )}
        {!userInfo && (
          <img src='/favicon.png' height='30' width='' alt='Logo' />
        )}
        <div className='text'>CRM</div>
      </div>

      <div className='user-box'>
        {username ? (
          <>
            <div className='user-menu'>
              <Dropdown overlay={menu} placement='bottomRight'>
                <p className='hello'>
                  <span className='user_name'>{username}</span>{' '}
                  <CaretDownOutlined />
                </p>
              </Dropdown>
            </div>
            <div className='user-mobile-menu'>
              <Button type='text' onClick={showDrawer}>
                <p className='hello'>
                  <MenuOutlined />
                </p>
              </Button>
              <Drawer
                width={250}
                title='Menu'
                placement='right'
                onClose={onClose}
                open={visible}
              >
                {mobileMenu}
              </Drawer>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default HeaderV2;
