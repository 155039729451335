import { Button, Form, Modal, Spin } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../utils';
import '../Statistics/index.css';
import EditableTable from './EditableTable';

const UpdateForm = ({ visible, onUpdate, onCancel, loading }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [defaultlDataSource, setDefaultlDataSource] = useState([]);

  const fetchItem = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1/revenues/63ed27fab5e8b5a0eae610df`,
      config
    );
    setDefaultlDataSource(data.data);
  };

  useEffect(() => {
    fetchItem();
    return () => {
      setDefaultlDataSource(null);
      form.resetFields();
    };
  }, [form]);

  const getDataSource = (data) => {
    setDataSource(data);
  };

  return (
    <Modal
      className='class-setting'
      open={visible}
      title='Sửa KPI'
      onCancel={onCancel}
      footer={[
        <Button onClick={onCancel}>{t('cancel')}</Button>,
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                values.kpi = dataSource;
                onUpdate(values);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          {t('ok')}
        </Button>,
      ]}
    >
      {!defaultlDataSource ? (
        <p>
          <Spin /> Đang tải...
        </p>
      ) : (
        <>
          <Form form={form} layout='vertical' name='form_in_modal'>
            <div
              style={{
                marginTop: '10px',
              }}
            >
              <EditableTable
                getDataSource={getDataSource}
                defaultlDataSource={defaultlDataSource.kpi}
              />
            </div>
          </Form>
        </>
      )}
    </Modal>
  );
};

export default React.memo(UpdateForm);
