import {
  CalendarOutlined,
  ControlOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
  QuestionCircleOutlined,
  SendOutlined,
  ThunderboltFilled,
  UnlockOutlined,
  UserOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Avatar,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
  Typography,
  message,
} from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import { toPng } from 'html-to-image';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';
import {
  CLASS_VALEDICTORIAN_FOLDER_PATH,
  VALEDICTORIAN_OPTIONS,
} from '../../constants';
import { useBoundStore } from '../../store/useBoundStore';
import {
  config,
  getRecordWarning,
  isSafari,
  userInfo,
  validateEmail,
} from '../../utils';
import emptyCell from '../../utils/emptyCell';
import getCert from '../../utils/getCert';
import getDataMailCert from '../../utils/getDataMailCert';
import studyStatus from '../../utils/studyStatus';
import Loader from '../Common/Loader/Loader';
import '../Common/Table.css';
import ExportExcel from '../ExportExcel/ExportExcel';
import UpdateForm from './UpdateForm';
const queryString = require('query-string');
const { Text } = Typography;

const { Option } = Select;
const CustomRow = ({ tooltip, ...restProps }) => {
  return (
    <Tooltip title={tooltip}>
      <tr {...restProps} />
    </Tooltip>
  );
};

const ClassTable = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [records, setRecords] = useState([]);
  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const [updateTable, setUpdateTable] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updateItem, setUpdateItem] = useState(null);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [dataSheets, setDataSheets] = useState([]);
  const [subClass, setSubClass] = useState(null);
  const [primaryClass, setPrimaryClass] = useState(null);
  const [dataSource, setDataScource] = useState([]);
  const [cosultants, setCosultants] = useState(null);
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [studyStatusFilter, setStudyStatusFilter] = useState('');
  const [name, setName] = useState('');
  const [cosultant, setCosultant] = useState('');
  const [phone, setPhone] = useState('');
  const [loadingMailCert, setLoadingMailCert] = useState(false);
  const [isSentCert, setIsSentCert] = useState(0);
  const [visible, setVisible] = useState(false);
  const [loadingSendRequest, setLoadingSendRequest] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const [assistants, setAssistants] = useState([]);
  const [isValedictorian, setIsValedictorian] = useState(false);
  const [valedictorianType, setValedictorianType] = useState('1');

  const certRef = useRef();
  const thanksLetterRef = useRef();

  const ranking = useBoundStore((state) => state.ranking);

  const { width } = useWindowSize();
  const { sub_class_id, class_id } = queryString.parse(window.location.search);

  console.log('primaryClass', primaryClass);

  useEffect(() => {
    const fetchTeachersAndAssistants = async () => {
      const url = `${process.env.REACT_APP_API_URL}/v1/users?select=role,fullname`;

      const { data } = await axios.get(url, config);

      const filteredTeachers = data.data.filter((user) =>
        user.role.includes('teacher')
      );
      const filteredAssistants = data.data.filter((user) =>
        user.role.includes('assistant')
      );

      setTeachers(filteredTeachers);
      setAssistants(filteredAssistants);
    };

    fetchTeachersAndAssistants();
  }, []);

  // fetch cosultants
  useEffect(() => {
    const fetchCosultants = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/cosultants`,
        config
      );

      setCosultants(data.data);
    };

    fetchCosultants();
  }, []);

  // fetch records
  useEffect(() => {
    const fetchData = async () => {
      setLoadingTable(true);

      const longQuery = `${month && `&month=${month}`}${
        year && `&year=${year}`
      }${name && `&name=${name}`}${cosultant && `&cosultant=${cosultant}`}${
        phone && `&phone=${phone}`
      }${studyStatusFilter && `&study_status=${studyStatusFilter}`}`;

      const url = `${process.env.REACT_APP_API_URL}/v1/records?sub_class=${sub_class_id}${longQuery}`;

      const { data } = await axios.get(url, config);

      data.data.forEach((record, index) => {
        record.key = record._id;
        record.no = index + 1;
      });

      // fetch subclass associate with each record
      const { data: classData } = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/classes/${class_id}?select=sub_class,class,alias`,
        config
      );

      setPrimaryClass({
        name: classData.data?.class,
        alias: classData.data?.alias,
      });

      const subClassObj = classData.data?.sub_class.find((subClass) => {
        return subClass._id === sub_class_id;
      });

      if (
        subClassObj?.access_end_class.includes(userInfo?.user?.id) ||
        userInfo?.user?.role.includes('admin') ||
        userInfo.user.role.includes('cskh')
      ) {
        if (userInfo.user.role.includes('cskh')) {
          setRecords(
            data.data.filter(
              (record) =>
                record.study_status !== 'Đã kết thúc' ||
                (record.study_status === 'Đã kết thúc' &&
                  new Date(subClassObj.class_end) - new Date() <= 0 &&
                  Math.ceil(
                    Math.abs(new Date(subClassObj.class_end) - new Date()) /
                      (1000 * 60 * 60 * 24)
                  ) <= 45)
            )
          );
        } else setRecords(data.data);
      } else {
        setRecords(
          data.data.filter((record) => record.study_status !== 'Đã kết thúc')
        );
      }

      setSubClass(subClassObj);
      setLoadingTable(false);
    };

    if (sub_class_id && class_id) {
      fetchData();
    }

    if (records.length) {
      setMonth('');
      setYear('');
      setName('');
      setCosultant('');
      setPhone('');
      setStudyStatusFilter('');
    }
  }, [updateTable, sub_class_id, class_id]);

  useEffect(() => {
    if (subClass && records?.length) {
      records.forEach((record) => {
        record.class_teacher = subClass.class_teacher;
        record.class_teaching_assistant = subClass.class_teaching_assistant;
        record.class_opening = subClass.class_opening;
        record.class_schedule = subClass.class_schedule;
        record.class_hour = subClass.class_hour;
        record.class_end = subClass.class_end;
      });
    }

    setDataScource(records);
  }, [records, subClass]);

  // data sheets
  useEffect(() => {
    if (dataSource.length) {
      let dataSets = [];
      let dataSets2 = [];
      let a = 0,
        b = 0,
        c = 0;
      let totalA = 0,
        totalB = 0,
        totalC;

      dataSource.forEach((record) => {
        let dataSet = {};
        dataSet.no = record.no;
        dataSet.phone = record.phone;
        dataSet.name = record.name;
        dataSet.year_of_birthday = record.year_of_birthday;
        dataSet.email = record.email;
        dataSet.address = record.address;
        dataSet.tuition_status = record.tuition_status;
        dataSet.study_status = record.study_status;
        dataSet.cosultant = record.cosultant;
        dataSet.class_teacher = record.class_teacher?.includes('fullname')
          ? JSON.parse(record.class_teacher)?.fullname
          : teachers?.find((teacher) => teacher._id == record.class_teacher)
              ?.fullname ||
            record.class_teacher ||
            '';
        dataSet.teacher_score = record.teacher_score;
        dataSet.tvv_score = record.tvv_score;
        dataSet.class_teaching_assistant =
          record.class_teaching_assistant?.includes('fullname')
            ? JSON.parse(record.class_teaching_assistant)?.fullname
            : assistants?.find(
                (assistant) => assistant._id == record.class_teaching_assistant
              )?.fullname ||
              record.class_teaching_assistant ||
              '';
        dataSet.teaching_assistant_score = record.teaching_assistant_score;
        dataSet.class_opening = record.class_opening
          ? new Date(record.class_opening).toLocaleDateString()
          : '';
        dataSet.class_schedule = record.class_schedule;
        dataSet.class_hour = record.class_hour;
        dataSet.class_end = record.class_end
          ? new Date(record.class_end).toLocaleDateString()
          : '';
        dataSet.note_b4 = record.note_b4;
        dataSet.note_tk = record.note_tk;
        dataSet.note_gv = record.note_gv;

        record.teacher_score && ++a && (totalA += record.teacher_score);
        record.tvv_score && ++c && (totalC += record.tvv_score);
        record.teaching_assistant_score &&
          ++b &&
          (totalB += record.teaching_assistant_score);

        dataSets.push(dataSet);
      });

      dataSets2 = [
        {
          total_teacher_score: Math.round((totalA / a) * 100) / 100,
          total_teaching_assistant_score: Math.round((totalB / b) * 100) / 100,
          total_tvv_score: Math.round((totalC / c) * 100) / 100,
        },
      ];

      const columns = [
        {
          label: t('no'),
          value: 'no',
        },
        { label: t('recordPhone'), value: 'phone' },
        { label: t('recordName'), value: 'name' },
        { label: t('recordDateOfBirthday'), value: 'year_of_birthday' },
        { label: t('recordEmail'), value: 'email' },
        { label: t('recordAddress'), value: 'address' },
        { label: t('tuitionStatus'), value: 'tuition_status' },
        { label: t('studyStatus'), value: 'study_status' },
        { label: t('recordConsultant'), value: 'cosultant' },
        { label: t('teacher'), value: 'class_teacher' },
        { label: t('teacherScore'), value: 'teacher_score' },
        { label: t('assistant'), value: 'class_teaching_assistant' },
        { label: t('assistantScore'), value: 'teaching_assistant_score' },
        { label: t('consultantScore'), value: 'tvv_score' },
        { label: t('classOpening'), value: 'class_opening' },
        { label: t('classSchedule'), value: 'class_schedule' },
        { label: t('classTime'), value: 'class_hour' },
        { label: t('classEnding'), value: 'class_end' },
        { label: t('b4Note'), value: 'note_b4' },
        { label: t('tkNote'), value: 'note_tk' },
        { label: t('teacherNote'), value: 'note_gv' },
      ];

      const columns2 = [
        { label: 'TB Giáo viên', value: 'total_teacher_score' },
        { label: 'TB Trợ giảng', value: 'total_teaching_assistant_score' },
        { label: 'TB Tư vấn viên', value: 'total_tvv_score' },
      ];

      const sheet1 = {
        sheetName: `${t('classList')} ${subClass?.class_name}`,
        sheetDataSets: dataSets,
        sheetColumns: columns,
      };

      const sheet2 = {
        sheetName: `Điểm TB`,
        sheetDataSets: dataSets2,
        sheetColumns: columns2,
      };

      setDataSheets([...dataSheets, sheet1, sheet2]);
    }
  }, [dataSource]);

  const renderWarnings = (record) => {
    const warns = getRecordWarning(record);
    return warns.map((warn) => {
      if (warn.type === 2) {
        return (
          <>
            <Popover
              content={
                <>
                  {warn.data.map((warnDetail) => (
                    <p>{warnDetail}</p>
                  ))}
                </>
              }
              title='Chi tiết cảnh báo'
            >
              <Tag color={warn.color} style={{ marginBottom: 10 }}>
                {warn.text} <QuestionCircleOutlined />{' '}
              </Tag>
            </Popover>
            <br />
          </>
        );
      } else if (warn.type === 3) {
        return (
          <>
            <Popover
              content={
                <>
                  {warn.data.map((warnObj) => (
                    <p>
                      {warnObj.title}: <u>{warnObj.currentValue}</u>
                    </p>
                  ))}
                </>
              }
              title='Chi tiết cảnh báo'
            >
              <Tag color={warn.color} style={{ marginBottom: 10 }}>
                {warn.text} <QuestionCircleOutlined />{' '}
              </Tag>
            </Popover>
            <br />
          </>
        );
      } else {
        return (
          <>
            <Tag color={warn.color} style={{ marginBottom: 10 }}>
              {warn.text}
            </Tag>
            <br />
          </>
        );
      }
    });
  };

  const columns = [
    {
      title: t('edit'),
      dataIndex: '',
      width: 60,
      fixed: 'left',
      render: (_, record) => (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          <Tooltip
            placement='rightBottom'
            title={`${t('recordCreatedBy')} ${record.createdBy}. ${t(
              'recordLastModified'
            )}: ${new Date(record.updatedAt).toLocaleString()} ${t('by')}: ${
              record.updatedBy || record.createdBy
            }`}
          >
            <Button
              size='small'
              icon={<EditOutlined />}
              disabled={
                !(
                  record.user._id === userInfo.user._id ||
                  userInfo.user.role.includes('admin') ||
                  userInfo.user.role.includes('only_set_class') ||
                  userInfo.user.role.includes('cskh') ||
                  userInfo.user.username === 'TVTS4' ||
                  userInfo.user.username === 'TVTS22' ||
                  userInfo.user.username === 'THALICVOICE'
                )
              }
              onClick={() => {
                setVisibleUpdate(true);
                setUpdateItem(record);
              }}
            ></Button>
          </Tooltip>
        </div>
      ),
    },
    {
      title: t('no'),
      fixed: 'left',
      dataIndex: 'no',
      width: 50,
    },
    {
      title: t('cert'),
      dataIndex: '',
      width: 50,
      fixed: width >= 580 ? 'left' : '',
      render: (_, record) => (
        <>
          {record.isSentCert ? (
            <Tooltip title={t('certSent')}>
              <MailOutlined style={{ color: '#2ecc71' }} />
            </Tooltip>
          ) : (
            <Tooltip title={t('certNotSent')}>
              <WarningOutlined style={{ color: 'red' }} />
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: t('recordPhone'),
      fixed: width >= 580 ? 'left' : '',
      dataIndex: 'phone',
      render: (text, record) => (
        <span>
          {record.user._id === userInfo?.user?._id ||
          userInfo.user.role.includes('admin') ||
          userInfo.user.role.includes('dev') ||
          userInfo.user.role.includes('only_set_class') ||
          userInfo.user.role.includes('cskh') ? (
            <span>{text}</span>
          ) : (
            <span>***</span>
          )}
        </span>
      ),
    },
    {
      title: t('recordPotentail'),
      fixed: width >= 768 ? 'left' : '',
      dataIndex: 'potential',
      width: 120,
      render: (text, record) => (
        <span>
          {record.user._id === userInfo?.user?._id ||
          userInfo.user.role.includes('admin') ||
          userInfo.user.role.includes('dev') ? (
            <span>{text || 'Chưa xác định'}</span>
          ) : (
            <span>***</span>
          )}
        </span>
      ),
    },
    {
      title: t('recordName'),
      fixed: width >= 580 ? 'left' : '',
      dataIndex: 'name',
      render: (_, record) => (
        <Button
          type='text'
          style={{ width: '100%' }}
          onClick={() => {
            setVisible(true);
            setUpdateItem(record);
            setIsSentCert(record.isSentCert);
          }}
        >
          <span style={{ color: '#2ecc71', fontWeight: '500' }}>
            {record.name}
          </span>
        </Button>
      ),
    },
    // {
    //   title: t('recordWarning'),
    //   width: 120,
    //   render: (_, record) => renderWarnings(record),
    // },
    {
      title: t('recordDateOfBirthday'),
      dataIndex: 'year_of_birthday',
      width: 75,
    },
    {
      title: t('recordEmail'),
      dataIndex: 'email',
      width: 100,
      render: (text, record) => (
        <span>
          {record.user._id === userInfo?.user?._id ||
          userInfo.user.role.includes('admin') ||
          userInfo.user.role.includes('dev') ? (
            <span>{text}</span>
          ) : (
            <span>***</span>
          )}
        </span>
      ),
    },
    {
      title: t('recordAddress'),
      dataIndex: 'address',
    },
    {
      title: t('recordOccupation'),
      width: 100,
      dataIndex: 'occupation',
      render: (_, record) => emptyCell(record.occupation),
    },
    {
      title: t('recordPurpose'),
      width: 100,
      dataIndex: 'purpose',
      render: (_, record) => emptyCell(record.purpose),
    },
    { title: t('tuitionStatus'), dataIndex: 'tuition_status', width: 120 },
    { title: t('studyStatus'), dataIndex: 'study_status', width: 120 },
    { title: t('recordConsultant'), dataIndex: 'cosultant' },
    { title: t('consultantScore'), dataIndex: 'tvv_score', width: 100 },
    {
      title: t('teacher'),
      width: 100,
      dataIndex: 'class_teacher',
      render: (_, record) => (
        <span>
          {record.class_teacher?.includes('fullname')
            ? JSON.parse(record.class_teacher)?.fullname
            : teachers?.find((teacher) => teacher._id == record.class_teacher)
                ?.fullname ||
              record.class_teacher ||
              ''}
        </span>
      ),
    },
    { title: t('teacherScore'), dataIndex: 'teacher_score', width: 100 },
    {
      title: t('assistant'),
      dataIndex: 'class_teaching_assistant',
      width: 100,
      render: (_, record) => (
        <span>
          {record.class_teaching_assistant?.includes('fullname')
            ? JSON.parse(record.class_teaching_assistant)?.fullname
            : assistants?.find(
                (assistant) => assistant._id == record.class_teaching_assistant
              )?.fullname ||
              record.class_teaching_assistant ||
              ''}
        </span>
      ),
    },
    {
      title: t('assistantScore'),
      dataIndex: 'teaching_assistant_score',
      width: 100,
    },
    {
      title: t('classOpening'),
      dataIndex: 'class_opening',
      width: 110,
      render: (_, record) =>
        record.class_opening
          ? dayjs(record.class_opening).format('DD/MM/YYYY')
          : '',
    },
    { title: t('classSchedule'), dataIndex: 'class_schedule', width: 100 },
    { title: t('classTime'), dataIndex: 'class_hour', width: 75 },
    {
      title: t('classEnding'),
      dataIndex: 'class_end',
      width: 100,
      render: (_, record) =>
        record.class_end ? dayjs(record.class_end).format('DD/MM/YYYY') : '',
    },
    // {
    //   title: t('levelUpCustomer'),
    //   dataIndex: 'level_up',
    //   render: (_, record) => <Checkbox />,
    //   width: 100,
    // },
    { title: t('teacherNote'), dataIndex: 'note_gv', width: 100 },
    { title: t('b4Note'), dataIndex: 'note_b4', width: 100 },
    { title: t('tkNote'), dataIndex: 'note_tk', width: 100 },
  ];

  const onUpdate = (values) => {
    const updateRecord = async () => {
      setLoadingUpdate(true);

      await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/records/${updateItem._id}`,
        values,
        config
      );

      setLoadingUpdate(false);

      setUpdateTable(!updateTable);
      setVisibleUpdate(false);

      message.success('Cập nhật thành công');
    };

    updateRecord();
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    form.resetFields();
    setUpdateTable(!updateTable);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const sendCert = () =>
    Modal.confirm({
      title: t('Xác nhận gửi email'),
      icon: <ExclamationCircleOutlined />,
      content: t(
        'Bạn đã kiểm tra kỹ toàn bộ thông tin chính xác và chịu trách nhiệm sau khi gửi. Xác nhận gửi email?'
      ),
      async onOk() {
        setLoadingMailCert(true);

        message.info('Đang đính kèm chứng chỉ...');
        const certImg = await toPng(certRef.current, {
          width: 1100,
          height: 777,
          style: {
            transform: 'scale(1)',
          },
          cacheBust: true,
        });

        message.info('Đang đính kèm thư cảm ơn...');
        message.info('Đang đính kèm học bổng...');
        // const thanksLetterImg = await toPng(thanksLetterRef.current, {
        //   width: 1100,
        //   height: 777,
        //   style: {
        //     transform: 'scale(1)',
        //   },
        //   cacheBust: true,
        // });

        const files = [
          {
            fileName: `Chứng nhận THALIC VOICE - ${updateItem?.name}.png`,
            fileContent: certImg,
            fileType: 'dataUrl',
          },
          // {
          //   fileName: `Thư cảm ơn - ${updateItem?.name}.png`,
          //   fileContent: thanksLetterImg,
          //   fileType: 'dataUrl',
          // },
          {
            fileName: `Thư cảm ơn - ${updateItem?.name}.png`,
            fileContent: 'thu-cam-on.png',
            fileType: 'storage',
          },
          {
            fileName: 'Học bổng.png',
            fileContent: 'hb.png',
            fileType: 'storage',
          },
        ];

        console.log('isValedictorian', isValedictorian);
        console.log('valedictorianType', valedictorianType);
        console.log(
          'file path',
          `${
            CLASS_VALEDICTORIAN_FOLDER_PATH[primaryClass?.name]
          }/${valedictorianType}.pdf`
        );

        if (isValedictorian) {
          files.push({
            fileName: `Chứng nhận ${
              VALEDICTORIAN_OPTIONS.find(
                (item) => item.value === valedictorianType
              )?.title
            } THALIC VOICE - ${updateItem?.name}.pdf`,
            fileContent: `${
              CLASS_VALEDICTORIAN_FOLDER_PATH[primaryClass?.name]
            }/${valedictorianType}.pdf`,
            fileType: 'storage',
          });
        }

        message.info('Đang gửi email...');
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/auth/sendmail`,
          {
            mailUser: updateItem?.email,
            // mailUser: 'hungngv231@gmail.com', // reveiver
            mailSubject: `[THALIC VOICE] CHỨNG NHẬN KHOÁ HỌC - THƯ CẢM ƠN QUÝ HỌC VIÊN`,
            mailContent: getDataMailCert(
              updateItem?.name,
              `${primaryClass?.alias || ''} ${subClass?.class_name}` || '',
              isValedictorian,
              valedictorianType
            ),
            files,
          }
        );

        if (data.success) {
          message.success('Gửi mail thành công');

          // update status
          const { data } = await axios.put(
            `${process.env.REACT_APP_API_URL}/v1/records/${updateItem?._id}`,
            {
              isSentCert: 1,
            },
            config
          );

          if (data.success) {
            setIsSentCert(1);
            setUpdateTable(true);
          } else {
            message.error('Lưu trạng thái gửi thất bại');
          }
        } else {
          message.error(`Gửi mail thất bại: ${data.msg}`);
        }

        setLoadingMailCert(false);
      },
      onCancel() {
        console.log('Cancel');
      },
    });

  const handleRequestOpenEndClass = async () => {
    const result = window.confirm('Gửi yêu cầu cho Admin?');
    if (result) {
      try {
        setLoadingSendRequest(true);

        await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/requests`,
          {
            type: 'hide_end_class',
            code: subClass?.class_name,
            content: `Mở khoá bản ghi 'Đã kết thúc' lớp ${subClass?.class_name}`,
            link: `/records?sub_class=${sub_class_id}&class=${class_id}`,
            status: 'pending',
          },
          config
        );
        message.success('Gửi yêu cầu thành công');
      } catch (error) {
        message.error('Gửi yêu cầu thất bại');
        console.log(error);
      } finally {
        setLoadingSendRequest(false);
      }
    } else {
      message.success('Đã huỷ yêu cầu');
    }
  };

  return (
    <div className='input-table'>
      {!loadingTable && subClass ? (
        <>
          <Helmet>
            <title>
              {t('classList')} {subClass.class_name} | THALIC CRM
            </title>
            <meta
              property='og:title'
              content={`${t('classList')} ${subClass.class_name} | THALIC CRM`}
            />
          </Helmet>
          <div style={{ marginBottom: 16 }} className='table-header'>
            <div className='left'>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h3>
                  {t('classList')} {subClass?.class_name}{' '}
                </h3>
              </div>
              <div className='filter-zone'>
                <Button
                  style={{ marginLeft: '20px' }}
                  onClick={showModal}
                  type='primary'
                  icon={<ControlOutlined />}
                >
                  {t('filter')}
                </Button>
                {!userInfo.user.role.includes('cskh') && (
                  <Tooltip title={t('requestUnlockEndRecordBtnTooltip')}>
                    <Button
                      onClick={handleRequestOpenEndClass}
                      type='dashed'
                      shape='circle'
                      icon={<ThunderboltFilled />}
                      style={{ marginLeft: 10, color: '#ffcd0f' }}
                      loading={loadingSendRequest}
                    />
                  </Tooltip>
                )}
                <Modal
                  title={t('filterRecordModal')}
                  open={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  okText={t('ok')}
                  cancelText={t('cancel')}
                >
                  <Form form={form} layout='vertical' name='form_in_modal'>
                    <div className='inline-input'>
                      <Form.Item name='month' label={t('month')}>
                        <Input
                          value={month}
                          onChange={(e) => setMonth(e.target.value)}
                          prefix={<CalendarOutlined />}
                        />
                      </Form.Item>
                      <Form.Item name='year' label={t('year')}>
                        <Input
                          value={year}
                          onChange={(e) => setYear(e.target.value)}
                          prefix={<CalendarOutlined />}
                        />
                      </Form.Item>
                    </div>
                    <div className='inline-input'>
                      <Form.Item name='name' label={t('recordName')}>
                        <Input
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          prefix={<UserOutlined />}
                        />
                      </Form.Item>
                      <Form.Item name='cosultant' label={t('consultant')}>
                        <Select
                          showSearch
                          allowClear
                          placeholder={t('typeToSearch')}
                          filterOption={(inputValue, option) =>
                            option.value
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                          onChange={(value) => setCosultant(value)}
                        >
                          <Option value=''>Không chọn</Option>
                          {(!!ranking ? ranking : cosultants)?.map((item) => (
                            <Option key={item._id} value={item.fullname}>
                              {item.fullname}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className='inline-input-2'>
                      <Form.Item name='phone' label={t('recordPhone')}>
                        <Input
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          prefix={<PhoneOutlined />}
                        />
                      </Form.Item>
                      <Form.Item name='study_status' label={t('studyStatus')}>
                        <Select
                          showSearch
                          allowClear
                          placeholder={t('typeToSearch')}
                          filterOption={(inputValue, option) =>
                            option.value
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                          onChange={(value) => setStudyStatusFilter(value)}
                        >
                          <Option value=''>Không chọn</Option>
                          {!userInfo?.user.role.includes('admin')
                            ? studyStatus
                                ?.filter((status) => status !== 'Đã kết thúc')
                                .map((item) => (
                                  <Option key={item} value={item}>
                                    {item}
                                  </Option>
                                ))
                            : studyStatus?.map((item) => (
                                <Option key={item} value={item}>
                                  {item}
                                </Option>
                              ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </Form>
                </Modal>
              </div>
            </div>
            <div className='right'>
              {userInfo?.user.role.includes('admin') && (
                <ExportExcel
                  filename={`${t('classList')} ${subClass?.class_name} (${
                    primaryClass?.name
                  })`}
                  dataSheets={dataSheets}
                />
              )}
            </div>
          </div>
          <small
            style={{
              whiteSpace: 'pre-wrap',
            }}
          >
            {subClass?.access_end_class.includes(userInfo?.user?.id) ? (
              <Text>
                <UnlockOutlined
                  style={{
                    color: '#16c784',
                    marginBottom: 8,
                    marginRight: 6,
                  }}
                />
                <b>{t('classTableHint2')}</b>
              </Text>
            ) : (
              <>
                {!userInfo.user.role.includes('cskh') ? (
                  <Text>
                    <LockOutlined
                      style={{
                        color: 'red',
                        marginBottom: 8,
                        marginRight: 6,
                      }}
                    />
                    <b>{t('classTableHint1')}</b>
                  </Text>
                ) : (
                  <Text>
                    <b>{t('classTableHint3')}</b>
                  </Text>
                )}
              </>
            )}
          </small>
          <Modal
            style={{ top: 0 }}
            className='modal-bill'
            title={
              <div className='flex items-center'>
                <Avatar
                  icon={<UserOutlined />}
                  size={width >= 640 ? 'large' : 'default'}
                />
                <div
                  className='flex items-start flex-wrap'
                  style={{
                    marginLeft: 10,
                  }}
                >
                  <div>
                    {updateItem?.name}
                    <>
                      {updateItem?.email && (
                        <div
                          style={{
                            color: '#00000073',
                            fontSize: 14,
                          }}
                        >
                          {updateItem.email}
                        </div>
                      )}
                    </>
                  </div>
                  <div
                    style={{
                      marginLeft: 10,
                    }}
                  >
                    {isSentCert === 1 ? (
                      <Tag style={{ margin: 0 }} color='#16c784'>
                        {t('sent')}
                      </Tag>
                    ) : isSentCert === 0 ? (
                      <Tag style={{ margin: 0 }} color='#ea3943'>
                        {t('notSent')}
                      </Tag>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            }
            centered
            onCancel={() => setVisible(false)}
            onOk={() => setVisible(false)}
            open={visible}
          >
            <div className='bill'>
              <Row gutter={16}>
                <Col
                  xs={24}
                  lg={19}
                  style={{
                    height: 1000,
                    overflow: 'scroll',
                  }}
                >
                  <>
                    <p>
                      <b>{t('mailTo')}:</b> {updateItem?.email}
                    </p>
                    <p>
                      <b>{t('mailSubject')}:</b>{' '}
                      {`[THALIC VOICE] CHỨNG NHẬN KHOÁ HỌC - THƯ CẢM ƠN QUÝ HỌC VIÊN`}
                    </p>
                    <p>
                      <b>{t('mailContent')}:</b>{' '}
                      <div
                        style={{
                          border: '1px solid #f0f0f0',
                          borderRadius: 8,
                          padding: '15px 20px',
                          marginTop: 20,
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: getDataMailCert(
                              updateItem?.name,
                              `${primaryClass?.alias || ''} ${
                                subClass?.class_name
                              }` || '',
                              isValedictorian,
                              valedictorianType
                            ),
                          }}
                        ></div>
                      </div>
                    </p>

                    <p>
                      <b>{t('mailAttachments')}:</b>
                      <>
                        {isValedictorian && (
                          <div>
                            <img
                              src='/pdf.png'
                              alt=''
                              style={{
                                height: 24,
                                marginRight: 5,
                              }}
                            />
                            Chứng nhận thủ khoa
                          </div>
                        )}
                      </>
                    </p>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 32,
                        transform:
                          width >= 640
                            ? 'scale(0.6) translateY(-200px)'
                            : 'scale(0.4) translateY(-600px)',
                        transformOrigin: 'left',
                        position: 'absolute',
                      }}
                    >
                      <div
                        ref={certRef}
                        dangerouslySetInnerHTML={{
                          __html: getCert(
                            updateItem?.name || '',
                            primaryClass?.alias || '',
                            dayjs(updateItem?.class_opening).format(
                              'DD/MM/YYYY'
                            ) || '',
                            dayjs(updateItem?.class_end).format('DD/MM/YYYY') ||
                              ''
                          ),
                        }}
                      ></div>
                      {/* <div
                        ref={thanksLetterRef}
                        dangerouslySetInnerHTML={{
                          __html: getThanksLetter(updateItem?.name),
                        }}
                      ></div> */}
                      <img
                        src='/thu-cam-on.png'
                        alt='Học bổng'
                        style={{
                          height: 777,
                        }}
                      />
                      <img
                        src='/hb.png'
                        alt='Học bổng'
                        style={{
                          height: 777,
                        }}
                      />
                    </div>
                  </>
                </Col>
                <Col xs={24} lg={5}>
                  {Object.keys(CLASS_VALEDICTORIAN_FOLDER_PATH).includes(
                    primaryClass?.name
                  ) && (
                    <Checkbox
                      style={{
                        marginTop: 10,
                      }}
                      onChange={(e) => setIsValedictorian(e.target.checked)}
                    >
                      {t('isValedictorian')}
                    </Checkbox>
                  )}
                  <br />
                  {isValedictorian && (
                    <Select
                      style={{
                        width: '100%',
                        marginTop: 10,
                      }}
                      placeholder={t('selectValedictorianType')}
                      onChange={(value) => setValedictorianType(value)}
                      optionLabelProp='label'
                      defaultValue={'1'}
                    >
                      {VALEDICTORIAN_OPTIONS.map(({ value, title }) => (
                        <Option value={value} label={title}>
                          <div className='option-label-item'>{title}</div>
                        </Option>
                      ))}
                    </Select>
                  )}
                  <Button
                    type='primary'
                    icon={<SendOutlined />}
                    loading={loadingMailCert}
                    disabled={
                      !validateEmail(updateItem?.email) ||
                      loadingMailCert ||
                      isSafari
                    }
                    style={{
                      textAlign: 'center',
                      marginTop: 20,
                      width: '100%',
                    }}
                    onClick={sendCert}
                  >
                    {loadingMailCert ? t('sending') : t('sendCert')}
                  </Button>
                  {isSafari ? (
                    <Alert
                      style={{ marginTop: 20 }}
                      type='error'
                      message={t(
                        'Safari hiện không được hỗ trợ gửi email, hãy thử sang trình duyệt khác như Chrome, Edge,...'
                      )}
                    />
                  ) : (
                    <>
                      {!validateEmail(updateItem?.email) ? (
                        <Alert
                          style={{ marginTop: 20 }}
                          type='error'
                          message={t('invalidNotFoundEmail')}
                        />
                      ) : (
                        <Alert
                          style={{ marginTop: 20 }}
                          type='success'
                          message={t('sendMailNote')}
                        />
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </Modal>
          <Table
            showHeader={dataSource?.length > 0}
            components={{
              body: {
                row: CustomRow,
              },
            }}
            rowClassName={(record, index) =>
              record.study_status === 'Bảo lưu'
                ? 'data-bao-luu'
                : record.study_status === 'Bỏ học'
                ? 'data-bo-hoc'
                : ''
            }
            onRow={(record, index) => ({
              tooltip:
                record.study_status === 'Bảo lưu'
                  ? t('reserved')
                  : record.study_status === 'Bỏ học'
                  ? t('dropped')
                  : '',
            })}
            pagination={{ pageSize: 50 }}
            bordered
            columns={columns}
            dataSource={dataSource}
            scroll={{ x: 'max-content' }}
            sticky
            summary={(pageData) => {
              let totalTeacherScore = 0;
              let totalTeachingAssistantScore = 0;
              let totalTVVScore = 0;
              let a = 0,
                b = 0,
                c = 0;

              pageData.forEach(
                ({ teacher_score, teaching_assistant_score, tvv_score }) => {
                  totalTVVScore += tvv_score ? tvv_score : 0;
                  totalTeacherScore += teacher_score ? teacher_score : 0;
                  totalTeachingAssistantScore += teaching_assistant_score
                    ? teaching_assistant_score
                    : 0;
                  teacher_score && a++;
                  teaching_assistant_score && b++;
                  tvv_score && c++;
                }
              );

              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan='10'>
                      {t('avg')}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type='danger'>
                        {Math.round((totalTVVScore / c) * 100) / 100}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type='danger'>
                        {Math.round((totalTeacherScore / a) * 100) / 100}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell></Table.Summary.Cell>
                    <Table.Summary.Cell>
                      <Text type='danger'>
                        {Math.round((totalTeachingAssistantScore / b) * 100) /
                          100}
                      </Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell colSpan='5'></Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
          <UpdateForm
            visible={visibleUpdate}
            onUpdate={onUpdate}
            onCancel={() => {
              setVisibleUpdate(false);
            }}
            updateItem={updateItem}
            loading={loadingUpdate}
          />
        </>
      ) : (
        <Loader fullHeight />
      )}
    </div>
  );
};

export default ClassTable;
