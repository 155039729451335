import { DeleteOutlined } from '@ant-design/icons';
import { Avatar, Typography } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { config } from '../../utils';
import Ripple from '../Common/Ripple';
import './RequestItem.css';

const RequestItem = ({ request, status }) => {
  const [subClasses, setSubClasses] = useState([]);
  const [expectedClass, setExpectedClass] = useState();
  const [msg, setMsg] = useState('');
  const [msgDelete, setMsgDelete] = useState('');

  // fetch subclass associate with each record
  useEffect(() => {
    const fetchSubClass = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/classes?select=sub_class&sub_class.class_name=${request.code}`,
        config
      );

      setExpectedClass(data.data[0]);
      setSubClasses(data.data[0]?.sub_class || []);
    };
    fetchSubClass();
  }, []);

  const updateSubClass = async (value) => {
    try {
      if (expectedClass) {
        const newSubClasses = [...subClasses];
        newSubClasses.forEach((subClass) => {
          if (subClass.class_name === request.code) {
            if (value === 'accepted') {
              subClass.access_end_class = [
                ...new Set([...subClass.access_end_class, request.from.id]),
              ];
            } else if (value === 'rejected') {
              subClass.access_end_class = subClass.access_end_class.filter(
                (id) => id !== request.from.id
              );
            } else return;
          }
        });

        await axios.put(
          `${process.env.REACT_APP_API_URL}/v1/classes/${expectedClass._id}`,
          {
            sub_class: newSubClasses,
          },
          config
        );

        if (value === 'accepted') setMsg('Đã chấp nhận');
        else if (value === 'rejected') setMsg('Đã loại bỏ');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateRequestStatus = async (status) => {
    await axios.put(
      `${process.env.REACT_APP_API_URL}/v1/requests/${request._id}`,
      { status },
      config
    );
  };

  const handleAccept = (e, request) => {
    e.stopPropagation();
    if (request.type === 'hide_end_class') {
      updateRequestStatus('accepted');
      updateSubClass('accepted');
    }
  };

  const handleReject = (e, request) => {
    e.stopPropagation();
    if (request.type === 'hide_end_class') {
      updateRequestStatus('rejected');
      updateSubClass('rejected');
    }
  };

  const handleDelete = async () => {
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/v1/requests/${request._id}`,
      config
    );
    setMsgDelete('Đã xoá');
  };

  return (
    <div className='request-item'>
      <Link to={request.link}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
          <Avatar src={request.from.avatar} style={{ marginRight: 10 }} />
          <div>
            <Typography.Text className='rq-content'>
              Yêu cầu: {request.content}
            </Typography.Text>
            <div style={{ color: '#a3a3a3' }}>
              Từ: {request.from.fullname},{' '}
              {new Date(request.createdAt).toLocaleString()}
            </div>
          </div>
        </div>
      </Link>

      {!msg ? (
        <>
          <div className='btn-group'>
            {status !== 'accepted' && (
              <div
                className='btn-request accept-btn'
                onClick={(e) => handleAccept(e, request)}
              >
                Chấp nhận <Ripple />
              </div>
            )}
            {status !== 'rejected' && (
              <div
                className='btn-request reject-btn'
                onClick={(e) => handleReject(e, request)}
              >
                Loại bỏ <Ripple />{' '}
              </div>
            )}
          </div>
        </>
      ) : (
        <>{msg}</>
      )}

      {msgDelete ? (
        <>{msgDelete}</>
      ) : (
        <DeleteOutlined
          onClick={handleDelete}
          className='delete-icon'
          style={{
            cursor: 'pointer',
            padding: 6,
            borderRadius: '50%',
          }}
        />
      )}
    </div>
  );
};

export default RequestItem;
