import { VALEDICTORIAN_OPTIONS } from '../constants';

const getDataMailCert = (
  name,
  course,
  isValedictorian,
  valedictorianType = '1'
) => {
  return `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>[THALIC VOICE] CHỨNG NHẬN KHOÁ HỌC - THƯ CẢM ƠN QUÝ HỌC VIÊN</title>
      <style>
        .mail-wrapper {
          margin: 0 auto;
          max-width: 600px;
          font-family: Arial, Helvetica, sans-serif;
          font-size: 15px;
          box-sizing: border-box;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          direction: ltr;
        }
        .mail-wrapper p {
          display: block;
          margin-block-start: 1em;
          margin-block-end: 1em;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          unicode-bidi: isolate;
        }
      </style>
    </head>
    <body>
      <div class="mail-wrapper">
        <div class="mail-banner">
          <img
            src="https://res.cloudinary.com/dinntgpwi/image/upload/v1713714775/thalic/ufkt9zx9ckjxqq3nwoqb.png"
            alt="Thalic Voice"
            style="width: 100%"
          />
        </div>
        <div class="mail-content">
          <br />
          <p>
            <i>Kính gửi quý học viên: <b>${name},</b></i>
          </p>
          <p>
            <b>THALIC VOICE</b>
            chúc mừng bạn đã hoàn thành và tốt nghiệp khóa học
            <b>${course}</b> tại Học viện giọng nói và kỹ năng THALIC VOICE.
          </p>
          <p>THALIC gửi bạn:</p>
          <p><b>- CHỨNG NHẬN:</b> HOÀN THÀNH KHÓA HỌC</p>
          <p><b>- THƯ CẢM ƠN</b></p>
          <p><b>- QUÀ TẶNG:</b> HỌC BỔNG CHO BẠN BÈ, NGƯỜI THÂN</p>
          ${
            isValedictorian
              ? `<p><b>- CHỨNG NHẬN:</b> ${VALEDICTORIAN_OPTIONS.find(
                  (item) => item.value === valedictorianType
                )?.title?.toUpperCase()}</p>`
              : ''
          }
          <p>
            <b>- LINK ĐÁNH GIÁ - GÓP Ý - GỬI LỜI CẢM ƠN:</b>&nbsp;
            <a
              href="https://forms.gle/KRDGjfyYF9eu2zVdA"
              target="_blank"
              rel="noreferrer"
              >https://forms.gle/KRDGjfyYF9eu2zVdA</a
            >
          </p>
          <p>
            <b>- LINK THAM GIA GROUP:</b>&nbsp;
            <a
              href="https://www.facebook.com/groups/clbthalicvoice"
              target="_blank"
              rel="noreferrer"
              >https://www.facebook.com/groups/clbthalicvoice</a
            >
          </p>
          <p>
            THALIC và đội ngũ giáo viên, chuyên viên chuyên môn ghi nhận sự cố
            gắng và chăm chỉ của bạn trong suốt khóa học. Đồng thời cũng nhìn thấy
            được sự tiến bộ, thay đổi tích cực từ bạn.
          </p>
          <p>
            Mặc dù khóa học đã kết thúc nhưng mong rằng bạn vẫn chăm chỉ luyện tập
            hằng ngày, áp dụng kiến thức được học vào cuộc sống để có một giọng nói
            đẹp hơn, cuốn hút hơn, trau dồi thêm các kỹ năng - tư duy giao tiếp,
            thuyết trình, nói trước đám đông nhé.
          </p>
          <p>
            Cảm ơn bạn đã tham gia khóa học và trở thành một phần của
            <b>THALIC.</b> Chúng ta đã đi với nhau một thời gian không quá dài
            nhưng đủ để mỗi lần bạn gặp khó khăn hay cần giúp đỡ hãy đừng ngần
            ngại chia sẻ với chúng tôi.
          </p>
          <p>
            Để tham khảo nội dung kiến thức khác của <b>THALIC</b>, vui lòng xem
            thông tin chi tiết tại:
          </p>
          <p>
            - Website:&nbsp;
            <a href="thalic.edu.vn" target="_blank" rel="noreferrer"
              >thalic.edu.vn</a
            >
          </p>
          <p>
            - Social media:&nbsp;
            <a
              href="https://www.tiktok.com/@thalicvoice"
              target="_blank"
              rel="noreferrer"
              >TikTok</a
            >,&nbsp;
            <a
              href="https://www.facebook.com/thalicvoice.hocviengiongnoivakynang"
              target="_blank"
              rel="noreferrer"
              >Facebook</a
            >,&nbsp;
            <a
              href="https://www.youtube.com/@thalicvoice"
              target="_blank"
              rel="noreferrer"
              >Youtube</a
            >,&nbsp;
            <a
              href="https://www.instagram.com/thalicvoice_hocviengiongnoi"
              target="_blank"
              rel="noreferrer"
              >Instagram</a
            >
          </p>
          <p>Chúc bạn sức khỏe, thành công trong cuộc sống và công việc!</p>
          <p>Trân trọng,</p>
          <p><b>Mr. Nguyễn Hà Duy</b></p>
          <p><i>CEO & Co-founder THALIC VOICE</i></p>
        </div>
      </div>
    </body>
  </html>
  `;
};

export default getDataMailCert;
