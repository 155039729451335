import axios from 'axios';
import { config } from '../utils';

export const getAllClassess = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/v1/classes`,
    config
  );
  return data.data;
};

export const getClass = async (id) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/v1/classes/${id}`,
    config
  );
  return data.data;
};
