import { Button, Checkbox, Form, Input, Modal, Select, Spin } from 'antd';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { config, userInfo } from '../../utils';
import userInfoConfig from '../../utils/userInfoConfig';
import EditableTable from './EditableTable';
import { USER_ROLE_MAPPING } from './SettingUser';
const { Option } = Select;

const UpdateForm = ({ visible, onUpdate, onCancel, updateItem, loading }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [defaultlDataSource, setDefaultlDataSource] = useState([]);
  const [count, setCount] = useState(0);
  const [role, setRole] = useState('');
  const [colla, setColla] = useState();
  const [department, setDepartment] = useState('');
  const [position, setPosition] = useState('');
  const [workingType, setWorkingType] = useState('');
  const [statusWorking, setStatusWorking] = useState('');

  const fetchItem = useCallback(async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1/users/${updateItem._id}`,
      config
    );

    setDefaultlDataSource(data.data);
  }, [updateItem]);

  useEffect(() => {
    if (updateItem) {
      fetchItem();
    }
    return () => {
      setDefaultlDataSource(null);
      form.resetFields();
    };
  }, [updateItem, fetchItem, form]);

  const getDataSource = (data) => {
    setDataSource(data);
  };

  return (
    <Modal
      className='class-setting'
      open={visible}
      title='Sửa User'
      onCancel={onCancel}
      footer={[
        <Button onClick={onCancel}>{t('cancel')}</Button>,
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                values.task = dataSource;
                values.role = role;
                values.isCollaborator = colla;
                values.department = department;
                values.position = position;
                values.workingType = workingType;
                values.statusWorking = statusWorking;
                // console.log(values);
                onUpdate(values);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          {t('ok')}
        </Button>,
      ]}
    >
      {!defaultlDataSource ? (
        <p>
          <Spin /> Đang tải...
        </p>
      ) : (
        <>
          <Form form={form} layout='vertical' name='form_in_modal'>
            <h4>Thông tin cơ bản</h4>
            <div
              style={{
                background: '#b1b1b1',
                width: '100%',
                height: '1px',
                marginBottom: '10px',
              }}
            ></div>
            <div className='inline-input'>
              <Form.Item name='fullname' label='Tên hiển thị'>
                <Input defaultValue={defaultlDataSource.fullname} />
              </Form.Item>
              <Form.Item name='birthday' label='Ngày sinh'>
                <Input
                  type='date'
                  defaultValue={
                    defaultlDataSource.birthday &&
                    new Date(defaultlDataSource.birthday)
                      .toISOString()
                      .substring(0, 10)
                  }
                />
              </Form.Item>
            </div>
            <div className='inline-input'>
              <Form.Item name='username' label='Tên tài khoản'>
                <Input defaultValue={defaultlDataSource.username} />
              </Form.Item>
              <Form.Item name='password' label='Mật khẩu'>
                <Input />
              </Form.Item>
            </div>
            {!userInfo?.user?.role.includes('hr') && (
              <div className='inline-input'>
                <Form.Item name='role' label='Loại tài khoản'>
                  <Select
                    mode='multiple'
                    allowClear
                    defaultValue={defaultlDataSource.role}
                    onChange={(value) => setRole(value)}
                  >
                    {Object.entries(USER_ROLE_MAPPING).map(([key, value]) => (
                      <Option value={key}>{value}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name='email' label='Email'>
                  <Input defaultValue={defaultlDataSource.email} />
                </Form.Item>
              </div>
            )}

            <Form.Item name='isCollaborator'>
              <Checkbox
                defaultChecked={defaultlDataSource.isCollaborator}
                onClick={(e) => setColla(e.target.checked)}
              >
                Là CTV
              </Checkbox>
            </Form.Item>

            <h4>Thông tin khác</h4>
            <div
              style={{
                background: '#b1b1b1',
                width: '100%',
                height: '1px',
                marginBottom: '10px',
              }}
            ></div>
            <div className='inline-input'>
              <Form.Item name='department' label='Phòng ban'>
                <Select
                  onChange={(value) => setDepartment(value)}
                  defaultValue={defaultlDataSource.department}
                >
                  {userInfoConfig?.department.map((value) => (
                    <Option value={value}>{value}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name='position' label='Chức danh'>
                <Select
                  onChange={(value) => setPosition(value)}
                  defaultValue={defaultlDataSource.position}
                >
                  {userInfoConfig?.position.map((value) => (
                    <Option value={value}>{value}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className='inline-input'>
              <Form.Item name='workingType' label='Hình thức làm việc'>
                <Select
                  onChange={(value) => setWorkingType(value)}
                  defaultValue={defaultlDataSource.workingType}
                >
                  {userInfoConfig?.workingType.map((value) => (
                    <Option value={value}>{value}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name='statusWorking' label={t('recordStatus')}>
                <Select
                  onChange={(value) => setStatusWorking(value)}
                  defaultValue={defaultlDataSource.statusWorking}
                >
                  {userInfoConfig?.statusWorking.map((value) => (
                    <Option value={value}>{value}</Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className='inline-input'>
              <Form.Item
                name='onboardDate'
                label='Ngày bắt đầu làm việc (Mặc định là thời gian tạo tài khoản)'
              >
                <Input
                  type='date'
                  defaultValue={
                    defaultlDataSource.onboardDate &&
                    new Date(defaultlDataSource.onboardDate)
                      .toISOString()
                      .substring(0, 10)
                  }
                />
              </Form.Item>
              <Form.Item name='contract' label='Hợp đồng làm việc'>
                <Input
                  defaultValue={defaultlDataSource.contract}
                  placeholder='Dán link tài liệu vào đây (Ví dụ Google Drive)'
                />
              </Form.Item>
            </div>
            {/* <div className="inline-input">
              <Form.Item name="salary" label="Lương cứng (VNĐ)">
                <Input defaultValue={defaultlDataSource.salary} />
              </Form.Item>
            </div> */}

            <EditableTable
              getDataSource={getDataSource}
              count={count}
              defaultlDataSource={defaultlDataSource.task}
            />
          </Form>
        </>
      )}
    </Modal>
  );
};

export default React.memo(UpdateForm);
