/* eslint-disable react-hooks/exhaustive-deps */
import {
  DollarCircleOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Alert, Button, Card, Col, Row, Tag } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { config, userInfo } from '../../utils';
import { addDefaultSrc } from '../Common/Card/Card';
import Loader from '../Common/Loader/Loader';
import Ripple from '../Common/Ripple';
import './ClassList.css';

const ClassList = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [classes, setClasses] = useState();

  useEffect(() => {
    const fetchClassNames = async () => {
      setLoading(true);

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/classes?select=class,cover,order,fee,sub_class.class_name,sub_class.class_teaching_assistant,sub_class.class_teacher`,
        config
      );

      if (
        (userInfo?.user?.role.includes('teacher') ||
          userInfo?.user?.role.includes('assistant')) &&
        ['sales', 'accountant', 'cskh', 'sales_mn', 'admin'].every(
          (role) => !userInfo?.user?.role.includes(role)
        )
      ) {
        const currentUserId = userInfo?.user?.id;
        const filteredClasses = data.data?.filter((classObj) => {
          let isClassVisible = false;
          const subs = classObj.sub_class;

          for (let sub of subs) {
            isClassVisible =
              sub.class_teaching_assistant?.includes(currentUserId) ||
              sub.class_teacher?.includes(currentUserId);
            if (isClassVisible) break;
          }

          return isClassVisible;
        });

        setClasses(
          filteredClasses.sort((a, b) => parseInt(a.order) - parseInt(b.order))
        );
      } else {
        setClasses(
          data.data.sort((a, b) => parseInt(a.order) - parseInt(b.order))
        );
      }
      setLoading(false);
    };

    fetchClassNames();
  }, []);

  return (
    <div className='class-list'>
      {classes?.length > 0 ? (
        <>
          {userInfo?.user.role.includes('admin') && (
            <Link to='/classes/setting'>
              <Button
                type='primary'
                icon={<SettingOutlined />}
                style={{ marginLeft: '8px' }}
              >
                {t('settings2')}
                <Ripple />
              </Button>
            </Link>
          )}
          <Row
            gutter={{
              xs: 4,
              sm: 8,
              md: 16,
            }}
            style={{ marginTop: '20px' }}
          >
            {classes.map((classObj) => (
              <Col xs={24} sm={12} md={8} lg={6} key={classObj._id}>
                <Link to={`/classes/${classObj._id}`}>
                  <Card
                    hoverable
                    cover={
                      <div className='card-cover-container'>
                        <img
                          alt='example'
                          style={{ height: '100%' }}
                          src={classObj.cover || '/logo-new.png'}
                        />
                      </div>
                    }
                  >
                    <Card.Meta
                      title={
                        <div
                          style={{
                            textTransform: 'uppercase',
                            marginTop: 10,
                          }}
                        >
                          {classObj.class}
                        </div>
                      }
                      description={
                        <>
                          <Tag color='orange'>
                            <DollarCircleOutlined
                              style={{
                                marginRight: 5,
                              }}
                            />
                            {classObj.fee}đ
                          </Tag>
                          <Tag color='purple'>
                            <UserOutlined
                              style={{
                                marginRight: 5,
                              }}
                            />
                            {classObj.sub_class?.length} Lớp học
                          </Tag>
                          <div
                            style={{
                              minHeight: 28,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              marginTop: 6,
                            }}
                          >
                            {classObj.sub_class
                              .map((sub) => sub.class_name)
                              .join(', ')}
                          </div>
                        </>
                      }
                    />
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </>
      ) : classes?.length === 0 ? (
        <div style={{ minHeight: '60vh' }}>
          <Alert
            type='info'
            message='Bạn đang không quản lý lớp học nào, liên hệ với Admin để biết thêm
        thông tin.'
          />
        </div>
      ) : (
        <Loader fullHeight />
      )}
    </div>
  );
};

export default React.memo(ClassList);
