import { Avatar, Tag, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './RequestSentItem.css';

const RequestSentItem = ({ request }) => {
  const { t } = useTranslation();
  return (
    <div className='rq-sent'>
      <Link to={request.link}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
          <Avatar src={request.from.avatar} style={{ marginRight: 10 }} />
          <div>
            <Typography.Text className='rq-content'>
              {request.content}
            </Typography.Text>
            <div style={{ color: '#a3a3a3' }}>
              {t('by')}: {t('me')},{' '}
              {new Date(request.createdAt).toLocaleString()}
            </div>
          </div>
        </div>
      </Link>
      {request.status === 'accepted' ? (
        <Tag color='green'>{t('requestAccepted')}</Tag>
      ) : request.status === 'rejected' ? (
        <Tag color='red'>{t('requestDenied')}</Tag>
      ) : (
        <Tag color='blue'>{t('requestPendingApproval')}</Tag>
      )}
    </div>
  );
};

export default RequestSentItem;
