import { Avatar, Button, Table } from 'antd';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

const SalaryTable = ({ users }) => {
  const columns = [
    {
      title: 'Thao tác',
      dataIndex: '',
      fixed: 'left',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button>
            <Link to={`/users/${record.id}/salary`}>Xem</Link>
          </Button>
        </div>
      ),
    },
    {
      title: 'Nhân sự',
      dataIndex: 'fullname',
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            style={{ marginRight: '10px' }}
            src={record.avatar}
            size='large'
          />
          <Link className='username' to={`/users/${record.id}/salary`}>
            {text}
          </Link>
        </div>
      ),
    },
    // {
    //   title: "Tổng thu nhập",
    //   dataIndex: "",
    // },
  ];

  const usersFiltered = useMemo(
    () =>
      users.filter((user) =>
        ['sales', 'teacher'].some((item) => user.role.includes(item))
      ),
    [users]
  );

  return (
    <div>
      <Table
        showHeader={usersFiltered?.length > 0}
        pagination={{ pageSize: 100 }}
        bordered
        columns={columns}
        dataSource={usersFiltered}
        scroll={{ x: 'max-content' }}
        showSorterTooltip={true}
        sticky
      />
    </div>
  );
};

export default SalaryTable;
