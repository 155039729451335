const userInfoConfig = {
  department: [
    "Marketing",
    "Kinh doanh",
    "Đào tạo",
    "HCNS",
    "Tài chính Kế toán",
    "Chăm sóc khách hàng",
    "Phát triển sản phẩm",
    "Ban Giám đốc",
  ],
  position: [
    "Giám đốc",
    "Phó giám đốc",
    "Trưởng phòng kinh doanh",
    "Trưởng nhóm kinh doanh",
    "Nhân viên kinh doanh",
    "CTV kinh doanh",
    "Trưởng phòng Marketing",
    "Trưởng nhóm Content",
    "Trưởng nhóm Marketing",
    "Trưởng nhóm Event",
    "Trưởng nhóm Digital",
    "Trưởng nhóm Video",
    "Designer",
    "Nhân viên Marketing",
    "Giáo viên",
    "Trợ giảng",
    "Trưởng phòng HCNS",
    "Admin HCNS",
    "Nhân viên HCNS",
    "Nhân viên Kế toán thuế",
    "Nhân viên Kế toán nội bộ",
    "Trưởng nhóm CSKH",
    "Nhân viên CSKH",
    "Trưởng phòng Sản phẩm",
    "Trưởng nhóm Sản phẩm",
    "Nhân viên Sản phẩm",
  ],
  workingType: ["Fulltime", "Parttime", "CTV", "Dự án", "Outsource"],
  statusWorking: [
    "Đang làm việc",
    "Đã nghỉ việc",
    "Cho thôi việc",
    "Đã nghỉ hưu",
    "Đang nghỉ phép",
    "Đang thử việc",
    "Đang thử thách",
  ],
};

module.exports = userInfoConfig;
