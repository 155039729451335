import { CloudDownloadOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { Button, Modal, Table, message } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import * as fs from 'fs';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx/xlsx.mjs';
import { useUsers } from '../../hooks/useUsers';
import { config } from '../../utils';
import {
  acceptStyle,
  baseStyle,
  focusedStyle,
  rejectStyle,
} from './DropZoneStyles';
import './FileImport.css';
XLSX.set_fs(fs);

const FileImport = () => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);

  const { data: users } = useUsers('users', {
    enabled: false,
  });
  const fileData = useRef();
  const cosultantUserMapping = useRef({});

  const handleOk = async () => {
    try {
      let isError = false;
      // get user id associate with cosultant name
      Object.keys(cosultantUserMapping.current).forEach(async (cosultant) => {
        const user = users?.find((user) => user.fullname === cosultant);
        console.log('user', user);
        if (!user) {
          isError = true;
        }
        cosultantUserMapping.current[cosultant] = user?._id;
      });

      if (isError) {
        message.error('Một số bản ghi thông tin Tư vấn viên chưa đúng');
        return;
      }
      console.log('cosultantUserMapping', cosultantUserMapping.current);

      // update input data
      const updatedData = data?.map((item) => ({
        ...item,
        user: cosultantUserMapping.current[item.cosultant],
      }));
      console.log('updatedData', updatedData);

      // bulk insert
      const { data: response } = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/records/bulk`,
        { data: updatedData },
        config
      );

      if (response.success) {
        message.success('Nhập các bản ghi thành công');
      }
    } catch (error) {
      message.error(`Có lỗi xảy ra: `, error);
    } finally {
      setOpen(false);
      fileData.current = null;
      setData(null);
    }
  };

  const handleCancel = () => {
    setOpen(false);
    fileData.current = null;
    setData(null);
  };

  const onDrop = useCallback((files) => {
    const file = files[0];

    if (!file) return;

    fileData.current = file;
    console.log('file', file);
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onabort = () => {
      message.warning('File reading was aborted');
      console.log('File reading was aborted');
    };
    reader.onerror = () => {
      message.warning('File reading has failed');
      console.log('File reading has failed');
    };
    reader.onload = (e) => {
      const bufferArray = e?.target.result;
      const workbook = XLSX.read(bufferArray, { type: 'buffer' });
      const sheetNameList = workbook.SheetNames;
      const firstWorksheet = workbook.Sheets[sheetNameList[0]];
      const dataJson = XLSX.utils.sheet_to_json(firstWorksheet, {
        raw: false,
      });
      const formattedData = dataJson.map((item, index) => {
        cosultantUserMapping.current[item['Tư vấn viên']] = '';
        return {
          key: index + 1,
          createdAt: dayjs(item['Ngày nhập']).format(),
          name: item['Họ và tên khách'],
          phone: item['Số điện thoại'],
          channel: item['Channel'],
          cosultant: item['Tư vấn viên'],
          status: item['Status'],
          note: item['Note'],
        };
      });

      console.log('formattedData', formattedData);
      setData(formattedData);
    };
  }, []);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    isDragActive,
  } = useDropzone({
    onDrop,
    multiple: false,
    noDragEventsBubbling: true,
    accept: {
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
      ],
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const columns = [
    {
      title: t('no'),
      dataIndex: 'key',
      width: 55,
    },
    {
      title: t('recordInputDate'),
      dataIndex: 'createdAt',
      render: (text) => dayjs(text).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: t('recordName'),
      dataIndex: 'name',
    },
    {
      title: t('recordPhone'),
      dataIndex: 'phone',
    },
    {
      title: t('recordChannel'),
      dataIndex: 'channel',
    },
    {
      title: t('recordConsultant'),
      dataIndex: 'cosultant',
    },
    {
      title: t('recordStatus'),
      dataIndex: 'status',
    },
    {
      title: t('recordNote'),
      dataIndex: 'note',
      width: 65,
    },
  ];

  return (
    <>
      <Button
        style={{ marginRight: 10 }}
        type='primary'
        icon={<CloudDownloadOutlined />}
        onClick={() => setOpen(true)}
      >
        {t('importFromFile')}
      </Button>
      <Modal
        width={1000}
        title={
          <>
            {t('importFromFile')}
            <a
              style={{
                marginLeft: 10,
                fontWeight: 'normal',
                fontSize: 12,
                textDecoration: 'underline',
              }}
              href='/data-import.xlsx'
              download
            >
              {t('viewSample')}
            </a>
          </>
        }
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={t('ok')}
        cancelText={t('cancel')}
      >
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <>
              {isDragAccept ? (
                <p>Drop the file here ...</p>
              ) : (
                <p>File format not suppoted</p>
              )}
            </>
          ) : (
            <p>
              <CloudUploadOutlined /> Drag 'n' drop file here, or click to
              select file (.xlsx, .xls)
            </p>
          )}
        </div>
        {fileData.current && (
          <>
            <p style={{ marginTop: 10 }}>
              <img
                src='/xlsx.png'
                style={{ width: 20, marginBottom: 5 }}
                alt=''
              />{' '}
              {fileData.current.name}
            </p>
          </>
        )}
        <div className='file-import__preview'>
          {data && (
            <Table
              className='file-import__table'
              columns={columns}
              dataSource={data}
              scroll={{ x: 'max-content' }}
              sticky
            />
          )}
        </div>
      </Modal>
    </>
  );
};

export default FileImport;
