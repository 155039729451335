import { useQuery } from 'react-query';
import { getAllChannels } from '../resources/configs';

const keyConfigMapping = {
  channels: getAllChannels,
};

export const useConfigs = (key, options) =>
  useQuery(key, keyConfigMapping[key], {
    ...options,
  });
