import ioClient from 'socket.io-client';

const socket = ioClient(process.env.REACT_APP_SOCKET_URL);

// socket.on('connect_error', (err) => {
//   message.error('Socket connect error: ', err.message);
// });
// socket.on('connect_failed', (err) => {
//   message.error('Socket connect failed: ', err);
// });

export { socket };
