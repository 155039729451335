import React from "react";
import { Skeleton } from "antd";

const SkeletonList = () => {
  return (
    <div>
      <Skeleton.Input style={{ width: 200, marginBottom: "20px" }} active />
      <Skeleton active avatar />
      <Skeleton active avatar />
      <Skeleton active avatar />
      <Skeleton active avatar />
    </div>
  );
};

export default SkeletonList;
