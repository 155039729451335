import axios from 'axios';
import queryString from 'query-string';
import { config, userInfo } from '../utils';

export const getRecords = async (query) => {
  let url;
  const stringified = queryString.stringify(query, {
    skipEmptyString: true,
    skipNull: true,
  });

  if (
    userInfo?.user?.role.includes('admin') ||
    userInfo?.user?.role.includes('tiktok1') ||
    userInfo?.user?.role.includes('unknown_1') ||
    userInfo?.user?.role.includes('tiktok_ads') ||
    userInfo?.user?.username === 'TVTS4' ||
    userInfo?.user?.role.includes('sales_mn') ||
    userInfo?.user?.username === 'TV0048'
  ) {
    url = `${process.env.REACT_APP_API_URL}/v1/records?${stringified}`;
  } else {
    url = `${process.env.REACT_APP_API_URL}/v1/records?user=${userInfo.user._id}&${stringified}`;
  }

  const { data } = await axios.get(url, config);

  return data;
};

export const getRegisteredRecords = async (query) => {
  let url;
  const stringified = queryString.stringify(query, {
    skipEmptyString: true,
    skipNull: true,
  });

  if (
    userInfo?.user.role.includes('admin') ||
    userInfo?.user.role.includes('dev') ||
    userInfo?.user.role.includes('accountant') ||
    userInfo?.user.username === 'TVTS4' ||
    userInfo?.user.role.includes('sales_mn')
  ) {
    url = `${process.env.REACT_APP_API_URL}/v1/records?${stringified}`;
  } else {
    url = `${process.env.REACT_APP_API_URL}/v1/records?user=${userInfo?.user._id}&${stringified}`;
  }

  const { data } = await axios.get(url, config);

  return data;
};

export const getRecord = async (id) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/v1/records/${id}`,
    config
  );
  return data;
};
