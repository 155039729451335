import { Spin, Tabs, Typography } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { config } from '../../utils';
import RequestItem from '../RequestItem/RequestItem';
import './ManageRequests.css';

const { TabPane } = Tabs;

const ManageRequests = () => {
  const [requests, setRequests] = useState([]);
  const [status, setStatus] = useState('pending');
  const [loadingChangeTab, setLoadingChangeTab] = useState(false);

  useEffect(() => {
    const fetchRequests = async (status) => {
      try {
        setLoadingChangeTab(true);

        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/requests?status=${status}`,
          config
        );
        setRequests(data.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingChangeTab(false);
      }
    };

    fetchRequests(status);
  }, [status]);

  const onChange = (key) => {
    setStatus(key);
  };

  const tabs = [
    { name: 'Đang chờ', key: 'pending' },
    { name: 'Đã chấp nhận', key: 'accepted' },
    { name: 'Đã loại bỏ', key: 'rejected' },
  ];

  return (
    <div className='mn-requests'>
      <h1>Tất cả yêu cầu ({requests?.length})</h1>
      <Typography.Text>
        <small>
          Chọn trạng thái Chấp nhận hoặc Loại bỏ với từng yêu cầu, sau khi Loại
          bỏ, chọn Xoá để dọn dẹp các yêu cầu.
        </small>
      </Typography.Text>
      <Tabs defaultActiveKey='1' onChange={onChange}>
        {tabs.map((tab) => (
          <TabPane tab={tab.name} key={tab.key}>
            {loadingChangeTab ? (
              <Spin />
            ) : requests?.length > 0 ? (
              requests?.map((request) => (
                <RequestItem status={tab.key} request={request} />
              ))
            ) : (
              <Typography.Text>Chưa có yêu cầu nào</Typography.Text>
            )}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default ManageRequests;
