import {
  ControlOutlined,
  DeleteOutlined,
  EditOutlined,
  ExportOutlined,
  FileAddOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  AutoComplete,
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Popover,
  Radio,
  Select,
  Space,
  Switch,
  Table,
  Tag,
  Tooltip,
  Typography,
  message,
} from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Confirm } from 'react-st-modal';
import ExportExcel from '../../components/ExportExcel/ExportExcel';
import { useClasses } from '../../hooks/useClasses';
import { useConfigs } from '../../hooks/useConfigs';
import { useCosultants } from '../../hooks/useCosultants';
import { useUsers } from '../../hooks/useUsers';
import { getRecords } from '../../resources/records';
import { useBoundStore } from '../../store/useBoundStore';
import {
  LEVEL_ENUM,
  config,
  getFilterTags,
  getRecordWarning,
  getTableRowClassName,
  userInfo,
} from '../../utils';
import emptyCell from '../../utils/emptyCell';
import locations from '../../utils/locations';
import { statusRecord } from '../../utils/status';
import * as studyStatusEnum from '../../utils/studyStatus';
import CreateForm from '../Common/CreateForm';
import Loader from '../Common/Loader/Loader';
import Ripple from '../Common/Ripple';
import '../Common/Table.css';
import UpdateForm from '../Common/UpdateForm';
import FileImport from '../FileImport/FileImport';
import { useWindowSize } from 'react-use';
dayjs.extend(customParseFormat);

const { Option } = Select;
const { Text } = Typography;

const CustomRow = ({ tooltip, ...restProps }) => {
  return (
    <Tooltip title={tooltip} placement='left'>
      <tr {...restProps} />
    </Tooltip>
  );
};

const InputTable = ({ onlyTiktok, tiktokAds }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const [updateTable, setUpdateTable] = useState(false);
  const [updateItem, setUpdateItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [dataSheets, setDataSheets] = useState([]);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
  const [loadingEditMulti, setLoadingEditMulti] = useState(false);

  const filters = useBoundStore((state) => state.filters.inputTable);
  const {
    name,
    expectedClass,
    startDate,
    endDate,
    channel,
    cosultant,
    yearOfBirth,
    address,
    phone,
    studyStatus,
    status,
    source,
    tuitionStatus,
    isRegister,
    level,
    page,
    limit,
  } = filters;

  const updateInputTableFilters = useBoundStore(
    (state) => state.updateInputTableFilters
  );
  const clearInputTableFilter = useBoundStore(
    (state) => state.clearInputTableFilter
  );
  const clearAllInputTableFilters = useBoundStore(
    (state) => state.clearAllInputTableFilters
  );
  const exportOptions = useBoundStore((state) => state.exportOptions);
  const updateExportOptions = useBoundStore(
    (state) => state.updateExportOptions
  );

  const { data: channels } = useConfigs('channels');
  const { data: classes } = useClasses('classes', { initialData: [] });
  const { data: cosultants } = useCosultants('cosultants', { initialData: [] });
  const { data: users } = useUsers('users');

  const query = useMemo(
    () => ({
      name,
      expected_class: expectedClass,
      start_date: startDate,
      end_date: endDate,
      channel: onlyTiktok
        ? 'Tiktok'
        : tiktokAds
        ? ['Ads TikTok', 'Website']
        : channel,
      year: tiktokAds ? dayjs().year() : '',
      cosultant,
      year_of_birthday: yearOfBirth,
      address,
      phone,
      study_status: studyStatus,
      status,
      source,
      tuition_status: tuitionStatus,
      is_register: isRegister,
      level,
      page,
      limit,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters]
  );

  const {
    data: dataSource,
    isFetching,
    isError,
    error,
  } = useQuery(['getDataSource', updateTable], () => getRecords(query), {
    initialData: null,
  });

  const fetchExcel = async () => {
    try {
      setLoadingExcel(true);

      let dataSourceExport = [];
      const exportPage = exportOptions.page;
      if (exportPage === '2') {
        const { data } = await getRecords({ ...query, limit: '' });
        dataSourceExport = data;
      } else if (dataSource?.data?.length) {
        dataSourceExport = [...dataSource.data];
      }

      if (dataSourceExport.length) {
        let dataSets = [];
        dataSourceExport.forEach((record) => {
          let dataSet = {};
          dataSet.no = record.no;
          dataSet.month = record.month;
          dataSet.createdAt = dayjs(record.createdAt).format('DD/MM/YYYY');
          dataSet.phone = record.phone;
          dataSet.name = record.name;
          dataSet.year_of_birthday = record.year_of_birthday;
          dataSet.email = record.email;
          dataSet.address = record.address;
          dataSet.class = record.class?.class;
          dataSet.channel = record.channel;
          dataSet.cosultant = record.cosultant;
          dataSet.is_register = record.is_register;
          dataSet.note = record.note;

          dataSets.push(dataSet);
        });

        const columns = [
          {
            label: t('no'),
            value: 'no',
          },
          {
            label: t('recordInputDate'),
            value: 'createdAt',
          },
          { label: t('recordPhone'), value: 'phone' },
          { label: t('recordName'), value: 'name' },
          { label: t('recordDateOfBirthday'), value: 'year_of_birthday' },
          { label: t('recordSex'), value: 'sex' },
          { label: t('recordEmail'), value: 'email' },
          { label: t('recordAddress'), value: 'address' },
          { label: t('recordExpectedClass'), value: 'class' },
          { label: t('recordChannel'), value: 'channel' },
          { label: t('recordConsultant'), value: 'cosultant' },
          { label: t('recordRegistered'), value: 'is_register' },
          { label: t('recordClassification'), value: 'level' },
          { label: t('recordStatus'), value: 'status' },
          { label: t('recordNote'), value: 'note' },
        ];

        const sheet = {
          sheetName: `DATA Nhập liệu_${new Date().getTime()}`,
          sheetDataSets: dataSets,
          sheetColumns: columns,
        };

        setDataSheets([sheet]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingExcel(false);
    }
  };

  useEffect(() => {
    fetchExcel(exportOptions.page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportOptions.page, dataSource]);

  const onCreate = (values) => {
    const createRecord = async () => {
      values.createdBy = userInfo?.user.fullname;
      values.user = userInfo?.user._id;

      setLoadingCreate(true);

      await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/records`,
        values,
        config
      );

      setLoadingCreate(false);
      setVisibleCreate(false);
      setUpdateTable(!updateTable);
      message.success('Thêm thành công');
    };

    createRecord();
  };

  const onUpdate = (values) => {
    const updateRecord = async () => {
      const userID = users.find(
        (user) => user.fullname === values.cosultant
      )?._id;

      values.updatedBy = userInfo.user.fullname;

      if (!!userID) {
        values.user = userID;
      }

      setLoadingUpdate(true);

      await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/records/${updateItem._id}`,
        values,
        config
      );

      setLoadingUpdate(false);

      setUpdateTable(!updateTable);
      setVisibleUpdate(false);

      message.success('Cập nhật thành công');
    };

    updateRecord();
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const handleDelete = async () => {
    setLoading(true);

    const deleteById = async (id) => {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/v1/records/${id}`,
        config
      );

      setUpdateTable(!updateTable);
    };

    const result = await Confirm(t('confirmDelete'));

    if (result) {
      selectedRowKeys.forEach((id) => {
        deleteById(id);
      });

      setLoading(false);
      setSelectedRowKeys([]);

      message.success(t('deletedSuccessfully'));
    } else {
      setLoading(false);
      setSelectedRowKeys([]);
    }
  };

  const renderWarnings = (record) => {
    const warns = getRecordWarning(record);
    return warns.map((warn) => {
      if (warn.type === 2) {
        return (
          <>
            <Popover
              content={
                <>
                  {warn.data.map((warnDetail) => (
                    <p>{warnDetail}</p>
                  ))}
                </>
              }
              title='Chi tiết cảnh báo'
            >
              <Tag color={warn.color} style={{ marginBottom: 10 }}>
                {warn.text} <QuestionCircleOutlined />{' '}
              </Tag>
            </Popover>
            <br />
          </>
        );
      } else if (warn.type === 3) {
        return (
          <>
            <Popover
              content={
                <>
                  {warn.data.map((warnObj) => (
                    <p>
                      {warnObj.title}: <u>{warnObj.currentValue}</u>
                    </p>
                  ))}
                </>
              }
              title='Chi tiết cảnh báo'
            >
              <Tag color={warn.color} style={{ marginBottom: 10 }}>
                {warn.text} <QuestionCircleOutlined />{' '}
              </Tag>
            </Popover>
            <br />
          </>
        );
      } else {
        return (
          <>
            <Tag color={warn.color} style={{ marginBottom: 10 }}>
              {warn.text}
            </Tag>
            <br />
          </>
        );
      }
    });
  };

  const columns = [
    {
      title: t('edit'),
      dataIndex: '',
      fixed: 'left',
      render: (_, record) => (
        <>
          {!userInfo?.user?.role.includes('tiktok1') &&
          !userInfo?.user?.role.includes('tiktok_ads') &&
          userInfo?.user?.username !== 'TV0048' ? (
            <Tooltip
              placement='rightBottom'
              title={`${t('recordCreatedBy')} ${
                record.createdBy || 'THALIC EDU'
              }. ${t('recordLastModified')}: ${new Date(
                record.updatedAt
              ).toLocaleString()} ${t('by')}: ${
                record.updatedBy || record.createdBy || 'THALIC EDU'
              }`}
            >
              <Button
                size='small'
                icon={<EditOutlined />}
                onClick={() => {
                  setVisibleUpdate(true);
                  setUpdateItem(record);
                }}
              >
                <Ripple color='#ffcd0f' />
              </Button>
            </Tooltip>
          ) : (
            <Button disabled size='small' icon={<EditOutlined />} />
          )}
        </>
      ),
    },
    {
      title: t('no'),
      dataIndex: 'no',
      fixed: 'left',
      width: 55,
    },
    {
      title: t('recordName'),
      dataIndex: 'name',
      fixed: width >= 768 ? 'left' : '',
      render: (_, record) => emptyCell(record.name),
    },
    {
      title: t('recordPotentail'),
      dataIndex: 'potential',
      width: 120,
      render: (_, record) => record.potential || 'Chưa xác định',
    },
    {
      title: t('recordInputDate'),
      dataIndex: 'createdAt',
      render: (_, record) => dayjs(record.createdAt).format('DD/MM/YYYY'),
      width: 120,
    },
    {
      title: t('recordPhone'),
      dataIndex: 'phone',
      width: 120,
      render: (_, record) =>
        !userInfo?.user?.role.includes('tiktok_ads')
          ? emptyCell(record.phone ? record.phone.slice(0, -3) + '***' : '')
          : '***',
    },
    // {
    //   title: t('recordWarning'),
    //   width: 120,
    //   render: (_, record) => renderWarnings(record),
    // },
    {
      title: t('recordDateOfBirthday'),
      dataIndex: 'year_of_birthday',
      width: 75,
    },
    {
      title: t('recordSex'),
      dataIndex: 'sex',
      width: 75,
    },
    {
      title: t('recordEmail'),
      dataIndex: 'email',
      width: 120,
      render: (text) =>
        !userInfo?.user?.role.includes('tiktok_ads') ? text : '***',
    },
    {
      title: t('recordAddress'),
      dataIndex: 'address',
    },
    {
      title: t('recordExpectedClass'),
      dataIndex: '',
      width: 115,
      render: (_, record) => emptyCell(record.class?.class),
    },
    {
      title: t('recordChannel'),
      dataIndex: 'channel',
      width: 100,
      render: (_, record) =>
        emptyCell(
          userInfo?.user.role.includes('admin') ||
            userInfo?.user.role.includes('sales_mn') ||
            userInfo?.user.role.includes('sales')
            ? record.channel
            : '***'
        ),
    },
    {
      title: t('recordConsultant'),
      dataIndex: 'cosultant',
      width: 120,
      render: (_, record) =>
        !userInfo?.user?.role.includes('tiktok_ads')
          ? emptyCell(record.cosultant)
          : '***',
    },
    {
      title: t('recordRegistered'),
      dataIndex: 'is_register',
      render: (_, record) => (record.is_register ? '️✔️' : ''),
      width: 75,
    },
    {
      title: t('recordClassification'),
      dataIndex: 'level',
      width: 90,
    },
    {
      title: t('recordStatus'),
      dataIndex: 'status',
      width: 75,
      render: (_, record) => emptyCell(record.status),
    },
    {
      title: t('recordCallback'),
      width: 100,
      dataIndex: 'callback',
      render: (text) => (text ? dayjs(text).format('HH:mm, DD/MM/YYYY') : ''),
    },
    {
      title: t('recordOccupation'),
      dataIndex: 'occupation',
      render: (text) => (
        <div
          style={{
            maxWidth: 300,
            minWidth: 100,
            overflowWrap: 'break-word',
            whiteSpace: 'initial',
          }}
        >
          {emptyCell(text)}
        </div>
      ),
    },
    {
      title: t('recordPurpose'),
      dataIndex: 'purpose',
      render: (text) => (
        <div
          style={{
            maxWidth: 300,
            minWidth: 100,
            overflowWrap: 'break-word',
            whiteSpace: 'initial',
          }}
        >
          {emptyCell(text)}
        </div>
      ),
    },
    {
      title: t('recordNote1'),
      dataIndex: 'note',
      render: (text) => (
        <div
          style={{
            maxWidth: 300,
            minWidth: 100,
            overflowWrap: 'break-word',
            whiteSpace: 'initial',
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: t('recordNote2'),
      dataIndex: 'note_1',
      render: (text) => (
        <div
          style={{
            maxWidth: 300,
            minWidth: 100,
            overflowWrap: 'break-word',
            whiteSpace: 'initial',
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: t('utm_source'),
      dataIndex: 'utm_source',
      width: 120,
    },
    {
      title: t('utm_medium'),
      dataIndex: 'utm_medium',
      width: 120,
    },
    {
      title: t('utm_content'),
      dataIndex: 'utm_content',
      width: 120,
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onSubmitFilters = () => {
    setIsModalVisible(false);
    form.resetFields();
    setUpdateTable(!updateTable);
    console.log('query', query);
  };

  const onResetFilers = () => {
    clearAllInputTableFilters();
    onSubmitFilters();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleTableChange = (pagination) => {
    window.scrollTo({ top: 0, left: 0 });
    updateInputTableFilters({
      page: pagination.current,
      limit: pagination.pageSize,
    });
    setUpdateTable(!updateTable);
  };

  const handleFiltersChange = (e) => {
    updateInputTableFilters({ [e.target.name]: e.target.value });
  };

  const handleClearFilter = (key) => {
    clearInputTableFilter(key);
    onSubmitFilters();
  };

  const handleEditMulti = (values) => {
    setLoadingEditMulti(true);
    const updateById = async (id) => {
      values.is_register = null;
      values.isKhachLe = null;
      values.isKhachCombo = null;
      values.isKhachHocLen = null;

      await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/records/${id}`,
        values,
        config
      );

      setUpdateTable(!updateTable);
    };
    selectedRowKeys.forEach((id) => {
      updateById(id);
    });
    setLoadingEditMulti(false);
    setSelectedRowKeys([]);
    message.success('Update thành công mục đã chọn');
    setIsModalVisibleEdit(false);
  };

  const contentExport = () => (
    <div>
      <Radio.Group
        defaultChecked={exportOptions.page}
        onChange={(e) => updateExportOptions({ page: e.target.value })}
      >
        <Space direction='vertical'>
          <Radio value={'1'}>{t('currentPage')}</Radio>
          <Radio value={'2'}>{t('allPages')}</Radio>
        </Space>
      </Radio.Group>
      <ExportExcel
        loading={loadingExcel}
        filename='Danh sách nhập liệu'
        dataSheets={dataSheets}
      />
    </div>
  );

  const renderTableHeader = (modalId) => (
    <>
      <div style={{ marginBottom: 16 }} className='table-header'>
        <div className='left'>
          {/* <div className='delete-zone'>
            <Modal
              title='Chỉnh sửa hàng loạt'
              open={isModalVisibleEdit}
              onCancel={() => setIsModalVisibleEdit(false)}
              footer={[
                <Button onClick={() => setIsModalVisibleEdit(false)}>
                  {t('cancel')}
                </Button>,
                <Button
                  key='submit'
                  type='primary'
                  loading={loadingEditMulti}
                  onClick={() => {
                    form
                      .validateFields()
                      .then((values) => {
                        form.resetFields();
                        handleEditMulti(values);
                      })
                      .catch((info) => {
                        console.log('Validate Failed:', info);
                      });
                  }}
                >
                  {t('ok')}
                </Button>,
              ]}
            >
              <Form form={form} layout='vertical' name='form_in_modal'>
                <div id={`ud-popup-col-multi-${modalId}`}>
                  <Form.Item
                    name='cosultant'
                    label={t('consultant')}
                    rules={[
                      {
                        required: data?.cosultant ? false : true,
                        message: 'Trường này là bắt buộc!',
                      },
                    ]}
                  >
                    <Select
                      getPopupContainer={() =>
                        document.getElementById(`ud-popup-col-multi-${modalId}`)
                      }
                    >
                      {cosultants?.map((item) => (
                        <Option key={item._id} value={item.fullname}>
                          {item.fullname}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Form>
            </Modal>
          </div> */}
          {hasSelected && (
            <div className='delete-zone'>
              <Button
                className='delete-btn'
                type='default'
                onClick={handleDelete}
                disabled={!hasSelected}
                loading={loading}
                icon={<DeleteOutlined />}
              >
                {t('delete')}
                <Ripple />
              </Button>
              <span style={{ marginLeft: 8, marginRight: 10 }}>
                {hasSelected ? (
                  <Text>
                    {selectedRowKeys.length} {t('selected')}
                  </Text>
                ) : (
                  ''
                )}
              </span>
            </div>
          )}
          {!userInfo?.user.role.includes('tiktok1') &&
            !userInfo?.user?.role.includes('tiktok_ads') &&
            userInfo?.user?.username !== 'TV0048' && (
              <div className='add-zone'>
                <Button
                  type='primary'
                  style={{
                    marginRight: 10,
                  }}
                  icon={<FileAddOutlined />}
                  onClick={() => {
                    setVisibleCreate(true);
                  }}
                >
                  {t('createRecord')}
                  <Ripple />
                </Button>
              </div>
            )}
          <div className='filter-zone'>
            <Button
              onClick={showModal}
              type='default'
              icon={<ControlOutlined />}
            >
              {t('filter')}
              <Ripple />
            </Button>
            <Modal
              title={t('filterRecordModal')}
              open={isModalVisible}
              onCancel={handleCancel}
              footer={[
                <Button onClick={onResetFilers}>{t('reset')}</Button>,
                <Button onClick={handleCancel}>{t('cancel')}</Button>,
                <Button key='submit' type='primary' onClick={onSubmitFilters}>
                  {t('ok')}
                </Button>,
              ]}
            >
              <Form form={form} layout='vertical' name='form_in_modal'>
                <div className='inline-input'>
                  <Form.Item name='startDate' label={t('fromDate')}>
                    <DatePicker
                      style={{ width: '100%' }}
                      disabledDate={(current) =>
                        !userInfo?.user.role.includes('admin') &&
                        current.year() < dayjs().year()
                      }
                      format={'DD/MM/YYYY'}
                      defaultValue={
                        startDate && moment(startDate, 'DD/MM/YYYY')
                      }
                      onChange={(_, dateString) =>
                        updateInputTableFilters({ startDate: dateString })
                      }
                    />
                  </Form.Item>
                  <Form.Item name='endDate' label={t('toDate')}>
                    <DatePicker
                      style={{ width: '100%' }}
                      disabledDate={(current) =>
                        !userInfo?.user.role.includes('admin') &&
                        current.year() < dayjs().year()
                      }
                      format={'DD/MM/YYYY'}
                      defaultValue={endDate && moment(endDate, 'DD/MM/YYYY')}
                      onChange={(_, dateString) =>
                        updateInputTableFilters({ endDate: dateString })
                      }
                    />
                  </Form.Item>
                </div>
                <div className='inline-input'>
                  <Form.Item label={t('recordPhone')}>
                    <Input
                      allowClear
                      defaultValue={phone}
                      value={phone}
                      name='phone'
                      onChange={(e) => handleFiltersChange(e)}
                    />
                  </Form.Item>
                  <div id='popup-status-xx'>
                    <Form.Item name='study_status' label={t('studyStatus')}>
                      <Select
                        allowClear
                        defaultValue={studyStatus}
                        value={studyStatus}
                        onChange={(value) =>
                          updateInputTableFilters({ studyStatus: value })
                        }
                        placeholder={t('typeToSearch')}
                        showSearch
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                      >
                        {studyStatusEnum.map((status) => (
                          <Option value={status}>{status}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className='inline-input'>
                  <Form.Item label={t('recordDateOfBirthday')}>
                    <Input
                      allowClear
                      defaultValue={yearOfBirth}
                      value={yearOfBirth}
                      name='yearOfBirth'
                      onChange={(e) => handleFiltersChange(e)}
                    />
                  </Form.Item>
                  <div id='popup-address'>
                    <Form.Item name='address' label={t('recordAddress')}>
                      <AutoComplete
                        getPopupContainer={() =>
                          document.getElementById('popup-address')
                        }
                        allowClear
                        defaultValue={address}
                        value={address}
                        onChange={(value) =>
                          updateInputTableFilters({ address: value })
                        }
                        showSearch
                        options={locations}
                        placeholder={t('typeToSearch')}
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className='inline-input'>
                  <Form.Item label={t('recordName')}>
                    <Input
                      allowClear
                      defaultValue={name}
                      value={name}
                      name='name'
                      onChange={(e) => handleFiltersChange(e)}
                    />
                  </Form.Item>
                  <div id='popup-expcls'>
                    <Form.Item
                      name='expectedClass'
                      label={t('recordExpectedClass')}
                    >
                      <Select
                        placeholder={t('typeToSearch')}
                        showSearch
                        allowClear
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                        defaultValue={expectedClass}
                        value={expectedClass}
                        onChange={(value) =>
                          updateInputTableFilters({ expectedClass: value })
                        }
                      >
                        <Option value='Chưa chọn'>Chưa chọn</Option>
                        {classes?.map((classObj) => (
                          <Option key={classObj._id} value={classObj.class}>
                            {classObj.class}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className='inline-input'>
                  {(userInfo?.user.role.includes('admin') ||
                    userInfo?.user.role.includes('sales')) && (
                    <div id='popup-channel-1'>
                      <Form.Item name='channel' label={t('recordChannel')}>
                        <AutoComplete
                          showSearch
                          allowClear
                          defaultValue={channel}
                          value={channel}
                          onChange={(value) =>
                            updateInputTableFilters({ channel: value })
                          }
                          style={{
                            width: '100%',
                          }}
                          fieldNames={{
                            value: 'channel_name',
                            label: 'channel_name',
                          }}
                          options={channels}
                          placeholder={t('typeToSearch')}
                          filterOption={(inputValue, option) =>
                            option.channel_name
                              .toUpperCase()
                              .indexOf(inputValue.toUpperCase()) !== -1
                          }
                        />
                      </Form.Item>
                    </div>
                  )}
                  <div id='popup-col'>
                    <Form.Item name='cosultant' label={t('consultant')}>
                      <Select
                        defaultValue={cosultant}
                        value={cosultant}
                        onChange={(value) =>
                          updateInputTableFilters({ cosultant: value })
                        }
                        showSearch
                        allowClear
                        placeholder={t('typeToSearch')}
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                      >
                        <Option value=''>Không chọn</Option>
                        {cosultants?.map((item) => (
                          <Option key={item._id} value={item.fullname}>
                            {item.fullname}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className='inline-input'>
                  <div id='popup-level'>
                    <Form.Item name='level' label={t('recordClassification')}>
                      <Select
                        allowClear
                        defaultValue={level}
                        value={level}
                        onChange={(value) =>
                          updateInputTableFilters({ level: value })
                        }
                      >
                        <Option value=''>Không chọn</Option>
                        {LEVEL_ENUM.map((item) => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div id='popup-status'>
                    <Form.Item name='status' label={t('recordStatus')}>
                      <Select
                        placeholder={t('typeToSearch')}
                        defaultValue={status}
                        value={status}
                        onChange={(value) =>
                          updateInputTableFilters({ status: value })
                        }
                        showSearch
                        allowClear
                        filterOption={(inputValue, option) =>
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                      >
                        <Option value=''>Không chọn</Option>
                        {statusRecord.map((item) => (
                          <Option key={item.value} value={item.value}>
                            {item.value}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </Modal>
          </div>
          <div className='source-zone'>
            <Text className=''>{t('dataSource')}: </Text>
            <Select
              style={{ width: 100, marginLeft: 5 }}
              defaultValue={source}
              value={source}
              onChange={(value) => {
                updateInputTableFilters({ source: value });
                onSubmitFilters();
              }}
            >
              <Option value=''>{t('all')}</Option>
              <Option value='1'>THALIC EDU</Option>
              <Option value='2'>CRM</Option>
              <Option value='3'>PANCAKE</Option>
            </Select>
          </div>
          <div className='source-zone'>
            <Text style={{ marginRight: 5 }}>{t('recordRegistered')}: </Text>
            <Switch
              onClick={(value) => {
                updateInputTableFilters({ isRegister: value });
                onSubmitFilters();
              }}
              defaultChecked={isRegister}
              checked={isRegister}
            />
          </div>
        </div>
        <div className='right flex items-center'>
          {(userInfo?.user.role.includes('admin') ||
            userInfo?.user.role.includes('sales_mn')) && <FileImport />}
          {!userInfo?.user.role.includes('tiktok1') &&
            !userInfo?.user?.role.includes('tiktok_ads') && (
              <Popover
                placement='bottomRight'
                title={t('exportData')}
                content={contentExport}
                trigger='click'
              >
                <Button type='primary' icon={<ExportOutlined />}>
                  {t('exportExcel')}
                </Button>
              </Popover>
            )}
        </div>
      </div>
    </>
  );

  const renderTableFilterTags = () => (
    <div style={{ marginBottom: 10, display: 'flex', flexWrap: 'wrap' }}>
      {Object.entries(getFilterTags(filters)).map(([key, valueObj]) => {
        return (
          <div>
            {valueObj.value ? (
              <Tag
                closable
                onClose={() => handleClearFilter(key)}
                style={{ marginBottom: 10 }}
              >
                {t(valueObj.transformedKey)}: {t(valueObj.value)}
              </Tag>
            ) : null}
          </div>
        );
      })}
    </div>
  );

  if (isFetching) return <Loader fullHeight />;

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <div
      className='input-table'
      style={{ marginTop: onlyTiktok || tiktokAds ? 80 : 'unset' }}
    >
      <>
        {renderTableHeader()}
        {renderTableFilterTags()}
        <Table
          showHeader={dataSource?.data.length > 0}
          pagination={{
            current: page,
            pageSize: limit,
            total: dataSource?.total,
          }}
          onChange={handleTableChange}
          bordered
          rowSelection={
            userInfo?.user?.role.includes('admin') ? rowSelection : ''
          }
          columns={columns}
          dataSource={dataSource?.data}
          components={{
            body: {
              row: CustomRow,
            },
          }}
          onRow={(record) => ({
            tooltip:
              record.source === '1'
                ? `${t('recordSource')} thalic.edu.vn`
                : record.source === '3'
                ? `${t('recordSource')} Pancake`
                : `${t('recordSource')} CRM`,
          })}
          rowClassName={(record) => getTableRowClassName(record)}
          scroll={{ x: 'max-content' }}
          showSorterTooltip={true}
          sticky
        />
        <CreateForm
          visible={visibleCreate}
          onCreate={onCreate}
          onCancel={() => {
            setVisibleCreate(false);
          }}
          loading={loadingCreate}
        />
        <UpdateForm
          visible={visibleUpdate}
          onUpdate={onUpdate}
          onCancel={() => {
            setVisibleUpdate(false);
          }}
          updateItem={updateItem}
          loading={loadingUpdate}
        />
      </>
    </div>
  );
};

export default InputTable;
