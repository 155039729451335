import { DeleteOutlined, RollbackOutlined } from '@ant-design/icons';
import { Avatar, Button, Form, Input, List, Tag, Typography } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { config, userInfo } from '../../../utils';
import SkeletonList from '../../SubClassList/SkeletonList';
import './NotificationDetails.css';
import SkeletonComments from './SkeletonComments';

const { Text, Title } = Typography;

const NotificationDetails = ({ match, history }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [noti, setNoti] = useState(null);
  const [comments, setComments] = useState([]);
  const [loadingCreateComment, setLoadingCreateComment] = useState(false);
  const [loadingDeleteComment, setLoadingDeleteComment] = useState(false);
  const [loadingComments, setLoadingComments] = useState(false);
  const [loves, setLoves] = useState('');
  const [claps, setClaps] = useState('');
  const [happy, setHappy] = useState('');
  const [smile, setSmile] = useState('');
  const [sad, setSad] = useState('');

  const url = `${process.env.REACT_APP_API_URL}/v1/notifications/${match.params.id}`;

  // fetch noti
  useEffect(() => {
    if (!userInfo) {
      history.push('/');
    }

    const fetchData = async () => {
      const { data } = await axios.get(url, config);

      // update views
      if (data) {
        await axios.put(`${url}/views`, { views: data.data.views + 1 }, config);
      }

      setNoti(data.data);
      setLoves(data.data.loves);
      setClaps(data.data.claps);
      setHappy(data.data.happy);
      setSmile(data.data.smile);
      setSad(data.data.sad);
    };

    fetchData();
  }, [match]);

  // fetch comments
  useEffect(() => {
    const fetchComments = async () => {
      setLoadingComments(true);

      const url = `${process.env.REACT_APP_API_URL}/v1/notifications/${match.params.id}`;

      const { data } = await axios.get(url, config);

      setComments(data.data.comments);
      setLoadingComments(false);
    };

    fetchComments();
  }, [match, loadingCreateComment, loadingDeleteComment]);

  // create comment
  const onFinish = async (values) => {
    setLoadingCreateComment(true);

    await axios.post(
      `${process.env.REACT_APP_API_URL}/v1/notifications/${match.params.id}/comments`,
      {
        content: values.content,
      },
      config
    );

    form.resetFields();
    setLoadingCreateComment(false);
  };

  // delete comment
  const handleDeleteComment = async (id) => {
    setLoadingDeleteComment(true);

    await axios.put(
      `${process.env.REACT_APP_API_URL}/v1/notifications/${match.params.id}/comments`,
      {
        commentId: id,
      },
      config
    );

    setLoadingDeleteComment(false);
  };

  const handleHeart = async () => {
    await axios.put(
      `${process.env.REACT_APP_API_URL}/v1/notifications/${match.params.id}/loves`,
      {
        loves: loves + 1,
      },
      config
    );
    setLoves(loves + 1);
  };

  const handleClap = async () => {
    await axios.put(
      `${process.env.REACT_APP_API_URL}/v1/notifications/${match.params.id}/claps`,
      {
        claps: claps + 1,
      },
      config
    );
    setClaps(claps + 1);
  };

  const handleHappy = async () => {
    await axios.put(
      `${process.env.REACT_APP_API_URL}/v1/notifications/${match.params.id}/happy`,
      {
        happy: happy + 1,
      },
      config
    );
    setHappy(happy + 1);
  };

  const handleSmile = async () => {
    await axios.put(
      `${process.env.REACT_APP_API_URL}/v1/notifications/${match.params.id}/smile`,
      {
        smile: smile + 1,
      },
      config
    );
    setSmile(smile + 1);
  };

  const handleSad = async () => {
    await axios.put(
      `${process.env.REACT_APP_API_URL}/v1/notifications/${match.params.id}/sad`,
      {
        sad: sad + 1,
      },
      config
    );
    setSad(sad + 1);
  };

  return (
    <div className='noti-item'>
      {noti ? (
        <>
          <Link to={`/`}>
            <Button
              style={{ marginBottom: '20px' }}
              type='primary'
              icon={<RollbackOutlined />}
            >
              {t('goToAnnouncements')}
            </Button>
          </Link>
          <Title level={4}>{noti.title}</Title>
          <div className='des'>
            {t('postedOn')} {new Date(noti.createdAt).toLocaleString()}{' '}
            {t('by')} <span>{noti.user.fullname}</span>{' '}
            {noti.user.role.map((role) => (
              <Tag
                color={noti.user.role.includes('admin') ? 'magenta' : 'cyan'}
              >
                {role}
              </Tag>
            ))}
          </div>
          <div className='content'>
            <Text>
              <pre>{noti.description}</pre>
            </Text>
          </div>

          <div className='noti-reacts'>
            <Title level={5}>{t('reactions')}</Title>
            <div className='emoji'>
              <div className='heart' onClick={handleHeart}>
                {loves && loves} <img src='/heart.svg' width='18' alt='heart' />
              </div>
              <div className='clap' onClick={handleClap}>
                {claps && claps} <img src='/clap.svg' width='18' alt='clap' />
              </div>
              <div className='happy' onClick={handleHappy}>
                {happy && happy} <img src='/happy.svg' width='18' alt='happy' />
              </div>
              <div className='smile' onClick={handleSmile}>
                {smile && smile} <img src='/smile.svg' width='18' alt='smile' />
              </div>
              <div className='sad' onClick={handleSad}>
                {sad && sad} <img src='/crying.svg' width='18' alt='sad' />
              </div>
            </div>
          </div>

          <div className='comments'>
            <Title level={5}>
              {t('comments')} {comments.length > 0 && `(${comments.length})`}
            </Title>

            <div className='comments-lis'></div>
            {!loadingComments ? (
              <>
                {comments.length > 0 ? (
                  <>
                    <List
                      pagination={{
                        pageSize: 10,
                      }}
                      itemLayout='horizontal'
                      dataSource={comments}
                      renderItem={(item) => (
                        <List.Item>
                          <List.Item.Meta
                            avatar={
                              <Avatar size='large' src={item.user.avatar} />
                            }
                            title={
                              <div className='list-item-title'>
                                <Text>{item.user.fullname}</Text>
                                {item.user.toString() ===
                                  userInfo?.user._id.toString() && (
                                  <Button
                                    icon={<DeleteOutlined />}
                                    onClick={() =>
                                      handleDeleteComment(item._id)
                                    }
                                  >
                                    {t('delete')}
                                  </Button>
                                )}
                              </div>
                            }
                            description={
                              <div className='list-des'>
                                <div className='time'>
                                  {dayjs(item.createdAt).fromNow()}
                                </div>
                              </div>
                            }
                          />
                          {item.content}
                        </List.Item>
                      )}
                    />
                  </>
                ) : (
                  <Text>{t('noComments')}</Text>
                )}
              </>
            ) : (
              <SkeletonComments />
            )}
            <Form
              form={form}
              name='normal_login'
              className='comments-form'
              onFinish={onFinish}
            >
              <Form.Item
                name='content'
                rules={[{ required: true, message: t('ruleCommentField') }]}
              >
                <Input.TextArea
                  rows={3}
                  showCount
                  placeholder={`${t('addAComment')}...`}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  className='login-form-button'
                >
                  {t('postComment')}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </>
      ) : (
        <SkeletonList />
      )}
    </div>
  );
};

export default NotificationDetails;
