import {
  CalendarOutlined,
  DollarOutlined,
  FileDoneOutlined,
  FolderOpenOutlined,
  RollbackOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Input, List, Space, Typography } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { config } from '../../utils/';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Ripple from '../Common/Ripple';
import SkeletonList from './SkeletonList';
import './SubClassList.css';

const { Text } = Typography;

const IconText = ({ icon, text }) => (
  <Space className='icon-text'>
    <Text>
      {React.createElement(icon)} {text}
    </Text>
  </Space>
);

const PAGE_SIZE = 15;

const SubClassList = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const pathSplit = location.pathname.toString().split('/');
  const classId = pathSplit[pathSplit.length - 1];

  const [subClasses, setSubClasses] = useState(null);
  const [primaryClass, setPrimaryClass] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    const fetchSubClasses = async () => {
      try {
        setLoading(true);

        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/classes/sub_class_list?primary_class_id=${classId}&page=${page}&limit=${PAGE_SIZE}&keyword=${keyword}`,
          config
        );

        setSubClasses(data.data?.subClasses);
        setPrimaryClass(data.data?.primaryClass);
        setTotal(data.data?.total);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubClasses();
  }, [location, classId, page, keyword]);

  return (
    <div className='sub-class-list'>
      {!loading && subClasses && primaryClass ? (
        <>
          <Helmet>
            <title>
              {t('classList')} {primaryClass.name} | THALIC CRM
            </title>
            <meta
              property='og:title'
              content={`${t('classList')} ${primaryClass.name} | THALIC CRM`}
            />
          </Helmet>
          <List
            itemLayout='vertical'
            size='large'
            pagination={{
              onChange: (page) => setPage(page),
              pageSize: PAGE_SIZE,
              current: page,
              total,
            }}
            dataSource={subClasses}
            header={
              <div className='sub-class-header'>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                  }}
                >
                  <Link to={`/classes`}>
                    <Button
                      style={{ marginBottom: '20px' }}
                      type='primary'
                      icon={<RollbackOutlined />}
                    >
                      {t('goToClasses')}
                      <Ripple />
                    </Button>
                  </Link>
                  <Input.Search
                    placeholder='Tìm mã lớp học'
                    defaultValue={keyword}
                    onSearch={(value) => {
                      setPage(1);
                      setKeyword(value);
                    }}
                    style={{
                      width: 200,
                      marginBottom: '20px',
                      marginLeft: 20,
                    }}
                  />
                </div>

                <div>
                  {t('classListBelongsTo')} <b>{primaryClass.name}</b>
                </div>
              </div>
            }
            renderItem={(item) => (
              <Link
                to={`/records?sub_class_id=${item._id}&class_id=${primaryClass.id}`}
              >
                <List.Item
                  style={{
                    borderRadius: 10,
                  }}
                  key={item._id}
                >
                  <div className='sub-class-items'>
                    <div className='flex items-center'>
                      <div className='sub-class-avt'>
                        <Avatar size='large' src={primaryClass.cover} />
                      </div>
                      <div className='sub-class-text'>
                        <p className='class-name'>{item.class_name}</p>
                        <div className='sub-class-details'>
                          <IconText
                            icon={TeamOutlined}
                            text={
                              <>
                                {t('studentsCount')}:
                                <b style={{ color: '#ff7979' }}>
                                  {' '}
                                  {item.students_count}
                                </b>
                              </>
                            }
                            key='list-vertical-star-ss'
                          />
                          <IconText
                            icon={DollarOutlined}
                            text={`${primaryClass.fee} đ`}
                            key='list-vertical-star-o'
                          />
                          <IconText
                            icon={FolderOpenOutlined}
                            text={
                              item.class_opening
                                ? dayjs(item.class_opening).format('DD/MM/YYYY')
                                : 'N/A'
                            }
                            key='list-vertical-like-o'
                          />
                          <IconText
                            icon={FileDoneOutlined}
                            text={
                              item.class_end
                                ? dayjs(item.class_end).format('DD/MM/YYYY')
                                : 'N/A'
                            }
                            key='list-vertical-message'
                          />
                          <IconText
                            icon={UserOutlined}
                            text={`${t('classTeachers')}: ${
                              item.class_teacher.fullname || '/'
                            }, ${t('classAssistants')}: ${
                              item.class_teaching_assistant.fullname || '/'
                            }`}
                            key='list-vertical-message'
                          />
                          <IconText
                            icon={CalendarOutlined}
                            text={`${t('classSchedule')}: ${
                              item.class_hour || 'N/A'
                            }; ${item.class_schedule || 'N/A'} `}
                            key='list-vertical-message'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </List.Item>
                <br />
              </Link>
            )}
          />
        </>
      ) : (
        <SkeletonList />
      )}
    </div>
  );
};

export default SubClassList;
