import { t } from 'i18next';

const emptyCell = (check) => {
  return (
    <>
      {check ? (
        <p style={{ margin: '0' }}>{check}</p>
      ) : (
        <p
          style={{
            width: '100%',
            height: '100%',
            background: '#e74c3c',
            color: '#fff',
            textAlign: 'center',
            padding: '3px 5px',
            margin: '0',
            borderRadius: 8,
          }}
        >
          {t('required')}
        </p>
      )}
    </>
  );
};

export default emptyCell;
