import React from "react";
import "./UserDetailsItem.css";

const UserDetailsItem = ({ title, content, isLink }) => {
  return (
    <div className="user-details-item">
      <div className="user-details-text">
        <div>{title}</div>
        {isLink ? (
          <>
            {content ? (
              <a href={content} target="_blank" rel="noreferrer noopener">
                Xem hợp đồng
              </a>
            ) : (
              <span style={{ fontWeight: "500" }}>N/A</span>
            )}
          </>
        ) : (
          <>
            <div style={{ fontWeight: "500" }}>{content || "N/A"}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default UserDetailsItem;
