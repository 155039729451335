import React from 'react';
import { Avatar, List } from 'antd';
import './Ranks.css';

const data = [
  {
    title: 'Tân binh',
    level: 0,
  },
  {
    title: 'Hạ sĩ',
    level: 1,
  },
  {
    title: 'Trung sĩ',
    level: 2,
  },
  {
    title: 'Thượng sĩ',
    level: 3,
  },
  {
    title: 'Thiếu úy',
    level: 4,
  },
  {
    title: 'Trung úy',
    level: 5,
  },
  {
    title: 'Thượng úy',
    level: 6,
  },
  {
    title: 'Đại úy',
    level: 7,
  },
  {
    title: 'Thiếu tá',
    level: 8,
  },
  {
    title: 'Trung tá',
    level: 9,
  },
  {
    title: 'Thượng tá',
    level: 10,
  },
  {
    title: 'Đại tá',
    level: 11,
  },
  {
    title: 'Thiếu tướng',
    level: 12,
  },
  {
    title: 'Trung tướng',
    level: 13,
  },
  {
    title: 'Thượng tướng',
    level: 14,
  },
  {
    title: 'Đại tướng',
    level: 15,
  },
];

const Ranks = () => {
  return (
    <div className='ranks'>
      <h1>Định nghĩa các cấp bậc</h1>
      <List
        itemLayout='horizontal'
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar src='' />}
              title={item.title}
              description={`Level`}
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default Ranks;
