import axios from 'axios';
import { config } from '../utils';

export const getAllChannels = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/v1/configs`,
    config
  );
  return data.data.channels;
};
