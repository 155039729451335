import React from 'react';
import { Skeleton } from 'antd';
import './Ranking.css';

const SkeletonRanking = () => {
  return (
    <div className='ranking-skeleton'>
      <Skeleton active />
      <Skeleton active />
    </div>
  );
};

export default SkeletonRanking;
