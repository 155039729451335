import { BackTop, Layout } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { ChevronUp } from 'react-feather';
import { Route, Switch } from 'react-router-dom';
import '../App.css';
import PrivateRoute from '../components/Common/PrivateRoute';
import Header from '../components/Header/Header';
import SiderMenu from '../components/SiderMenu/SiderMenu';
import TopNotifications from '../components/TopNotifications/TopNotifications';
import history from '../history';
import NotFoundPage from '../pages/NotFoundPage/NotFoundPage';
import { privateRoutes, publicRoutes } from '../routes';
import { socket } from '../services/socket';
import { config, userInfo } from '../utils';
import { APP_VERSION } from '../constants';

const { Footer, Content, Header: AntHeader, Sider } = Layout;

const DefaultLayout = ({ children }) => {
  const [notifications, setNotifications] = useState(null);
  const [people, setPeople] = useState('');
  const [collapsed, setCollapsed] = useState(false);
  const [hasNewData, setHasNewData] = useState(false);
  const fetchDataIntervalId = useRef();
  const timeoutId = useRef();

  const fetchNotis = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1/notifications`,
      config
    );
    setNotifications(data.data.slice(0, 3));
  };

  const hasNewDataIn12Hours = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1/statistics/latest_data?userId=${userInfo?.user._id}`,
      config
    );

    if (data.success) {
      return dayjs().diff(data.data.createdAt, 'hour') < 12;
    }
    return true;
  };

  const getRandomNoti = () => {
    const contents = [
      '720 phút trôi qua, bạn chưa mang về vị khách hàng đáng yêu nào cho THALIC.',
      '12 giờ dài như 12 năm vì THALIC không được gặp khách của bạn.',
      'Cổ của sếp dài ra thêm 720 cm rồi vì 720 phút vẫn chưa có khách mới.',
      'Sếp không giỏi Toán nhưng vẫn tính được 12 giờ tương đương 720 phút hoặc 43200 giây bạn chưa có khách hàng mới.',
      'Cảm giác chờ chậm lương 1h của bạn tương đương với 12h sếp chờ học viên mới.',
      'Dân số Việt Nam 100 triệu người, bạn không tìm được 1 khách hàng mới trong 12h sao?',
      'Mỗi phút trôi qua là một cơ hội để tìm kiếm khách hàng mới. Đừng để 12 giờ qua đi mà bạn không khai thác được bất kỳ cơ hội nào!',
      'Hãy viết tiếp bài tập làm văn sau: Ngày xửa ngày xưa, có một bạn tư vấn tuyển sinh đã 12 giờ trôi qua vẫn chưa có khách mới…',
      'Bạn là một siêu nhân sale, nhưng hãy nhớ rằng siêu nhân cũng cần khách hàng mới để thể hiện sức mạnh!',
      'Lọ Lem đi chơi quá 12h bị mất hết đồ đạc, trang sức… Bạn không chốt đơn quá 12h mất đi một phần lương cuối tháng.',
      'Thời gian trôi nhanh như UFO. Và bạn để cho khách hàng của mình bay đi như những phi hành gia.',
      'Bạn có biết sếp với CH4 khác nhau chỗ nào không? CH4 là mêtan, còn sếp mê có khách mới.',
      'Trái Đất có cực Nam và cực Bắc. Còn THALIC chỉ có một cực, đó là CỰC CẦN HỌC VIÊN.',
      'Hình có 3 cạnh là Tam giác. Còn 12h mà không có khách là sếp đang bị Ảo giác đúng không?',
      'Nếu ghét sếp là đúng, vậy thì em cứ sai. Như con số 12, nửa ngày chưa có khách',
      'Hoà hợp âm dương. Lương với khách là một. Thiếu khách lương buồn. Vơi lương do khách ít.',
      'Dăm ba cây kẹo mút. Làm sao cuốn hút bằng tiếng Ting Ting',
      'Thứ hai là Monday, thứ ba là Tuesday còn người đòi KPI em là SẾP ĐÂY!',
      'Khách hàng là một điều gì đó xa xỉ. Data cả tỷ nhưng sẵn sàng chi có mấy người?!',
      '[Disappointed] - Thất vọng (Tính từ): Là từ dùng để miêu tả sự hụt hẫng tột độ khi 720 phút trôi qua vẫn chưa thấy hình bóng học viên mới nào.',
      'Không có khách không phải là cái tội. Đó là sự vượt trội hơn người về việc tư vấn chưa thành công.',
      '12h qua bình yên đến lạ. Không tiếng ting ting, yên tĩnh như khuya rằm.',
      'Lâu lắm rồi không có tiếng ting ting. Đến chiếc lá rơi cũng làm sếp giật mình. ',
      'Cỗ nào chẳng có thịt gà. Khách mà không có, cả nhà mất vui.',
      'Bắc Đẩu đã có Nam Tào. Còn em đã có khách nào hay chưa?',
    ];

    let randomIndex = Math.ceil(Math.random() * (contents.length - 1));
    return contents[randomIndex];
  };

  const getRandomImg = () => {
    const imgs = [
      '/12h_1.jpg',
      '/12h_2.jpg',
      '/12h_3.jpg',
      '/12h_4.jpg',
      '/12h_5.jpg',
      '/12h_6.jpg',
      '/12h_7.jpg',
      '/12h_8.jpg',
      '/12h_9.jpg',
      '/12h_10.jpg',
      '/12h_11.jpg',
      '/12h_12.jpg',
      '/12h_13.jpg',
      '/12h_14.jpg',
    ];

    let randomIndex = Math.ceil(Math.random() * (imgs.length - 1));
    return imgs[randomIndex];
  };

  // fetch notifications
  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
      return;
    }

    // handle socket connection
    socket.emit('client: user connected', userInfo?.user);
    socket.on('server: connected users', function (message) {
      setPeople(JSON.parse(message));
    });

    socket.on('server: f5', function (message) {
      if (message === 'all' || message === userInfo?.user._id) {
        window.location.reload();
      }
    });

    socket.on('server: logout', function (message) {
      if (message === 'all' || message === userInfo?.user._id) {
        localStorage.removeItem('userInfo');
        history.push('/login');
        window.location.reload();
      }
    });

    fetchNotis();

    // if (fetchDataIntervalId.current) {
    //   clearInterval(fetchDataIntervalId.current);
    //   fetchDataIntervalId.current = undefined;
    // }
    // fetchDataIntervalId.current = setInterval(async () => {
    //   const isHasNewData = await hasNewDataIn12Hours();
    //   setHasNewData(isHasNewData);
    // }, 1 * 60 * 1000); // 1 minute

    // // clear interval
    // return () => clearInterval(fetchDataIntervalId.current);
  }, []);

  // useEffect(() => {
  //   if (!hasNewData) {
  //     if (
  //       localStorage.getItem(`is_close_12h_popup_${userInfo?.user._id}`) !==
  //         'true' &&
  //       !!userInfo
  //     ) {
  //       if (timeoutId.current) {
  //         clearInterval(timeoutId.current);
  //         timeoutId.current = undefined;
  //       }
  //       timeoutId.current = setTimeout(() => {
  //         Swal.fire({
  //           text: getRandomNoti(),
  //           title: `${userInfo?.user.fullname} ơi`,
  //           confirmButtonText: `Tắt thông báo`,
  //           confirmButtonColor: '#716add',
  //           imageUrl: getRandomImg(),
  //           imageHeight: 300,
  //           imageAlt: 'Custom image',
  //           width: 360,
  //           allowOutsideClick: false,
  //           backdrop: `
  //             rgba(0,0,0,0.6)
  //             left top
  //             no-repeat
  //           `,
  //         }).then((result) => {
  //           if (result.isConfirmed) {
  //             Swal.fire({
  //               icon: 'question',
  //               text: 'Bạn chắc chắn tắt chứ? Bạn đã có khách hàng đúng không?',
  //               confirmButtonColor: '#716add',
  //               confirmButtonText: 'Chính xác',
  //               allowOutsideClick: false,
  //               backdrop: `
  //               rgba(0,0,0,0.6)
  //               left top
  //               no-repeat
  //             `,
  //               width: 600,
  //             }).then((result) => {
  //               if (result.isConfirmed) {
  //                 localStorage.setItem(
  //                   `is_close_12h_popup_${userInfo?.user._id}`,
  //                   true
  //                 );
  //               }
  //             });
  //           }
  //         });
  //       }, 3000);
  //     }
  //   } else {
  //     localStorage.setItem(`is_close_12h_popup_${userInfo?.user._id}`, false);
  //   }

  //   // clear timeout
  //   return () => clearTimeout(timeoutId.current);
  // }, [hasNewData]);

  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <BackTop>
        <div
          style={{
            height: 40,
            width: 40,
            lineHeight: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            backgroundColor: '#ffcd0f',
            boxShadow: '0px 2px 6px rgb(255 205 15 / 0.3)',
            color: '#fff',
            textAlign: 'center',
          }}
        >
          <ChevronUp size={20} />
        </div>
      </BackTop>
      <AntHeader>
        <Header history={history} notifications={notifications} />
      </AntHeader>
      <Layout
        style={{
          flexDirection: 'row',
        }}
        hasSider
      >
        {userInfo && (
          <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            width={!collapsed ? 300 : 'auto'}
            className='sider-wrapper'
            style={{
              overflowX: 'hidden',
              overflowY: 'auto',
              position: 'fixed',
              left: 0,
              top: '64px',
              bottom: 0,
              zIndex: 999,
              paddingBottom: '200px',
              paddingInline: 12,
            }}
          >
            <SiderMenu history={history} />
          </Sider>
        )}
        <Layout
          className='content-wrapper'
          style={{
            padding: '0 15px 0',
            marginLeft: userInfo ? (!collapsed ? 300 : 75) : 0,
            marginTop: userInfo ? '64px' : '-20px',
            // backgroundImage: `url('/theming-gradient.png')`,
          }}
        >
          {window.location.pathname !== '/login' && notifications ? (
            <TopNotifications
              history={history}
              notifications={notifications}
              people={people}
            />
          ) : (
            <div style={{ marginTop: '64px' }}></div>
          )}
          <div style={{ margin: '10px 0' }}></div>
          <Content>
            <div className='main-content'>
              <main className='body'>
                <Switch>
                  {publicRoutes.map((route) => (
                    <Route
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                    />
                  ))}
                  {privateRoutes.map((route) => (
                    <PrivateRoute
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                      isAuthenticated={!!userInfo}
                    />
                  ))}
                  <Route path='*' component={NotFoundPage} />
                  {children}
                </Switch>
              </main>
            </div>
            <Footer>
              <footer className='footer'>
                <p style={{ textAlign: 'center' }}>
                  &copy;Copyright {new Date(Date.now()).getFullYear()} - Thalic
                  Voice CRM
                  <br />
                  <p>
                    Version: {APP_VERSION} | Dev:{' '}
                    <a
                      href='https://www.facebook.com/hungnguyen2301/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <b style={{ color: '#686de0' }}>Hung Nguyen</b>
                    </a>
                  </p>
                </p>
              </footer>
            </Footer>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default DefaultLayout;
