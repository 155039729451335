import React from "react";
import { Skeleton } from "antd";

const SkeletonForm = () => {
  return (
    <div>
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
    </div>
  );
};

export default SkeletonForm;
