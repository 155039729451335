import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './ChannelConfigItem.css';

const ChannelConfigItem = ({
  data,
  handleDelete,
  handleAdd,
  handleChange,
  isLast,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleChangeValue = (values) => {
    handleChange(data._id, values.channel_name);
  };

  return (
    <div className='channel-item-wrapper flex items-start'>
      <div className='channel-item'>
        <Form
          form={form}
          onValuesChange={handleChangeValue}
          initialValues={{
            channel_name: data.channel_name,
          }}
        >
          <Form.Item
            name='channel_name'
            rules={[
              {
                required: true,
                message: 'Kênh tiếp cận không được để trống',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </div>
      <Tooltip title={t('delete')}>
        <Button
          onClick={() => handleDelete(data._id)}
          size='small'
          className='icon-btn'
          shape='circle'
          icon={<CloseOutlined size={14} />}
        ></Button>
      </Tooltip>
      {isLast && (
        <Tooltip title='Thêm'>
          <Button
            onClick={handleAdd}
            size='small'
            className='icon-btn'
            shape='circle'
            icon={<PlusOutlined size={14} />}
          ></Button>
        </Tooltip>
      )}
    </div>
  );
};

export default ChannelConfigItem;
