import React from 'react';
import BillDetails from '../BillDetails';
import './BillPDF.css';

const BillPDF = React.forwardRef((props, ref) => {
  const { bill } = props;

  return (
    <div className={`bill-pdf`} ref={ref}>
      <div className='bill-header'>
        <img
          style={{ width: 25, display: 'block' }}
          src='/favicon.png'
          alt='Thalic Voice'
        />
        <p>THALIC VOICE - Giọng nói và kỹ năng</p>
      </div>
      <div className='bill-main'>
        <h1 className='bill-heading'>PHIẾU THU</h1>
        <div className='bill-subheading'>Học viên: {bill?.name}</div>
        <p></p>
        <div className='bill-details'>
          <BillDetails updateItem={bill} />
          <div className='note'>
            Lưu ý: Không hoàn trả lại học phí đã thanh toán trong bất kỳ trường
            hợp nào.
          </div>
        </div>
      </div>
    </div>
  );
});

export default React.memo(BillPDF);
