import axios from 'axios';
import { config } from '../utils';

export const getAllCosultants = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/v1/cosultants`,
    config
  );
  return data.data;
};
