import axios from 'axios';
import { useQuery } from 'react-query';
import { AuthServcie } from '../services/auth';
import { config, parseJwt, userInfo } from '../utils';

export const useAuth = () => {
  // check if user server token exists or not
  const url = `${process.env.REACT_APP_API_URL}/v1/users/${userInfo?.user?.id}`;
  useQuery(
    'checkUserToken',
    async () => {
      const { data } = await axios.get(url, config);
      return data.data;
    },
    {
      enabled: !!userInfo,
      refetchInterval: 5 * 60 * 1000,
      refetchOnWindowFocus: true,
      onSuccess: (data) => {
        const serverToken = data.token;

        if (!serverToken) {
          AuthServcie.logOut({ isTokenExpired: true });
        }
      },
      onError: () => {
        const localToken = userInfo?.token;
        const decodedJwt = parseJwt(localToken);

        if (decodedJwt.exp * 1000 < Date.now()) {
          AuthServcie.logOut({ isTokenExpired: true });
        }
      },
    }
  );
};
