import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Col, Form, Input, Row, message } from 'antd';
import axios from 'axios';
import { t } from 'i18next';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import '../../components/Common/AuthForm.css';
import { config, userInfo } from '../../utils';

const Login = ({ history }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const isTokenExpired = queryString.parse(
    window.location.search
  )?.is_token_expired;

  useEffect(() => {
    if (!!userInfo) {
      history.push('/');
    }

    if (isTokenExpired) {
      message.warning('Phiên đăng nhập đã hết hạn');
    }
  }, [isTokenExpired]);

  const login = async (values) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/auth/login`,
        {
          username: values.username,
          password: values.password,
        },
        config
      );

      localStorage.setItem('userInfo', JSON.stringify(data));

      window.location.href = '/';
    } catch (error) {
      message.error('Tên đăng nhập hoặc mật khẩu không đúng');
    }
  };

  const onFinish = (values) => {
    setLoading(true);
    login(values);
    setLoading(false);
  };

  return (
    <div className='login' style={{ marginTop: '100px' }}>
      <h1
        style={{
          marginBottom: 20,
          fontSize: 18,
        }}
      >
        {t('login')}
      </h1>
      <Row gutter={32}>
        <Col xs={24} md={12}>
          <div className='banner'>
            <img src='/login_banner_v2.svg' height='180' alt='login-banner' />
          </div>
        </Col>
        <Col xs={24} md={12}>
          <Form
            name='normal_login'
            form={form}
            className='login-form'
            onFinish={onFinish}
          >
            <Form.Item
              name='username'
              rules={[
                {
                  required: true,
                  message: 'Hãy nhập tên tài khoản!',
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className='site-form-item-icon' />}
                placeholder='Tên tài khoản'
              />
            </Form.Item>
            <Form.Item
              name='password'
              rules={[
                {
                  required: true,
                  message: 'Hãy nhập mật khẩu!',
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className='site-form-item-icon' />}
                iconRender={(visible) =>
                  visible ? (
                    <EyeTwoTone style={{ color: '#ffcd0f' }} />
                  ) : (
                    <EyeInvisibleOutlined style={{ color: '#ffcd0f' }} />
                  )
                }
                placeholder='Mật khẩu'
              />
            </Form.Item>
            <Form.Item>
              {/* <p>
                Chưa có tài khoản? <Link to='/register'>Đăng ký</Link>
              </p> */}
              <Button
                type='primary'
                htmlType='submit'
                className='login-form-button'
                loading={loading}
              >
                {t('login')}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
