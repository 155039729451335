import { Button, Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditableTable from './EditableTable';

const CreateForm = ({ visible, onCreate, onCancel, loading }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [count, setCount] = useState(0);

  const getDataSource = (data) => {
    setDataSource(data);
  };

  return (
    <Modal
      className='class-setting'
      open={visible}
      title={t('consultant')}
      onCancel={onCancel}
      footer={[
        <Button onClick={onCancel}>{t('cancel')}</Button>,
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                values.kpi = dataSource;
                onCreate(values);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          {t('ok')}
        </Button>,
      ]}
    >
      <Form form={form} layout='vertical' name='form_in_modal'>
        <Form.Item name='fullname' label={t('consultant')}>
          <Input defaultValue='' placeholder={`${t('e.g.')}Nguyễn Văn A`} />
        </Form.Item>

        <EditableTable
          getDataSource={getDataSource}
          dataSource={dataSource}
          count={count}
        />
      </Form>
    </Modal>
  );
};

export default CreateForm;
