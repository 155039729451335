import { SoundOutlined } from '@ant-design/icons';
import {
  Alert,
  Avatar,
  Badge,
  Image,
  Popover,
  Skeleton,
  Typography,
} from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Award, Hash, Star } from 'react-feather';
import { Link } from 'react-router-dom';
import TextLoop from 'react-text-loop';
import { useWindowSize } from 'react-use';
import { useBoundStore } from '../../store/useBoundStore';
import { config } from '../../utils';
import './TopNotifications.css';

const { Text } = Typography;

export const getBadgeStyles = (index) => {
  switch (index) {
    case 0:
      return {
        backgroundColor: '#fff300d6',
        color: '#ffa600',
      };

    case 1:
      return {
        backgroundColor: '#d8d8d8',
        color: '#222',
      };

    case 2:
      return {
        backgroundColor: '#ffa600',
        color: '#f70',
      };

    default:
      return {
        backgroundColor: '#bbdefb',
        color: '#1976d2',
      };
  }
};

const TopNotifications = ({ notifications, people }) => {
  const { width } = useWindowSize();
  const updateRanking = useBoundStore((state) => state.updateRanking);

  const [ranking, setRanking] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/v1/statistics/ranking`;
      const { data } = await axios.post(
        url,
        {
          month_index: dayjs().month(),
          year: dayjs().year(),
        },
        config
      );

      updateRanking(data.data?.ranking || []);
      setRanking(data.data?.ranking || []);
    };

    fetchData();
  }, []);

  return (
    <>
      <div className='top-notifications-container' key={notifications}>
        {notifications.length > 0 && (
          <Alert
            type='error'
            banner
            showIcon
            icon={<SoundOutlined />}
            className='top-notifications'
            message={
              <TextLoop mask interval={5000}>
                {notifications &&
                  notifications.map((notification) => (
                    <>
                      <Link
                        to={`/notifications/${notification._id}`}
                        key={notification._id}
                      >
                        <Text
                          key={notification._id}
                          className='noti-content'
                          ellipsis={true}
                        >
                          {notification.title}
                          {notification.description &&
                            `: ${notification.description}`}
                        </Text>
                      </Link>
                    </>
                  ))}
              </TextLoop>
            }
          />
        )}
      </div>

      <div className='active-tab'>
        <div
          style={{
            marginLeft: '15px',
            marginTop: '3px',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <div style={{ marginRight: '10px', fontWeight: '500' }}>
            <Text> Online ({people?.length || 0})</Text>
          </div>
          <Text>
            {people &&
              people.map((user) => (
                <span style={{ marginRight: '10px' }}>
                  <Badge
                    status='success'
                    style={{
                      marginRight: '3px',
                    }}
                  />{' '}
                  {user.user.fullname}
                </span>
              ))}
          </Text>
        </div>

        {/* ranking */}
        <div
          style={{
            margin: '10px 15px 0',
            display: `${width <= 768 ? 'none' : 'block'}`,
          }}
        >
          {ranking ? (
            <>
              <Avatar.Group
                size='large'
                maxCount={5}
                maxPopoverTrigger='hover'
                maxStyle={{
                  color: '#f56a00',
                  backgroundColor: '#fde3cf',
                  cursor: 'pointer',
                }}
              >
                {ranking.map((cosultant, index) => (
                  <Popover
                    content={
                      <>
                        <div className='flex items-start'>
                          <h4>{cosultant.fullname}</h4>
                          <div
                            style={{
                              width: 24,
                              height: 24,
                              marginLeft: 8,
                              borderRadius: '50%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              fontSize: 12,
                              ...getBadgeStyles(index),
                            }}
                          >
                            {index + 1}
                          </div>
                        </div>
                        <div
                          style={{
                            color: '#888da8',
                          }}
                        >
                          <div className='flex items-center'>
                            <Hash
                              size={14}
                              style={{
                                marginRight: 10,
                              }}
                            />
                            <span>Xếp hạng tháng: {index + 1}</span>
                          </div>
                          <div className='flex items-center'>
                            <Award
                              size={14}
                              style={{
                                marginRight: 10,
                              }}
                            />
                            <span>Danh hiệu: (Coming soon)</span>
                          </div>
                          <div className='flex items-center'>
                            <Star
                              size={14}
                              style={{
                                marginRight: 10,
                              }}
                            />
                            <span>Điểm: (Coming soon)</span>
                          </div>
                        </div>
                      </>
                    }
                    title={
                      cosultant.avatar ? (
                        <div>
                          <Image
                            src={cosultant.avatar || ''}
                            width={200}
                            height={200}
                            style={{ objectFit: 'cover' }}
                          />
                        </div>
                      ) : null
                    }
                  >
                    <Avatar
                      size='large'
                      src={cosultant.avatar}
                      style={{
                        border: '1px solid #ffcd0f',
                        cursor: 'pointer',
                      }}
                    />
                  </Popover>
                ))}
              </Avatar.Group>
            </>
          ) : (
            <>
              <Skeleton.Input style={{ width: 300 }} active />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default TopNotifications;
