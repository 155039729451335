import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, InputNumber, Modal, Upload, message } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import readNumber from 'read-vn-number';
import { getBase64FromFile } from '../../utils';
import addCommas from '../../utils/addCommas';
import { getImgUrl } from '../../utils/getImgUrl';
import removeNonNumeric from '../../utils/removeNonNumeric';

const CreateForm = ({
  visible = false,
  onCreate,
  onCancel,
  loading = false,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [price, setPrice] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);

  const handleFormValuesChange = (_, allValues) => {
    if (allValues.fee) {
      form.setFieldsValue({ fee: addCommas(removeNonNumeric(allValues.fee)) });
      setPrice(addCommas(removeNonNumeric(allValues.fee)));
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPng && isLt2M;
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64FromFile(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  const handleCancel = () => setPreviewVisible(false);

  const uploadButton = (
    <div>
      {previewImage ? (
        <img
          src={previewImage}
          alt='avatar'
          style={{
            width: '100%',
            height: 100,
            objectFit: 'cover',
          }}
        />
      ) : (
        <>
          <PlusOutlined />
          <div
            style={{
              marginTop: 8,
            }}
          >
            Chọn ảnh
          </div>
        </>
      )}
    </div>
  );

  return (
    <Modal
      className='class-setting'
      open={visible}
      title='Tạo lớp mới'
      onCancel={onCancel}
      footer={[
        <Button onClick={onCancel}>{t('cancel')}</Button>,
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={() => {
            form
              .validateFields()
              .then(async (values) => {
                if (fileList[0]?.originFileObj) {
                  if (!beforeUpload(fileList[0].originFileObj)) {
                    return;
                  } else {
                    values.cover = await getImgUrl(fileList[0].originFileObj);
                  }
                }

                onCreate(values);
                form.resetFields();
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          {t('ok')}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout='vertical'
        name='form_in_modal'
        onValuesChange={handleFormValuesChange}
      >
        <Form.Item name='class' label='Lớp chính'>
          <Input defaultValue='' placeholder={`${t('e.g.')}Lớp 1 Online`} />
        </Form.Item>
        <Form.Item name='alias' label='Tên thay thế'>
          <Input
            defaultValue=''
            placeholder={`${t('e.g.')}LEVEL 1: Sửa phát âm - Luyện nói chuẩn`}
          />
        </Form.Item>
        <Form.Item
          name='fee'
          label={t('fixedTuition')}
          style={{ width: '100%' }}
        >
          <Input defaultValue='' value={price} />
        </Form.Item>
        {price && (
          <p className='fee-text'>
            Giá bằng chữ:{' '}
            <span>{readNumber(parseInt(price.split('.').join('')))} đồng</span>
          </p>
        )}
        <Form.Item name='order' label={t('order1')}>
          <InputNumber defaultValue={1} min={1} />
        </Form.Item>
        <Form.Item name='cover' label='Ảnh bìa'>
          <Upload
            fileList={fileList}
            name='cover'
            listType='picture-card'
            beforeUpload={() => {
              return false;
            }}
            onChange={handleChange}
            onPreview={handlePreview}
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>
          <Modal
            open={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img
              alt='preview'
              style={{
                width: '100%',
              }}
              src={previewImage}
            />
          </Modal>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateForm;
