import { Button, Form, Input, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import addCommas from '../../utils/addCommas';
import removeNonNumeric from '../../utils/removeNonNumeric';

const CreateFormSalesSalary = ({ visible, onCreate, onCancel, loading }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleValueChangeAddCommas = (e) => {
    form.setFieldsValue({
      [e.target.name]: addCommas(removeNonNumeric(e.target.value)),
    });
  };

  return (
    <Modal
      className='class-setting'
      open={visible}
      title='Thêm thông tin lương nhân sự'
      onCancel={onCancel}
      footer={[
        <Button onClick={onCancel}>{t('cancel')}</Button>,
        <Button
          key='submit'
          type='primary'
          loading={loading}
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                form.resetFields();
                // console.log(values);
                onCreate(values);
              })
              .catch((info) => {
                console.log('Validate Failed:', info);
              });
          }}
        >
          {t('ok')}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout='vertical'
        name='form_in_modal'
        initialValues={{
          month: new Date().getMonth() + 1,
          year: new Date().getFullYear(),
        }}
      >
        <div className='inline-input'>
          <Form.Item name='month' label={t('month')}>
            <Input />
          </Form.Item>
          <Form.Item name='year' label={t('year')}>
            <Input />
          </Form.Item>
        </div>
        <div className='inline-input'>
          <Form.Item name='permanent_salary' label='Lương cứng'>
            <Input
              name='permanent_salary'
              onChange={handleValueChangeAddCommas}
            />
          </Form.Item>
          <Form.Item name='student_count' label='Số HV tư vấn'>
            <Input />
          </Form.Item>
        </div>
        <div className='inline-input'>
          <Form.Item name='business_salary' label='Lương kinh doanh'>
            <Input
              name='business_salary'
              onChange={handleValueChangeAddCommas}
            />
          </Form.Item>
          <Form.Item name='total_sales' label='Tổng doanh số'>
            <Input name='total_sales' onChange={handleValueChangeAddCommas} />
          </Form.Item>
        </div>

        <div className='inline-input'>
          <Form.Item name='other_salary' label='Lương khác'>
            <Input name='other_salary' onChange={handleValueChangeAddCommas} />
          </Form.Item>
          <Form.Item name='reward' label='Thưởng'>
            <Input name='reward' onChange={handleValueChangeAddCommas} />
          </Form.Item>
        </div>
        <div className='inline-input'>
          <Form.Item name='punishment' label='Phạt'>
            <Input name='punishment' onChange={handleValueChangeAddCommas} />
          </Form.Item>
          <Form.Item name='advance_salary' label='Tạm ứng'>
            <Input
              name='advance_salary'
              onChange={handleValueChangeAddCommas}
            />
          </Form.Item>
        </div>
        <Form.Item name='note' label='Note'>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateFormSalesSalary;
