import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { config } from '../../utils';
import Spinner from '../Spinner/Spinner';
import './SalaryDetails.css';
import SalarySalesTable from './SalarySalesTable';
import SalaryTeacherTable from './SalaryTeacherTable';

const SalaryDetails = ({ match }) => {
  const [data, setData] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/users/${match.params.id}`,
        config
      );

      setData(data.data);
    };

    fetchData();
  }, [match.params.id]);

  return (
    <div className='salary'>
      {!!data ? (
        <>
          {data.role.includes('teacher') ? (
            <SalaryTeacherTable user={data} />
          ) : (
            <SalarySalesTable user={data} />
          )}
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default SalaryDetails;
